import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import classNames from "classnames";
import { connect } from "react-redux";
import { addDeliveryAgent } from "../../Services/Actions/deliveryAgentAction";
import { toast } from "react-toastify";
import Toaster from "../../../Components/Toaster/Toaster";
import {
  contactValidation,
  countryCodeValidation,
  validEmailRegex,
  validPassword,
} from "../../Constants/Constants";
import { Us_Pattern_Number } from "../../../Store/type/Constant";
import MaskedInput from "react-text-mask";
import { Loader } from "rsuite";

function AddAgent(props) {
  const notifyError = (msg) => toast.error(msg);

  const [visible, setvisible] = useState(false);
  const [Confirmvisible, setConfirmvisible] = useState(false);
  const [onsubmiteloading, setonsubmiteloading] = useState(false);
  const [btnLoading, setbtnLoading] = useState(false);

  const [state, setState] = useState({
    agentName: "",
    contactNo: "",
    emailId: "",
    password: "",
    confirmPass: "",
    countryCode: "91",
    status: "active",
  });
  const [error, setError] = useState({
    agentName: false,
    contactNo: false,
    emailId: false,
    password: false,
    confirmPass: false,
    countryCode: false,
  });

  const [passwordCheck, setpasswordCheck] = useState({
    spacialcharacter: false,
    capitalLatter: false,
    smallLatter: false,
    passwordNumber: false,
    length: false,
  });

  var res = state?.contactNo
    ?.replace(/\D+/g, "")
    .replace(/^(\d{3})(\d{3})(\d{4}).*/, "$1$2$3");

  useEffect(() => {
    if (props.addAgent.success) {
      setonsubmiteloading(false);
      props.setIsAgent(false);
      setbtnLoading(false);
    }
  }, [props.addAgent]);

  const handleInput = (e) => {
    const { name, value } = e.target;

    if (name === "countryCode") {
      if (value.length <= 4 && value !== "+0x" && value !== "+0X") {
        setState((prevState) => ({
          ...prevState,
          countryCode: value === "+" ? parseInt("0") : parseInt(value),
        }));
      } else return;
    }
    if (name === "contactNo") {
      if (value.length <= 14) {
        setState((prevState) => ({
          ...prevState,
          [name]: value.replace(/^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/),
        }));
      }
    }
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "password") {
      if (/[A-Z]/.test(value)) {
        setpasswordCheck((prevState) => ({
          ...prevState,
          capitalLatter: "Capital Latter",
        }));
      } else {
        setpasswordCheck((prevState) => ({
          ...prevState,
          capitalLatter: false,
        }));
      }
      if (/[a-z]/.test(value)) {
        setpasswordCheck((prevState) => ({
          ...prevState,
          smallLatter: "Small Latter",
        }));
      } else {
        setpasswordCheck((prevState) => ({
          ...prevState,
          smallLatter: false,
        }));
      }
      if (/[0-9]/.test(value)) {
        setpasswordCheck((prevState) => ({
          ...prevState,
          passwordNumber: "Number",
        }));
      } else {
        setpasswordCheck((prevState) => ({
          ...prevState,
          passwordNumber: false,
        }));
      }
      if (value.length >= 8) {
        setpasswordCheck((prevState) => ({
          ...prevState,
          length: "length",
        }));
      } else {
        setpasswordCheck((prevState) => ({
          ...prevState,
          length: false,
        }));
      }
      if (/[$&+,:;=?@#|'<>.^*()%!-]/.test(value)) {
        setpasswordCheck((prevState) => ({
          ...prevState,
          spacialcharacter: "Spacial Character",
        }));
      } else {
        setpasswordCheck((prevState) => ({
          ...prevState,
          spacialcharacter: false,
        }));
      }
    }

    switch (name) {
      case "agentName":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            agentName: "Enter Agent name",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            agentName: false,
          }));
        }
        break;

      case "contactNo":
        if (value.length == 0) {
          setError((prevState) => ({
            ...prevState,
            contactNo: "contactNo can not be empty",
          }));
        } else if (!Us_Pattern_Number.test(value)) {
          setError((prevState) => ({
            ...prevState,
            contactNo: "Enter valid contactNo",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            contactNo: false,
          }));
        }
        break;

      case "emailId":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            emailId: "Enter Agent Email Address",
          }));
        } else if (!validEmailRegex.test(value)) {
          setError((prevState) => ({
            ...prevState,
            emailId: "Enter Valid Email",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            emailId: false,
          }));
        }
        break;

      case "password":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            password: "Enter Agent password",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            password: false,
          }));
        }
        break;

      case "countryCode":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            countryCode: "Enter Country code",
          }));
        } else if (!countryCodeValidation.test(value)) {
          setError((prevState) => ({
            ...prevState,
            countryCode: "Enter country code",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            countryCode: false,
          }));
        }
        break;

      case "confirmPass":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            confirmPass: "Enter Confirm password",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            confirmPass: false,
          }));
        }
        break;

      default:
        break;
    }
  };

  const handleCancel = () => {
    props.setIsAgent(false);
  };

  const handleAddAgent = () => {
    if (!onsubmiteloading) {
      if (state.agentName === "") {
        setError((prevState) => ({
          ...prevState,
          agentName: "Enter Agent name",
        }));
      }
      if (state.contactNo === "") {
        setError((prevState) => ({
          ...prevState,
          contactNo: "Enter Agent contact no.",
        }));
      } else if (!Us_Pattern_Number.test(state.contactNo)) {
        setError((prevState) => ({
          ...prevState,
          contactNo: "Enter valid contactNo",
        }));
      } else {
        setError((prevState) => ({
          ...prevState,
          contactNo: false,
        }));
      }
      if (state.emailId === "") {
        setError((prevState) => ({
          ...prevState,
          emailId: "Enter email",
        }));
      }
      if (state.password === "") {
        setError((prevState) => ({
          ...prevState,
          password: "Enter password",
        }));
      }
      if (state.confirmPass === "") {
        setError((prevState) => ({
          ...prevState,
          confirmPass: "Enter Confirm password",
        }));
      }
      if (state.countryCode === "") {
        setError((prevState) => ({
          ...prevState,
          countryCode: "Enter Country code",
        }));
      }

      if (state.confirmPass !== state.password) {
        notifyError("Password and Confirm Password not match");
      } else {
        if (
          state.agentName !== "" &&
          state.contactNo !== "" &&
          state.emailId !== "" &&
          state.password !== "" &&
          state.confirmPass !== "" &&
          state.countryCode !== "" &&
          !error.agentName &&
          !error.contactNo &&
          !error.emailId &&
          !error.password &&
          !error.confirmPass &&
          !error.countryCode
        ) {
          // call add function
          const details = {
            name: state.agentName,
            email: state.emailId,
            number: res,
            countrycode: state.countryCode,
            password: state.password,
            type: "2",
            status: state.status === "active" ? 1 : 0,
          };
          setonsubmiteloading(true);
          props.addDeliveryAgent(details);
          setbtnLoading(true);
          // props.setIsAgent(false);
        }
      }
    }
  };

  const passwordvisible = () => {
    setvisible(!visible);
  };
  const Confirmpasswordvisible = () => {
    setConfirmvisible(!Confirmvisible);
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Create Driver</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="row">
          <div className="col-6">
            <Form.Group className="">
              <Form.Label style={{ fontWeight: "bold" }}>Name:</Form.Label>
              <Form.Control
                name="agentName"
                type="text"
                placeholder="Enter Agents Name"
                value={state.agentName}
                onChange={handleInput}
                className={classNames({
                  requireField: error.agentName,
                })}
              />
            </Form.Group>
          </div>
          <div className="col-6">
            <Form.Group className="">
              <Form.Label style={{ fontWeight: "bold" }}>
                Email Address:
              </Form.Label>
              <Form.Control
                name="emailId"
                type="text"
                placeholder="Enter Email Address"
                value={state.emailId}
                onChange={handleInput}
                className={classNames({
                  requireField: error.emailId,
                })}
              />
            </Form.Group>
          </div>

          <div className="col-6 mt-4">
            {" "}
            <Form.Group className="">
              <Form.Label style={{ fontWeight: "bold" }}>
                Contact No:
              </Form.Label>

              {/* <Form.Control
                name="contactNo"
                type="number"
                placeholder="Enter Contact No."
                value={state.contactNo}
                onChange={handleInput}
                className={classNames({
                  requireField: error.contactNo,
                })}
              /> */}
              <MaskedInput
                mask={[
                  "(",
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                  ")",
                  " ",
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                  "-",
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                ]}
                // type="number"
                name="contactNo"
                placeholder="Enter Contact No."
                guide={false}
                id="my-input-id number"
                value={state.contactNo}
                onChange={handleInput}
                className={classNames("form-control mb-3", {
                  requireField: error.contactNo,
                })}
              />
            </Form.Group>
          </div>
          <div className="col-6"></div>

          <Form.Group className="mt-4 col-6">
            <Form.Label style={{ fontWeight: "bold" }}>Password:</Form.Label>
            <div className="password-field">
              <Form.Control
                name="password"
                type={!!visible ? "text" : "password"}
                value={state.password}
                placeholder="Enter Password"
                onChange={handleInput}
                className={classNames({
                  requireField: error.password,
                })}
              />
              <div
                className="form-control-eye-icon pointer"
                onClick={passwordvisible}
              >
                {visible ? (
                  <i className="fa fa-eye" style={{ marginLeft: "250px" }}></i>
                ) : (
                  <i
                    className="fa fa-eye-slash "
                    style={{ marginLeft: "250px" }}
                  ></i>
                )}
              </div>
            </div>
          </Form.Group>
          <Form.Group className="mt-4 col-6">
            <Form.Label style={{ fontWeight: "bold" }}>
              Confirm Password:
            </Form.Label>
            <div className="password-field">
              <Form.Control
                name="confirmPass"
                type={!!Confirmvisible ? "text" : "password"}
                value={state.confirmPass}
                placeholder="Enter Password"
                onChange={handleInput}
                className={classNames({
                  requireField: error.confirmPass,
                })}
              />

              <div
                className="form-control-eye-icon pointer"
                onClick={Confirmpasswordvisible}
              >
                {Confirmvisible ? (
                  <i className="fa fa-eye" style={{ marginLeft: "250px" }}></i>
                ) : (
                  <i
                    className="fa fa-eye-slash"
                    style={{ marginLeft: "250px" }}
                  ></i>
                )}
              </div>
            </div>
          </Form.Group>
          <div className="row">
            <div
              className="col-md-12 col-12 mt-2"
              style={{ fontWeight: "bold" }}
            >
              <div
                style={{
                  color: passwordCheck?.length ? "green" : "red",
                }}
              >
                <span>
                  <i
                    className={`fa ${
                      passwordCheck?.length
                        ? "fa-circle-check text-success"
                        : "fa-circle-xmark text-danger"
                    } text-success fs-5 me-2`}
                  ></i>
                  Must be at least 8 characters.
                </span>
              </div>
            </div>

            <div
              className="col-md-12 col-12 mt-2"
              style={{ fontWeight: "bold" }}
            >
              <div
                style={{
                  color: passwordCheck?.capitalLatter ? "green" : "red",
                }}
              >
                <span>
                  <i
                    className={`fa ${
                      passwordCheck?.capitalLatter
                        ? "fa-circle-check text-success"
                        : "fa-circle-xmark text-danger"
                    } text-success fs-5 me-2`}
                  ></i>
                  Must be at least One upper case character ( A,B,C...etc )
                </span>
              </div>
            </div>
            <div
              className="col-md-12 col-12 mt-2"
              style={{ fontWeight: "bold" }}
            >
              <div
                style={{
                  color: passwordCheck?.smallLatter ? "green" : "red",
                }}
              >
                <span>
                  <i
                    className={`fa ${
                      passwordCheck?.smallLatter
                        ? "fa-circle-check text-success"
                        : "fa-circle-xmark text-danger"
                    } text-success fs-5 me-2`}
                  ></i>
                  Must be at least One lower case character ( a,b,c...etc )
                </span>
              </div>
            </div>

            <div
              className="col-md-12 col-12 mt-2"
              style={{ fontWeight: "bold" }}
            >
              <div
                style={{
                  color: passwordCheck?.spacialcharacter ? "green" : "red",
                }}
              >
                <span>
                  {" "}
                  <i
                    className={`fa ${
                      passwordCheck?.spacialcharacter
                        ? "fa-circle-check text-success"
                        : "fa-circle-xmark text-danger"
                    } text-success fs-5 me-2`}
                  ></i>
                  Must conatin at least special character ( #,@,$,...etc )
                </span>
              </div>
            </div>
            <div
              className="col-md-12 col-12 mt-2"
              style={{ fontWeight: "bold" }}
            >
              <div
                style={{
                  color: passwordCheck?.passwordNumber ? "green" : "red",
                }}
              >
                <i
                  className={`fa ${
                    passwordCheck?.passwordNumber
                      ? "fa-circle-check text-success"
                      : "fa-circle-xmark text-danger"
                  } text-success fs-5 me-2`}
                ></i>
                {/* <i className="fa fa-circle-xmark text-danger fs-4"></i> */}
                Must conatin at least one number ( 1,2,3,...etc )
              </div>
            </div>
          </div>
          <div className="col-md-3 col-4 mt-4">
            <Form.Label>Status:</Form.Label>
            <Form.Select
              value={state.status}
              onChange={(e) => {
                setState((prevState) => ({
                  ...prevState,
                  status: e.target.value,
                }));
              }}
            >
              <option value="active">Active</option>
              <option value="Inactive">Inactive</option>
            </Form.Select>
          </div>
        </Form>
        <div className="text-end mt-3">
          <Button
            type="button"
            className="border rounded pointer px-4 py-2 text-white bg-red mt-4"
            onClick={handleAddAgent}
          >
            {btnLoading ? <Loader /> : "Submit"}
          </Button>
        </div>
      </Modal.Body>
      <Toaster />
    </>
  );
}

const mapStateToProp = (state) => ({
  addAgent: state.DeliveryAgentReducer.addAgent,
});

const mapDispatchToProps = (dispatch) => ({
  addDeliveryAgent: (details) => dispatch(addDeliveryAgent(details)),
});

export default connect(mapStateToProp, mapDispatchToProps)(AddAgent);

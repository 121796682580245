import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getCutomerAddressDataAction,
  getCutomerDataAction,
} from "../../../Store/Action/CreateAgents/UserAction";
import Footer from "../Userlayout/Footer";
import Header from "../Userlayout/Header";

const InsideCustomerData = () => {
  const dispatch = useDispatch();

  const Insidecustomer = useSelector(
    (state) => state.getCustomerReducer.user.data
  );

  return (
    <>
      {" "}
      <Header />
      <main className="userMain">
        <div className="content-block">
          <h4>Customer Address</h4>
          <p>{
            Insidecustomer?.map((v, i) => {
              return(
                <>
                <p key={v._id}>{v?.name}</p>
                </>
              )
            })
            } </p>
        </div>
      </main>
      <Footer />
    </>
  );
};

const mapStateToProp = (state) => ({
  createCustomerReducer: state.createCustomerReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getCutomerAddressHandler: (user) =>
    dispatch(getCutomerAddressDataAction(user)),
  getCustomerHandler: (Details) => dispatch(getCutomerDataAction(Details)),
});
export default connect(mapStateToProp, mapDispatchToProps)(InsideCustomerData);

import { ADD_PRM_ERROR, ADD_PRM_LOADING, ADD_PRM_SUCCESS, DELETE_PRM_ERROR, DELETE_PRM_LOADING, DELETE_PRM_SUCCESS, GET_PRM_BY_ID_ERROR, GET_PRM_BY_ID_LOADING, GET_PRM_BY_ID_SUCCESS, GET_PRM_ERROR, GET_PRM_LOADING, GET_PRM_SUCCESS, RESET_ADD_PRM, RESET_DELETE_PRM, RESET_GET_PRM, RESET_GET_PRM_BY_ID, RESET_UPDATE_PRM, UPDATE_PRM_ERROR, UPDATE_PRM_LOADING, UPDATE_PRM_SUCCESS } from "../../../Constants/Constants";

let initialState = {
  getPrm: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
    pagination: {},
  },
  getPrmById: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
  updatePrm: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
  deletePrm: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
  addPrm: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
};

export default function PrmReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PRM_LOADING:
      return { ...state, getPrm: { ...state.getPrm, loading: true, error: false, errors: [], success: false, pagination: {} } };

    case GET_PRM_SUCCESS:
      return { ...state, getPrm: { ...state.getPrm, loading: false, error: false, errors: [], success: true, data: action.response.data, pagination: action.response.pagination } };

    case GET_PRM_ERROR:
      return { ...state, getPrm: { ...state.getPrm, loading: false, error: true, errors: action.errors, success: false, data: null, pagination: {} } };

    case RESET_GET_PRM:
      return { ...state, getPrm: { ...state.getPrm, loading: false, error: false, errors: null, success: false, pagination: {} } };


    //  get Menu by id
    case GET_PRM_BY_ID_LOADING:
      return { ...state, getPrmById: { ...state.getPrmById, loading: true, error: false, errors: [], success: false } };

    case GET_PRM_BY_ID_SUCCESS:
      return { ...state, getPrmById: { ...state.getPrmById, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case GET_PRM_BY_ID_ERROR:
      return { ...state, getPrmById: { ...state.getPrmById, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_GET_PRM_BY_ID:
      return { ...state, getPrmById: { ...state.getPrmById, loading: false, error: false, errors: null, success: false } };


    //  update Menu by id
    case UPDATE_PRM_LOADING:
      return { ...state, updatePrm: { ...state.updatePrm, loading: true, error: false, errors: [], success: false } };

    case UPDATE_PRM_SUCCESS:
      return { ...state, updatePrm: { ...state.updatePrm, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case UPDATE_PRM_ERROR:
      return { ...state, updatePrm: { ...state.updatePrm, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_UPDATE_PRM:
      return { ...state, updatePrm: { ...state.updatePrm, loading: false, error: false, errors: null, success: false } };


    //  delete Menu by id
    case DELETE_PRM_LOADING:
      return { ...state, deletePrm: { ...state.deletePrm, loading: true, error: false, errors: [], success: false } };

    case DELETE_PRM_SUCCESS:
      return { ...state, deletePrm: { ...state.deletePrm, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case DELETE_PRM_ERROR:
      return { ...state, deletePrm: { ...state.deletePrm, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_DELETE_PRM:
      return { ...state, deletePrm: { ...state.deletePrm, loading: false, error: false, errors: null, success: false } };


    //  add menu
    case ADD_PRM_LOADING:
      return { ...state, addPrm: { ...state.addPrm, loading: true, error: false, errors: [], success: false } };

    case ADD_PRM_SUCCESS:
      return { ...state, addPrm: { ...state.addPrm, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case ADD_PRM_ERROR:
      return { ...state, addPrm: { ...state.addPrm, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_ADD_PRM:
      return { ...state, addPrm: { ...state.addPrm, loading: false, error: false, errors: null, success: false } };

    default:
      return state;
  }
}

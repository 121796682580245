import React from "react";

const Footer = () => {
  return (
    <footer className="user-footer text-center footer">
      &copy; Copyright 2023, All rights are reserved.
    </footer>
  );
};

export default Footer;

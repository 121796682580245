import axios from "axios";
import {
  addAgent_Url,
  deleteAgent_Url,
  getAgentbyId_Url,
  getAgent_Url,
  updateAgent_Url,
  freeAgentByAdmin_Url,
} from "../../apiSheet";
import {
  ADD_AGENT_ERROR,
  ADD_AGENT_LOADING,
  ADD_AGENT_SUCCESS,
  DELETE_AGENT_ERROR,
  DELETE_AGENT_LOADING,
  DELETE_AGENT_SUCCESS,
  GET_AGENT_BY_ID_ERROR,
  GET_AGENT_BY_ID_LOADING,
  GET_AGENT_BY_ID_SUCCESS,
  GET_AGENT_ERROR,
  GET_AGENT_LOADING,
  GET_AGENT_SUCCESS,
  UPDATE_AGENT_ERROR,
  UPDATE_AGENT_LOADING,
  UPDATE_AGENT_SUCCESS,
} from "../../Constants/Constants";

export const getDeliveryAgent = () => {
  return (dispatch) => {
    dispatch({
      type: GET_AGENT_LOADING,
    });
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },

      url: `${getAgent_Url}`,
    };

    axios(requestOptions)
      .then((resp) => {
        
        dispatch({
          type: GET_AGENT_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message,
            pagination: {
              page: resp.data.page,
              limit: resp.data.limit,
              totalpage: resp.data.totalpage,
            },
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_AGENT_ERROR,
            netError: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: GET_AGENT_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const getFreeAgentAdmin = (details) => {
  return (dispatch) => {
    dispatch({
      type: GET_AGENT_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },

      url: `${freeAgentByAdmin_Url}`,
      data: details,
    };

    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_AGENT_SUCCESS,
          response: {
            data: resp.data.agent,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_AGENT_ERROR,
            netError: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: GET_AGENT_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const addDeliveryAgent = (details) => {
  return (dispatch) => {
    dispatch({
      type: ADD_AGENT_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${addAgent_Url}`,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: ADD_AGENT_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: ADD_AGENT_ERROR,
            netError: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: ADD_AGENT_ERROR,
            errors: validationError,
          });
        } else if (data.status === 401) {
          window.location.reload();
        }
      });
  };
};

export const getAgentById = (id) => {
  return (dispatch) => {
    dispatch({
      type: GET_AGENT_BY_ID_LOADING,
    });
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${getAgentbyId_Url}/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_AGENT_BY_ID_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_AGENT_BY_ID_ERROR,
            netError: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: ADD_AGENT_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const updateAgent = (details) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_AGENT_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${updateAgent_Url}/${details.id}`,
      data: details.data,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: UPDATE_AGENT_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: UPDATE_AGENT_ERROR,
            netError: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: UPDATE_AGENT_ERROR,
            errors: validationError,
          });
        } else if (data.status === 401) {
          window.location.reload();
        }
      });
  };
};

export const deleteAgent = (id) => {
  return (dispatch) => {
    dispatch({
      type: DELETE_AGENT_LOADING,
    });
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${deleteAgent_Url}/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: DELETE_AGENT_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: DELETE_AGENT_ERROR,
            netError: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: DELETE_AGENT_ERROR,
            errors: validationError,
          });
        } else if (data.status === 401) {
          window.location.reload();
        }
      });
  };
};

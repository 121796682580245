import {
  RESET_UPDATE_DELIVERY_AGENTS_DATA_SUCCESS,
  UPDATE_DELIVERY_AGENTS_DATA_ERROR,
  UPDATE_DELIVERY_AGENTS_DATA_LOADING,
  UPDATE_DELIVERY_AGENTS_DATA_SUCCESS,
} from "../type/Constant";

let initialState = {
  error: false,
  success: false,
  loading: false,
  errors: [],
  data: null,
};

export default function editDeliveryAgentsReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case UPDATE_DELIVERY_AGENTS_DATA_LOADING:
      return { ...state, error: false, loading: true, success: false };

    case UPDATE_DELIVERY_AGENTS_DATA_SUCCESS:
      return {
        ...state,
        error: false,
        success: true,
        loading: false,
        message: action.msg,
        data: action.response.data,
      };

    case UPDATE_DELIVERY_AGENTS_DATA_ERROR:
      return {
        ...state,
        error: true,
        success: false,
        loading: false,
        errors: action.errors,
      };

    case RESET_UPDATE_DELIVERY_AGENTS_DATA_SUCCESS:
      return {
        ...state,
        error: false,
        success: false,
        loading: false,
        data: null,
      };
    default:
      return state;
  }
}

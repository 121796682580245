import React from "react";
import ReactDOM from "react-dom/client";
import "react-data-table-component-extensions/dist/index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./Store/Store";
import "font-awesome/css/font-awesome.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-calendar/dist/Calendar.css";
import "dropify/dist/js/dropify.js";
import "dropify/dist/css/dropify.css";
import "rsuite/dist/rsuite.min.css";
import "rsuite/styles/index.less";
import "rsuite/dist/rsuite.css";
import "./index.css";
import "./App.css";

// import 'dropify/dist/fonts/*'

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
);
reportWebVitals();

import {
  ADD_CUSTOMER_ERROR,
  ADD_CUSTOMER_LOADING,
  ADD_CUSTOMER_SUCCESS,
  RESET_ADD_CUSTOMER,
} from "../type/Constant";

let initialState = {
  loading: false,
  user: [{}],
  error: null,
  errors: null,
  success: false,
  payload: {},
  data: [],
};

export default function createCustomerReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_CUSTOMER_LOADING:
      return { ...state, error: false, loading: true, success: false };

    case ADD_CUSTOMER_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        success: true,
        data: action.data,
      };

    case ADD_CUSTOMER_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        success: false,
        errors: action.errors,
      };

    case RESET_ADD_CUSTOMER:
      return {
        loading: false,
        error: null,
        errors: null,
        success: false,
        data: [],
      };

    default:
      return state;
  }
}

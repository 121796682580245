import {
    AGENTSWISE_ORDER_ERROR,
    AGENTSWISE_ORDER_LOADING,
    AGENTSWISE_ORDER_SUCCESS,
    GET_SWAP_AGENT_ERROR,
    GET_SWAP_AGENT_LOADING,
    GET_SWAP_AGENT_SUCCESS,
    RESET_AGENTSWISE_ORDER,
    RESET_GET_SWAP_AGENT,
  } from "../type/Constant";
  
  let initialState = {
    getswapagent: {
      loading: false,
      data: null,
      error: false,
      errors: null,
      success: false,
      pagination: {},
    },
  };
  
  export default function getswapagentReducer(state = initialState, action) {
    switch (action.type) {
      case GET_SWAP_AGENT_LOADING:
        return {
          ...state,
          getswapagent: {
            ...state.getswapagent,
            loading: true,
            error: false,
            errors: [],
            success: false,
          },
        };
  
      case GET_SWAP_AGENT_SUCCESS:
        return {
          ...state,
          getswapagent: {
            ...state.getswapagent,
            loading: false,
            error: false,
            errors: [],
            success: true,
            data: action.response.data,
            pagination: action.pagination,
          },
        };
  
      case GET_SWAP_AGENT_ERROR:
        return {
          ...state,
          getswapagent: {
            ...state.getswapagent,
            loading: false,
            error: true,
            errors: action.errors,
            success: false,
            data: null,
          },
        };
  
      case RESET_GET_SWAP_AGENT:
        return {
          ...state,
          getswapagent: {
            ...state.getswapagent,
            loading: false,
            error: false,
            errors: false,
            success: false,
            data: null,
          },
        };
  
      default:
        return state;
    }
  }
  
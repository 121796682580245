import axios from "axios";
import {
  REPORT_ERROR,
  REPORT_LOADING,
  REPORT_SUCCESS,
} from "../../Constants/Constants";
import { getReport_Url } from "../../apiSheet";

export const getAdminReport = (details) => {
  return (dispatch) => {
    dispatch({
      type: REPORT_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${getReport_Url}`,
      data: details
    };
    axios(requestOptions)
      .then((resp) => {
        console.log("resp", resp)
        dispatch({
          type: REPORT_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: REPORT_ERROR,
            netError: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: REPORT_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

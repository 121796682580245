import {
  ADMIN_CUSTOMER_SEARCH_ERROR,
  ADMIN_CUSTOMER_SEARCH_LOADING,
  ADMIN_CUSTOMER_SEARCH_SUCCESS,
  CREATE_COUSTOMER_ERROR,
  CREATE_COUSTOMER_LOADING,
  CREATE_COUSTOMER_SUCCESS,
  CUSTOMER_CONTACT_SEARCH_ADMIN_ERROR,
  CUSTOMER_CONTACT_SEARCH_ADMIN_LOADING,
  CUSTOMER_CONTACT_SEARCH_ADMIN_SUCCESS,
  DELETE_ADMIN_CUSTOMER_ADDRESS_ERROR,
  DELETE_ADMIN_CUSTOMER_ADDRESS_LOADING,
  DELETE_ADMIN_CUSTOMER_ADDRESS_SUCCESS,
  DELETE_COUSTOMER_ERROR,
  DELETE_COUSTOMER_LOADING,
  DELETE_COUSTOMER_SUCCESS,
  EDIT_COUSTOMER_ERROR,
  EDIT_COUSTOMER_LOADING,
  EDIT_COUSTOMER_SUCCESS,
  FIND_CUSTOMER_BY_CONTACT_ERROR,
  FIND_CUSTOMER_BY_CONTACT_LOADING,
  FIND_CUSTOMER_BY_CONTACT_SUCCESS,
  GET_ADMIN_CUSTOMER_DETAILS_ERROR,
  GET_ADMIN_CUSTOMER_DETAILS_LOADING,
  GET_ADMIN_CUSTOMER_DETAILS_SUCCESS,
  GET_ADMIN_CUSTOMER_ERROR,
  GET_ADMIN_CUSTOMER_LOADING,
  GET_ADMIN_CUSTOMER_ORDER_ERROR,
  GET_ADMIN_CUSTOMER_ORDER_LOADING,
  GET_ADMIN_CUSTOMER_ORDER_SUCCESS,
  GET_ADMIN_CUSTOMER_SUCCESS,
  GET_CUSTOMER_ERROR,
  GET_CUSTOMER_LOADING,
  GET_CUSTOMER_SUCCESS,
  RESET_ADMIN_CUSTOMER_SEARCH,
  RESET_CREATE_COUSTOMER,
  RESET_CUSTOMER_CONTACT_SEARCH_ADMIN,
  RESET_DELETE_ADMIN_CUSTOMER_ADDRESS,
  RESET_DELETE_COUSTOMER,
  RESET_EDIT_COUSTOMER,
  RESET_FIND_CUSTOMER_BY_CONTACT,
  RESET_GET_ADMIN_CUSTOMER,
  RESET_GET_ADMIN_CUSTOMER_DETAILS,
  RESET_GET_ADMIN_CUSTOMER_ORDER,
  RESET_GET_CUSTOMER,
} from "../../../Constants/Constants";

let initialState = {
  findCustomerByContact: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
  },
  CustomerSerachByContact: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
  },
  CustomerSerachByNameAdmin: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
  },
  getCoustomerList: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
  },
  getCoustomerById: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
  },
  getCoustomerOrder: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
  },
  deleteAddress: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
    flag: null,
  },
  EditCoustomer: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
  },
  DeleteCoustomer: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
  },
  CreateCoustomer: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
  },
};

export default function CustomerReducer(state = initialState, action) {
  switch (action.type) {
    case FIND_CUSTOMER_BY_CONTACT_LOADING:
      return {
        ...state,
        findCustomerByContact: {
          ...state.findCustomerByContact,
          loading: true,
          error: false,
          errors: [],
          success: false,
        },
      };

    case FIND_CUSTOMER_BY_CONTACT_SUCCESS:
      return {
        ...state,
        findCustomerByContact: {
          ...state.findCustomerByContact,
          loading: false,
          error: false,
          errors: [],
          success: true,
          data: action.response.data,
        },
      };

    case FIND_CUSTOMER_BY_CONTACT_ERROR:
      return {
        ...state,
        findCustomerByContact: {
          ...state.findCustomerByContact,
          loading: false,
          error: true,
          errors: action.errors,
          success: false,
          data: null,
        },
      };

    case RESET_FIND_CUSTOMER_BY_CONTACT:
      return {
        ...state,
        findCustomerByContact: {
          ...state.findCustomerByContact,
          loading: false,
          error: false,
          errors: null,
          success: false,
        },
      };

    // ............................................

    case CUSTOMER_CONTACT_SEARCH_ADMIN_LOADING:
      return {
        ...state,
        CustomerSerachByContact: {
          ...state.CustomerSerachByContact,
          loading: true,
          error: false,
          errors: [],
          success: false,
        },
      };

    case CUSTOMER_CONTACT_SEARCH_ADMIN_SUCCESS:
      return {
        ...state,
        CustomerSerachByContact: {
          ...state.CustomerSerachByContact,
          loading: false,
          error: false,
          errors: [],
          success: true,
          data: action.response.data,
        },
      };

    case CUSTOMER_CONTACT_SEARCH_ADMIN_ERROR:
      return {
        ...state,
        CustomerSerachByContact: {
          ...state.CustomerSerachByContact,
          loading: false,
          error: true,
          errors: action.errors,
          success: false,
          data: null,
        },
      };

    case RESET_CUSTOMER_CONTACT_SEARCH_ADMIN:
      return {
        ...state,
        CustomerSerachByContact: {
          ...state.CustomerSerachByContact,
          loading: false,
          error: false,
          errors: null,
          success: false,
        },
      };

    // ...................................search by customer name...............................

    case ADMIN_CUSTOMER_SEARCH_LOADING:
      return {
        ...state,
        CustomerSerachByNameAdmin: {
          ...state.CustomerSerachByNameAdmin,
          loading: true,
          error: false,
          errors: [],
          success: false,
        },
      };

    case ADMIN_CUSTOMER_SEARCH_SUCCESS:
      return {
        ...state,
        CustomerSerachByNameAdmin: {
          ...state.CustomerSerachByNameAdmin,
          loading: false,
          error: false,
          errors: [],
          success: true,
          data: action.response.data,
        },
      };

    case ADMIN_CUSTOMER_SEARCH_ERROR:
      return {
        ...state,
        CustomerSerachByNameAdmin: {
          ...state.CustomerSerachByNameAdmin,
          loading: false,
          error: true,
          errors: action.errors,
          success: false,
          data: null,
        },
      };

    case RESET_ADMIN_CUSTOMER_SEARCH:
      return {
        ...state,
        CustomerSerachByNameAdmin: {
          ...state.CustomerSerachByNameAdmin,
          loading: false,
          error: false,
          errors: null,
          success: false,
        },
      };

    // ...................................customer list..............................

    case GET_ADMIN_CUSTOMER_LOADING:
      return {
        ...state,
        getCoustomerList: {
          ...state.getCoustomerList,
          loading: true,
          error: false,
          errors: [],
          success: false,
        },
      };

    case GET_ADMIN_CUSTOMER_SUCCESS:
      return {
        ...state,
        getCoustomerList: {
          ...state.getCoustomerList,
          loading: false,
          error: false,
          errors: [],
          success: true,
          data: action.response.data,
        },
      };

    case GET_ADMIN_CUSTOMER_ERROR:
      return {
        ...state,
        getCoustomerList: {
          ...state.getCoustomerList,
          loading: false,
          error: true,
          errors: action.errors,
          success: false,
          data: null,
        },
      };

    case RESET_GET_ADMIN_CUSTOMER:
      return {
        ...state,
        getCoustomerList: {
          ...state.getCoustomerList,
          loading: false,
          error: false,
          errors: null,
          success: false,
        },
      };
    // ...................................customer by ID..............................

    case GET_ADMIN_CUSTOMER_DETAILS_LOADING:
      return {
        ...state,
        getCoustomerById: {
          ...state.getCoustomerById,
          loading: true,
          error: false,
          errors: [],
          success: false,
        },
      };

    case GET_ADMIN_CUSTOMER_DETAILS_SUCCESS:
      return {
        ...state,
        getCoustomerById: {
          ...state.getCoustomerById,
          loading: false,
          error: false,
          errors: [],
          success: true,
          data: action.response.data,
        },
      };

    case GET_ADMIN_CUSTOMER_DETAILS_ERROR:
      return {
        ...state,
        getCoustomerById: {
          ...state.getCoustomerById,
          loading: false,
          error: true,
          errors: action.errors,
          success: false,
          data: null,
        },
      };

    case RESET_GET_ADMIN_CUSTOMER_DETAILS:
      return {
        ...state,
        getCoustomerById: {
          ...state.getCoustomerById,
          loading: false,
          error: false,
          errors: null,
          success: false,
        },
      };
    // ...................................customer Order..............................

    case GET_ADMIN_CUSTOMER_ORDER_LOADING:
      return {
        ...state,
        getCoustomerOrder: {
          ...state.getCoustomerOrder,
          loading: true,
          error: false,
          errors: [],
          success: false,
        },
      };

    case GET_ADMIN_CUSTOMER_ORDER_SUCCESS:
      return {
        ...state,
        getCoustomerOrder: {
          ...state.getCoustomerOrder,
          loading: false,
          error: false,
          errors: [],
          success: true,
          data: action.response.data,
        },
      };

    case GET_ADMIN_CUSTOMER_ORDER_ERROR:
      return {
        ...state,
        getCoustomerOrder: {
          ...state.getCoustomerOrder,
          loading: false,
          error: true,
          errors: action.errors,
          success: false,
          data: null,
        },
      };

    case RESET_GET_ADMIN_CUSTOMER_ORDER:
      return {
        ...state,
        getCoustomerOrder: {
          ...state.getCoustomerOrder,
          loading: false,
          error: false,
          errors: null,
          success: false,
        },
      };
    // ...................................customer delete address..............................

    case DELETE_ADMIN_CUSTOMER_ADDRESS_LOADING:
      return {
        ...state,
        deleteAddress: {
          ...state.deleteAddress,
          loading: true,
          error: false,
          errors: [],
          success: false,
        },
      };

    case DELETE_ADMIN_CUSTOMER_ADDRESS_SUCCESS:
      return {
        ...state,
        deleteAddress: {
          ...state.deleteAddress,
          loading: false,
          error: false,
          errors: [],
          success: true,
          data: action.response.data,
          flag: action?.response?.flag,
        },
      };

    case DELETE_ADMIN_CUSTOMER_ADDRESS_ERROR:
      return {
        ...state,
        deleteAddress: {
          ...state.deleteAddress,
          loading: false,
          error: true,
          errors: action.errors,
          success: false,
          data: null,
          flag: action?.flag,
        },
      };

    case RESET_DELETE_ADMIN_CUSTOMER_ADDRESS:
      return {
        ...state,
        deleteAddress: {
          ...state.deleteAddress,
          loading: false,
          error: false,
          errors: null,
          success: false,
          flag: null,
        },
      };
    // ...................................customer Edit..............................

    case EDIT_COUSTOMER_LOADING:
      return {
        ...state,
        EditCoustomer: {
          ...state.EditCoustomer,
          loading: true,
          error: false,
          errors: [],
          success: false,
        },
      };

    case EDIT_COUSTOMER_SUCCESS:
      return {
        ...state,
        EditCoustomer: {
          ...state.EditCoustomer,
          loading: false,
          error: false,
          errors: [],
          success: true,
          data: action.response.data,
        },
      };

    case EDIT_COUSTOMER_ERROR:
      return {
        ...state,
        EditCoustomer: {
          ...state.EditCoustomer,
          loading: false,
          error: true,
          errors: action.errors,
          success: false,
          data: null,
        },
      };

    case RESET_EDIT_COUSTOMER:
      return {
        ...state,
        EditCoustomer: {
          ...state.EditCoustomer,
          loading: false,
          error: false,
          errors: null,
          success: false,
        },
      };
    // ...................................customer Delete..............................

    case DELETE_COUSTOMER_LOADING:
      return {
        ...state,
        DeleteCoustomer: {
          ...state.DeleteCoustomer,
          loading: true,
          error: false,
          errors: [],
          success: false,
        },
      };

    case DELETE_COUSTOMER_SUCCESS:
      return {
        ...state,
        DeleteCoustomer: {
          ...state.DeleteCoustomer,
          loading: false,
          error: false,
          errors: [],
          success: true,
          data: action.response.data,
        },
      };

    case DELETE_COUSTOMER_ERROR:
      return {
        ...state,
        DeleteCoustomer: {
          ...state.DeleteCoustomer,
          loading: false,
          error: true,
          errors: action.errors,
          success: false,
          data: null,
        },
      };

    case RESET_DELETE_COUSTOMER:
      return {
        ...state,
        DeleteCoustomer: {
          ...state.DeleteCoustomer,
          loading: false,
          error: false,
          errors: null,
          success: false,
        },
      };
    // ...................................customer Create..............................

    case CREATE_COUSTOMER_LOADING:
      return {
        ...state,
        CreateCoustomer: {
          ...state.CreateCoustomer,
          loading: true,
          error: false,
          errors: [],
          success: false,
        },
      };

    case CREATE_COUSTOMER_SUCCESS:
      return {
        ...state,
        CreateCoustomer: {
          ...state.CreateCoustomer,
          loading: false,
          error: false,
          errors: [],
          success: true,
          data: action.response.data,
        },
      };

    case CREATE_COUSTOMER_ERROR:
      return {
        ...state,
        CreateCoustomer: {
          ...state.CreateCoustomer,
          loading: false,
          error: true,
          errors: action.errors,
          success: false,
          data: null,
        },
      };

    case RESET_CREATE_COUSTOMER:
      return {
        ...state,
        CreateCoustomer: {
          ...state.CreateCoustomer,
          loading: false,
          error: false,
          errors: null,
          success: false,
        },
      };

    default:
      return state;
  }
}

import React, { useEffect, useState } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { RESET_ADMIN_LOGOUT } from "../../Admin/Constants/Constants";
import {
  adminLogout,
  getProfileDetails,
} from "../../Admin/Services/Actions/AuthAction";
import AdminSidebar from "../Sidebar/AdminSidebar";
import CreateOrder from "../../Admin/Pages/AdminOrder/CreateOrder";
// import imageurl from "../../images";
function AdminHeader(props) {
  const [show, setShow] = useState(false);
  const [isCreateOrder, setIsCreateOrder] = useState(false);

  const navigate = useNavigate();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [ShortName, setShortName] = useState();
  const [NameArray, setNameArray] = useState();

  const clearLocalStorage = () => {
    localStorage.removeItem("userid");
    localStorage.removeItem("usertype");
    localStorage.removeItem("token");
    navigate("admin/login");
  };

  //   const handleLogout = () => {
  //     props.adminLogout();
  //   };
  const [locationName, setLocationName] = useState();
  useEffect(() => {
    if (props.profileDetails.success) {
      const data = props.profileDetails?.data;
      const profileNameArray = data?.name?.split(" ");
      const profileShortName = data?.name?.slice(0, 2);
      setShortName(profileShortName);
      setNameArray(profileNameArray);
      setLocationName(data && data?.locationdata[0]?.location_name);
    }
  }, [props.profileDetails]);

  useEffect(() => {
    if (props.loginReducer.success) {
      props.getProfileDetails();
    }
  }, [props.loginReducer]);

  //   useEffect(() => {
  //     if (props.adminLogoutReducer.success) {
  //       localStorage.clear();
  //       props.resetLogout();
  //       navigate("/admin/login");
  //     }
  //   }, [props.adminLogoutReducer]);

  return (
    <>
      <header className="user-Header">
        <div className="d-flex align-items-center">
          <img
            crossOrigin="anonymous"
            src={"/images/logo.png"}
            className="img-fluid logo pointer"
            alt="Logo"
            onClick={() => navigate("/")}
          />
        </div>
        <h3 className="text-dark">{locationName}</h3>
        <div className="d-flex align-items-center">
          {/* <Link to="/cart" className="btn"><i className="ri-shopping-cart-2-line h5 mb-0"></i></Link> */}
          <button
            id="profile"
            className=" btn1 profile-Order pointer bg-white"
            onClick={() => setIsCreateOrder(true)}
            type="button"
          >
            {/* <i className="fa-solid fa-plus text-white"></i> */}
            <img
              crossOrigin="anonymous"
              src={"/images/plus.svg"}
              className="img-fluid"
              alt="Logo"
            />
          </button>
          <Dropdown className="profile-drp">
            <Dropdown.Toggle id="profile">
              <span className="profile-icon">
                {NameArray?.length > 1
                  ? `${NameArray?.[0]?.slice(0, 1)}${NameArray?.[1]?.slice(
                      0,
                      1
                    )}`
                  : ShortName}
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {/* <Dropdown.Item
                href="#"
                onClick={() => navigate("/admin/profile")}
              >
                <i className="fa fa-user me-2 text-primary"></i> Change Profile
              </Dropdown.Item> */}
              <Dropdown.Item
                href="#"
                onClick={() => navigate("/admin/change-password")}
                className="text-decoration-none"
              >
                <i className="fa fa-key me-2"></i> Change Password
              </Dropdown.Item>
              {/* <Dropdown.Item href="#/action-3"><i className="fa-solid fa-table-columns me-2 text-primary"></i>Set Column result</Dropdown.Item> */}
              {/* <Dropdown.Item href="#" onClick={handleShow}>
                <i className="fa fa-comments me-2 text-primary"></i>Send
                Feedback
              </Dropdown.Item> */}
              <Dropdown.Item
                // href="/admin/login"
                className="text-decoration-none"
                onClick={handleShow}
              >
                <i className="fa fa-sign-out me-3 text-primary "></i>Log out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </header>
      <AdminSidebar />
      {/* <!-- Modal --> */}
      <Modal
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <div className="d-flex justify-content-center align-items-center my-3 py-3">
          <div className="text-center">
            <i
              className="fa fa-circle-xmark text-red"
              style={{ fontSize: "80px" }}
            ></i>
            <h4 className="text-center mt-3">
              Are you sure You want to log out?{" "}
            </h4>
            <h4 className="text-center mt-3"></h4>
            <div className="d-flex justify-content-center align-items-center mt-5">
              <button
                onClick={() => handleClose()}
                className="text-white bg-red border-0 fs-5 rounded py-2 px-5 me-4"
              >
                No
              </button>
              <button
                onClick={() => {
                  clearLocalStorage();
                }}
                className="text-white bg-red border-0 fs-5 rounded py-2 px-5"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        show={isCreateOrder}
        size="xl"
        onHide={() => {
          setIsCreateOrder(false);
        }}
        dialogClassName="modal-dialog-scrollable"
        backdrop="static"
      >
        <CreateOrder setIsCreateOrder={setIsCreateOrder} />
      </Modal>
    </>
  );
}

const mapStateToProp = (state) => ({
  adminLogoutReducer: state.AuthReducer.logOutReducer,
  profileDetails: state.AuthReducer.profileDetails,
  loginReducer: state.AuthReducer.loginReducer,
  // adminDetailsReducer: state.AuthReducer.profileDetails
});

const mapDispatchToProps = (dispatch) => ({
  adminLogout: () => dispatch(adminLogout()),
  getProfileDetails: () => dispatch(getProfileDetails()),
  resetLogout: () => dispatch({ type: RESET_ADMIN_LOGOUT }),
});

export default connect(mapStateToProp, mapDispatchToProps)(AdminHeader);

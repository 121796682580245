import { CHANGE_PASSWORD_ERROR, CHANGE_PASSWORD_LOADING, CHANGE_PASSWORD_SUCCESS, FORGOT_PASSWORD_ERROR, FORGOT_PASSWORD_LOADING, FORGOT_PASSWORD_SUCCESS, OTP_VERIFY_ERROR, OTP_VERIFY_LOADING, OTP_VERIFY_SUCCESS, RESET_CHANGE_PASSWORD, RESET_FORGOT_PASSWORD, RESET_OTP_VERIFY, RESET_PASSWORD_ERROR, RESET_PASSWORD_LOADING, RESET_PASSWORD_SUCCESS, RESET_RESET_PASSWORD } from "../../../Constants/Constants";

let initialState = {
  forgotPassword: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
  verifyOtp: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
  resetPassword: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
  changePassword: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  }
};

export default function PasswordReducer(state = initialState, action) {
  switch (action.type) {
    case FORGOT_PASSWORD_LOADING:
      return { ...state, forgotPassword: { ...state.forgotPassword, loading: true, error: false, errors: [], success: false } };

    case FORGOT_PASSWORD_SUCCESS:
      return { ...state, forgotPassword: { ...state.forgotPassword, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case FORGOT_PASSWORD_ERROR:
      return { ...state, forgotPassword: { ...state.forgotPassword, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_FORGOT_PASSWORD:
      return { ...state, forgotPassword: { ...state.forgotPassword, loading: false, error: false, errors: null, success: false } };


    // verify otp

    case OTP_VERIFY_LOADING:
      return { ...state, verifyOtp: { ...state.verifyOtp, loading: true, error: false, errors: [], success: false } };

    case OTP_VERIFY_SUCCESS:
      return { ...state, verifyOtp: { ...state.verifyOtp, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case OTP_VERIFY_ERROR:
      return { ...state, verifyOtp: { ...state.verifyOtp, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_OTP_VERIFY:
      return { ...state, verifyOtp: { ...state.verifyOtp, loading: false, error: false, errors: null, success: false } };



    // reset pass

    case RESET_PASSWORD_LOADING:
      return { ...state, resetPassword: { ...state.resetPassword, loading: true, error: false, errors: [], success: false } };

    case RESET_PASSWORD_SUCCESS:
      return { ...state, resetPassword: { ...state.resetPassword, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case RESET_PASSWORD_ERROR:
      return { ...state, resetPassword: { ...state.resetPassword, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_RESET_PASSWORD:
      return { ...state, resetPassword: { ...state.resetPassword, loading: false, error: false, errors: null, success: false } };



      // change pass

    case CHANGE_PASSWORD_LOADING:
      return { ...state, changePassword: { ...state.changePassword, loading: true, error: false, errors: [], success: false } };

    case CHANGE_PASSWORD_SUCCESS:
      return { ...state, changePassword: { ...state.changePassword, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case CHANGE_PASSWORD_ERROR:
      return { ...state, changePassword: { ...state.changePassword, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_CHANGE_PASSWORD:
      return { ...state, changePassword: { ...state.changePassword, loading: false, error: false, errors: null, success: false } };





    // change pass




    default:
      return state;
  }
}

import axios from "axios";
import {
  addPrm_Url,
  deletePrm_Url,
  getPrmbyId_Url,
  getPrm_Url,
  updatePrm_Url,
} from "../../apiSheet";
import {
  ADD_PRM_ERROR,
  ADD_PRM_LOADING,
  ADD_PRM_SUCCESS,
  DELETE_PRM_ERROR,
  DELETE_PRM_LOADING,
  DELETE_PRM_SUCCESS,
  GET_PRM_BY_ID_ERROR,
  GET_PRM_BY_ID_LOADING,
  GET_PRM_BY_ID_SUCCESS,
  GET_PRM_ERROR,
  GET_PRM_LOADING,
  GET_PRM_SUCCESS,
  UPDATE_PRM_ERROR,
  UPDATE_PRM_LOADING,
  UPDATE_PRM_SUCCESS,
} from "../../Constants/Constants";

export const getPrmList = () => {
  return (dispatch) => {
    dispatch({
      type: GET_PRM_LOADING,
    });
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${getPrm_Url}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_PRM_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message,
            pagination: {
              page: resp.data.page,
              limit: resp.data.limit,
              totalpage: resp.data.totalpage,
            },
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_PRM_ERROR,
            netError: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: GET_PRM_ERROR,
            errors: validationError,
          });
        } 
      });
  };
};

export const addPrm = (details) => {
  return (dispatch) => {
    dispatch({
      type: ADD_PRM_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${addPrm_Url}`,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: ADD_PRM_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: ADD_PRM_ERROR,
            netError: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: ADD_PRM_ERROR,
            errors: validationError,
          });
        } else if (data.status === 401) {
          window.location.reload();
        }
      });
  };
};

export const getPrmById = (id) => {
  return (dispatch) => {
    dispatch({
      type: GET_PRM_BY_ID_LOADING,
    });
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${getPrmbyId_Url}/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_PRM_BY_ID_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_PRM_BY_ID_ERROR,
            netError: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: GET_PRM_BY_ID_ERROR,
            errors: validationError,
          });
        } 
      });
  };
};

export const updatePrm = (details) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_PRM_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${updatePrm_Url}/${details.id}`,
      data: details.data,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: UPDATE_PRM_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: UPDATE_PRM_ERROR,
            netError: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: UPDATE_PRM_ERROR,
            errors: validationError,
          });
        } else if (data.status === 401) {
          window.location.reload();
        }
      });
  };
};

export const deletePrm = (id) => {
  return (dispatch) => {
    dispatch({
      type: DELETE_PRM_LOADING,
    });
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${deletePrm_Url}/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: DELETE_PRM_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: DELETE_PRM_ERROR,
            netError: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: DELETE_PRM_ERROR,
            errors: validationError,
          });
        } else if (data.status === 401) {
          window.location.reload();
        }
      });
  };
};

import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import Toaster from "../../../Components/Toaster/Toaster";
// import {
//   getLocationListData,
//   updateAgentsData,
// } from "../../../Componant/Store/Action/CreateAgents/UserAction";
// import { updateAgentsData } from "../Store/Action/CreateAgents/CreateAgentsAction";
// import Footer from "../../../Componant/Userlayout/Footer";
// import Header from "../../../Componant/Userlayout/Header";
import {
  SuperadminchangePassword,
  UpdatePRMForLocation,
  getLocationByPRMDataAction,
  getLocationListData,
  getViewLocationById,
} from "../../../Store/Action/CreateAgents/UserAction";
import { updateAgentsData } from "../../../Store/Action/CreateAgents/UserAction";
import {
  contactValidation,
  countryCodeValidation,
  validEmailRegex,
  onlyNumberValidation,
  validPassword,
  RESET_UPDATE_AGENTS_DATA,
  RESET_SUPERADMIN_CHANGE_PASSWORD,
  RESET_SUPERADMIN_TO_ADMIN_CHANGE_PASSWORD,
  Sales_tax_Validation,
  formatPhoneNumber,
  Us_Pattern_Number,
  RESET_UPDATE_PRM_FOR_LOCATION,
} from "../../../Store/type/Constant";
import Footer from "../Userlayout/Footer";
import Header from "../Userlayout/Header";
import { changePassword } from "../../../Admin/Services/Actions/AuthAction";
import { Loader } from "rsuite";
import MaskedInput from "react-text-mask";

const EditLocation = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [locatinPRMList, setLocationPRMList] = useState([]);
  const [selectPRM, setSelectPRM] = useState('');
  const notifyError = (msg) => toast.error(msg);
  const successMsg = (msg) => toast.success(msg);
  const [isPRMModal, setIsPRMModal] = useState(false);

  const [visible, setvisible] = useState(false);
  const [Confirmvisible, setConfirmvisible] = useState(false);
  const [OldVisible, setOldVisible] = useState(false);

  const successTost = (msg) => toast.success(msg);
  const errorNotify = (msg) => toast.error(msg);
  const [btnLoading, setbtnLoading] = useState(false);

  const [state, setState] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

  const [error, setError] = useState({
    old_password: false,
    new_password: false,
    confirm_password: false,
  });

  const [ShowChangePassword, setShowChangePassword] = useState(false);


  // const ByIDName = useSelector(
  //   (state) => state.getViewLocationByIdReducer.getViewLocationById?.data?.data
  // );

  const [locationID, setLocationId] = useState('')


  const [inputData, setinputData] = useState({
    status: "",
    name: "",
    location_name: "",
    email: "",
    color: "",
    number: "",
    name: "",
    countrycode: "",
    salestax: 0,
  });

  const [checkupdateValue, setcheckupdateValue] = useState({
    location_name: "",
    color: "",
    status: "",
    name: "",
  });

  const [errors, setErrors] = useState({
    name: false,
    email: false,
    number: false,
    color: false,
    countrycode: false,
    password: false,
    confirmpassword: false,
    location_name: false,
    salestax: false,
  });

  const handleClose = () => {
    props.setEditShow(false);
  };

  useEffect(() => {
    if (props.getLocationByPRMreducer.success) {
      const data = props.getLocationByPRMreducer.data
      setLocationPRMList(data)
    }
  }, [props.getLocationByPRMreducer])

  useEffect(() => {
    if (props.selectedId) {
      props.getViewLocationById(props.selectedId);
      props.getLocationByPRMDataAction(props.selectedId)
    }
  }, [props.selectedId]);

  useEffect(() => {
    if (props.getViewLocationByIdReducer.success) {
      const data = props.getViewLocationByIdReducer.data.data;
      setcheckupdateValue((prevState) => ({
        status: data[0]?.status === 1 ? "active" : "Inactive",
        color: data[0].color,
        location_name: data[0].location_name,
      }));
      setinputData((prevState) => ({
        ...prevState,
        status: data[0]?.status === 1 ? "active" : "Inactive",
        email: data[0].locationdata[0]?.email,
        color: data[0].color,
        number: formatPhoneNumber(data[0].locationdata[0]?.number),
        location_name: data[0].location_name,
        name: data[0].locationdata[0]?.name,
        salestax: data[0]?.salestax ? data[0]?.salestax : 0,
      }));
      setLocationId(data[0]?._id)
    }
  }, [props.getViewLocationByIdReducer]);

  useEffect(() => {
    if (props.editAgentsReducer.success) {
      setTimeout(() => {
        successMsg("Location updated successfully. ");
      }, 100);
      props.getLocationListData();
      props.resetlocation();
      handleClose();
      setbtnLoading(false);
    }
    if (props.editAgentsReducer?.error) {
      notifyError(props.editAgentsReducer.errors);
      props?.resetlocation();
      setbtnLoading(false);
    }
  }, [props.editAgentsReducer]);

  useEffect(() => {
    if (props.SuperadminchangePasswordReducer?.success) {
      successTost("Password changed successfully.");
      BackBack(true);
      setTimeout(() => {
        props.resetchangePassword();
      }, 2200);
    } else if (props.SuperadminchangePasswordReducer.error) {
      errorNotify(props.SuperadminchangePasswordReducer.errors);
      props.resetchangePassword();
    }
  }, [props.SuperadminchangePasswordReducer]);

  const getValue = (e) => {
    const { id, value, name } = e.target;

    if (e.target.name === "number") {
      if (e.target.value.length <= 14) {
        setinputData((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value.replace(
            /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/
          ),
        }));
      } else return;
    }

    if (e.target.name === "salestax") {
      if (e.target.value.length <= 3) {
        setinputData((prevState) => ({
          ...prevState,
          salestax:
            e.target.value === "" ? 0 : Number(e.target.value).toString(),
        }));
      } else return;
    } else if (
      name == "confirm_password" ||
      name == "new_password" ||
      name == "old_password"
    ) {
      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      setinputData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

    switch (name) {
      case "old_password":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            old_password: "Enter Old Password",
          }));
        } else if (!validPassword.test(value)) {
          setError((prevState) => ({
            ...prevState,
            old_password:
              "Password contail atleast 1 number, 1 charecter and 1 symbol",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            old_password: false,
          }));
        }
        break;

      case "new_password":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            new_password: "Enter new Password",
          }));
        } else if (!validPassword.test(value)) {
          setError((prevState) => ({
            ...prevState,
            new_password:
              "Password contail atleast 1 number, 1 charecter and 1 symbol",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            new_password: false,
          }));
        }
        break;

      case "confirm_password":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            confirm_password: "Enter confirm new Password",
          }));
        } else if (!validPassword.test(value)) {
          setError((prevState) => ({
            ...prevState,
            confirm_password:
              "Password contail atleast 1 number, 1 charecter and 1 symbol",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            confirm_password: false,
          }));
        }
        break;
      case "location_name":
        if (e.target.value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            location_name: "Location name can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            location_name: false,
          }));
        }
        break;

      case "salestax":
        if (e.target.value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            salestax: "Location salestax can not be empty",
          }));
        } else if (!Sales_tax_Validation.test(e.target.value)) {
          setErrors((prevState) => ({
            ...prevState,
            salestax: "Enter valid salestax",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            salestax: false,
          }));
        }
        break;

      case "name":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            name: "Location name can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            name: false,
          }));
        }
        break;

      case "email":
        if (e.target.value === "") {
          setErrors((prevState) => ({
            ...prevState,
            email: "Location Email can not be empty",
          }));
        } else if (!validEmailRegex.test(e.target.value)) {
          setErrors((prevState) => ({
            ...prevState,
            email: "Enter valid email",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            email: false,
          }));
        }
        break;

      case "color":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            color: "Location Color can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            color: false,
          }));
        }
        break;

      case "number":
        if (e.target.value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            number: "number can not be empty",
          }));
        } else if (!Us_Pattern_Number.test(e.target.value)) {
          setErrors((prevState) => ({
            ...prevState,
            number: "Enter valid number",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            number: false,
          }));
        }
        break;

      case "countrycode":
        if (e.target.value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            countrycode: "countrycode can not be empty",
          }));

          // document.getElementById("countrycode").classList.add("error");
        } else if (!countryCodeValidation.test(e.target.value)) {
          setErrors((prevState) => ({
            ...prevState,
            countrycode: "countrycode can not be match",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            countrycode: false,
          }));

        }
        break;

      case "password":
        if (e.target.value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            password: "Password can not be empty",
          }));

        } else {
          setErrors((prevState) => ({
            ...prevState,
            password: false,
          }));

        }
        break;

      case "confirmpassword":
        if (e.target.value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            confirmpassword: "confirmpassword can not be empty",
          }));

        } else if (inputData.password === inputData.confirmpassword) {
          setErrors((prevState) => ({
            ...prevState,
            confirmpassword: "confirmpassword can not be match",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            confirmpassword: false,
          }));

        }
        break;

      default:
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (inputData.location_name == "") {
      setErrors((prevState) => ({
        ...prevState,
        location_name: "Location name can not be empty",
      }));
    }
    if (inputData.name == "") {
      setErrors((prevState) => ({
        ...prevState,
        name: "Location name can not be empty",
      }));
    }

    if (inputData.email == "") {
      setErrors((prevState) => ({
        ...prevState,
        email: "Location email can not be empty",
      }));
    } else if (!validEmailRegex.test(inputData.email)) {
      setErrors((prevState) => ({
        ...prevState,
        email: "Enter valid email",
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        email: false,
      }));
    }

    if (inputData.color == "") {
      setErrors((prevState) => ({
        ...prevState,
        color: "Location color can not be empty",
      }));
    }

    if (inputData.password == "") {
      setErrors((prevState) => ({
        ...prevState,
        password: "password can not be empty",
      }));
    }
    if (inputData.password == inputData.confirmpassword) {
      setErrors((prevState) => ({
        ...prevState,
        confirmpassword: "password cant not match",
        password: "password can not match",
      }));
    }

    if (inputData.number == "") {
      setErrors((prevState) => ({
        ...prevState,
        number: "number can not be empty",
      }));
    } else if (!Us_Pattern_Number.test(inputData.number)) {
      setErrors((prevState) => ({
        ...prevState,
        number: "Enter valid number",
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        number: false,
      }));
    }

    if (inputData.salestax == "") {
      setErrors((prevState) => ({
        ...prevState,
        salestax: "salestax can not be empty",
      }));
    } else if (!Sales_tax_Validation.test(inputData.salestax)) {
      setErrors((prevState) => ({
        ...prevState,
        salestax: "Enter valid salestax",
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        salestax: false,
      }));
    }

    if (inputData.confirmpassword == "") {
      setErrors((prevState) => ({
        ...prevState,
        confirmpassword: "confirmpassword can not be empty",
      }));
    }

    if (
      inputData.location_name !== "" &&
      inputData.color !== "" &&
      inputData.name !== "" &&
      inputData.email !== "" &&
      inputData.salestax !== "" &&
      inputData.salestax !== undefined &&
      !errors.salestax &&
      !errors.email &&
      !errors.number
    ) {
      const details = {
        location_name: inputData.location_name,
        name: inputData.name,
        email: inputData.email,
        color: inputData.color,
        number: inputData.number,
        status: inputData?.status === "active" ? 1 : 0,
        salestax: inputData.salestax,
      };
      props.updateAgentsDatahandler({
        id: props.selectedId,
        data: details,
      });
      setbtnLoading(true);
    }
  };

  const savePassword = (e) => {
    e.preventDefault();
    if (state.old_password === "") {
      setError((prevState) => ({
        ...prevState,
        old_password: "Enter Old Password",
      }));
    } else if (state.new_password === "") {
      setError((prevState) => ({
        ...prevState,
        new_password: "Enter new Password",
      }));
    } else if (state.confirm_password === "") {
      setError((prevState) => ({
        ...prevState,
        confirm_password: "Enter confirm new password",
      }));
    } else if (state.new_password !== state.confirm_password) {
      setError((prevState) => ({
        ...prevState,
        confirm_password: "new password and confirm password not match",
      }));
    }

    if (state.new_password !== state.confirm_password) {
      errorNotify("Password does not match");
    }

    if (
      // state.old_password !== "" &&
      state.new_password !== "" &&
      state.confirm_password !== "" &&
      // !error.old_password &&
      !error.new_password &&
      !error.confirm_password &&
      state.confirm_password === state.new_password
    ) {
      const details = {
        // current_password: state.old_password,
        new_password: state.new_password,
        confirm_password: state.confirm_password,
        id: props.adminId,
      };
      props.SuperadminchangePassword(details);
    }
  };

  const passwordvisible = () => {
    setvisible(!visible);
  };
  const Confirmpasswordvisible = () => {
    setConfirmvisible(!Confirmvisible);
  };
  const OldPasswordVisible = () => {
    setOldVisible(!OldVisible);
  };

  const Back = () => {
    setShowChangePassword(true);
    setState({
      old_password: "",
      new_password: "",
      confirm_password: "",
    });
  };
  const BackBack = () => {
    setShowChangePassword(false);
  };


  useEffect(() => {
    if (props.UpdatePrmForLocationReducer.success) {
      setIsPRMModal(false)
      props.getViewLocationById(props.selectedId);
      props.resetUpdatePRM()
      toast.success('PRM Updated Successfully')
    } else if (props.UpdatePrmForLocationReducer.error) {
      notifyError('Get Error')
    }
  }, [props.UpdatePrmForLocationReducer])

  return (
    <>
      <>
        <Modal.Header closeButton>
          <Modal.Title>Edit Location</Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <div className="form-area">
            <Form className="row gx-3">
              <div className="col-6">
                <Form.Group className="mb-3">
                  <Form.Label>Location Name:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter location name"
                    name="location_name"
                    onChange={getValue}
                    value={inputData?.location_name}
                    id="location_name"
                    className={classNames({
                      requireField: errors.location_name,
                    })}
                  />
                </Form.Group>
              </div>
              <div className="col-6">
                <Form.Group className="mb-3">
                  <Form.Label>Admin Name:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter name"
                    name="name"
                    onChange={getValue}
                    label="name"
                    value={inputData?.name}
                    id="name"
                    // disabled
                    className={classNames({
                      requireField: errors.name,
                    })}
                  />
                </Form.Group>
              </div>
              <div className="col-6">
                <Form.Group className="mb-3">
                  <Form.Label>Email Address:</Form.Label>
                  <Form.Control
                    id="email"
                    type="email"
                    placeholder="Enter Email Address"
                    name="email"
                    onChange={getValue}
                    label="email"
                    // disabled
                    value={inputData?.email}
                    className={classNames({
                      requireField: errors.email,
                    })}
                  />
                </Form.Group>
              </div>
              <div className="col-6">
                <Form.Label>Contact No.:</Form.Label>
                <MaskedInput
                  mask={[
                    "(",
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    ")",
                    " ",
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    "-",
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                  ]}
                  name="number"
                  placeholder="Enter Contact No"
                  guide={false}
                  id="my-input-id number"
                  value={inputData?.number}
                  onKeyUp={getValue}
                  onChange={getValue}
                  className={classNames("form-control mb-3", {
                    requireField: errors.number,
                  })}
                />
              </div>
              <div className="col-6"></div>{" "}
              {/* <div className="col-2">
                <Form.Group className="mb-3">
                  <Form.Label>Code :</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="+91"
                    value={`${inputData?.countrycode}`}
                    name="countrycode"
                    label="countrycode"
                    onChange={getValue}
                    id="countrycode"
                    disabled
                    // className={classNames({
                    //   requireField: errors.countrycode,
                    // })}
                  />
                </Form.Group>
              </div> */}
              <div className="col-5"></div>
              <div className="col-2">
                {" "}
                <Form.Group className="mb-3">
                  <Form.Label>Color:</Form.Label>
                  <Form.Control
                    type="color"
                    placeholder="Enter password"
                    name="color"
                    id="color"
                    value={inputData?.color}
                    // disabled
                    onChange={getValue}
                    className={
                      "w-100" +
                      classNames({
                        requireField: errors.color,
                      })
                    }
                  />
                </Form.Group>
              </div>
              <div className="col-md-3 col-4">
                <Form.Label>Status:</Form.Label>
                <Form.Select
                  value={inputData?.status}
                  onChange={(e) => {
                    setinputData((prevState) => ({
                      ...prevState,
                      status: e.target.value,
                    }));
                  }}
                >
                  <option value="active">Active</option>
                  <option value="Inactive">Inactive</option>
                </Form.Select>
              </div>
              <div className="col-1"></div>
              <div className="col-md-3 col-4">
                <Form.Group className="mb-3">
                  <Form.Label style={{ whiteSpace: "nowrap" }}>
                    Sales Tax (In Percentage):
                  </Form.Label>
                  <Form.Control
                    type="salestax"
                    placeholder="Enter sales tax amount"
                    name="salestax"
                    id="salestax"
                    value={inputData?.salestax}
                    onChange={getValue}
                    className={classNames({
                      requireField: errors.salestax,
                    })}
                  />
                </Form.Group>
              </div>
              <div></div>
              {ShowChangePassword && (
                <div className="col-md-12">
                  <div className="address-container row w-100 gx-2">
                    <h4 className="text-center mb-4">Change Password</h4>

                    <Form.Group className="col-4 position-relative">
                      <Form.Label>New Password:</Form.Label>
                      <Form.Control
                        type={!!visible ? "text" : "password"}
                        placeholder="Enter New Password"
                        name="new_password"
                        value={state.new_password}
                        onChange={getValue}
                        className={classNames("", {
                          requireField: error.new_password,
                        })}
                      />
                      <div onClick={passwordvisible}>
                        {visible ? (
                          <i className="fa fa-eye eye-location pointer fs-5" />
                        ) : (
                          <i className="fa fa-eye-slash eye-location pointer fs-5" />
                        )}
                      </div>
                    </Form.Group>
                    <Form.Group className="col-6 position-relative">
                      <Form.Label>Confirm Password:</Form.Label>
                      <Form.Control
                        type={!!Confirmvisible ? "text" : "password"}
                        name="confirm_password"
                        placeholder="Enter Confirm Password"
                        value={state.confirm_password}
                        onChange={getValue}
                        className={classNames("", {
                          requireField: error.confirm_password,
                        })}
                      />
                      <div onClick={Confirmpasswordvisible}>
                        {Confirmvisible ? (
                          <i className="fa fa-eye eye-location pointer fs-5" />
                        ) : (
                          <i className="fa fa-eye-slash eye-location pointer fs-5" />
                        )}
                      </div>
                    </Form.Group>
                    <div className="text-end">
                      <Button
                        type="button"
                        className="me-2 border rounded pointer px-4 py-2 text-white bg-red mt-4"
                        onClick={savePassword}
                      >
                        Save Password
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </Form>
          </div>
          <div className="text-end mt-3">
            <Button
              type="button"
              className="me-2 border rounded pointer px-4 py-2 text-white bg-red mt-4"
              onClick={() => { setIsPRMModal(true) }}
            >
              Change PRM
            </Button>
            {ShowChangePassword ? (
              <Button
                type="button"
                className="me-2 border rounded pointer px-4 py-2 text-white bg-red mt-4"
                onClick={BackBack}
              >
                Back
              </Button>
            ) : (
              <></>
            )}
            <Button
              type="button"
              className="border rounded pointer px-4 py-2 text-white bg-red mt-4"
              onClick={onSubmit}
            >
              {btnLoading ? <Loader /> : "Submit"}
            </Button>
          </div>
        </Modal.Body>
      </>
      <Modal show={isPRMModal}
        onHide={() => setIsPRMModal(false)}
        size="lg"
        backdrop="static"

      >
        <Modal.Header closeButton>
          <Modal.Title>Change PRM</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{
          height: '280px'
        }}>
          <div className="py-4 px-2">
            <Form.Label>Select PRM:</Form.Label>
            <Form.Select
              value={selectPRM}
              onChange={(e) => {
                setSelectPRM(e.target.value)
              }}
            >
              <option value="">Select PRM</option>
              {
                locatinPRMList.length !== 0 && locatinPRMList.filter(e => e.primary_admin == 0).map((e, i) => {
                  return <option value={e._id} key={i}>{e.name}</option>
                })
              }
            </Form.Select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="button"
            className="border rounded pointer px-4 py-2 text-white bg-red mt-4"
            onClick={() => {
              {
                if (locationID !== '' && selectPRM !== '') {
                  props.UpdatePRMForLocation({
                    location: locationID,
                    prm_id: selectPRM
                  })
                }
              }
            }}
          >
            Change PRM
            {/* {btnLoading ? <Loader /> : "Submit"} */}
          </Button></Modal.Footer>
      </Modal>
      {/* <Toaster /> */}
    </>
  );
};

const mapStateToProp = (state) => ({
  editAgentsReducer: state.editAgentsReducer,
  getViewLocationByIdReducer:
    state.getViewLocationByIdReducer.getViewLocationById,
  SuperadminchangePasswordReducer:
    state.SuperadminPasswordReducer.SuperadminchangePassword,
  getLocationByPRMreducer: state.getLocationByPRM.getLocationByPRM,
  UpdatePrmForLocationReducer: state.UpdatePrmForLocationReducer.updatePRMForLocation,

});

const mapDispatchToProps = (dispatch) => ({
  updateAgentsDatahandler: (details) => dispatch(updateAgentsData(details)),
  getViewLocationById: (id) => dispatch(getViewLocationById(id)),
  getLocationByPRMDataAction: (id) => dispatch(getLocationByPRMDataAction(id)),
  getLocationListData: () => dispatch(getLocationListData()),
  UpdatePRMForLocation: (details) => dispatch(UpdatePRMForLocation(details)),
  resetlocation: () => dispatch({ type: RESET_UPDATE_AGENTS_DATA }),
  resetUpdatePRM: () => dispatch({ type: RESET_UPDATE_PRM_FOR_LOCATION }),
  resetchangePassword: () =>
    dispatch({ type: RESET_SUPERADMIN_TO_ADMIN_CHANGE_PASSWORD }),
  SuperadminchangePassword: (details) =>
    dispatch(SuperadminchangePassword(details)),
});
export default connect(mapStateToProp, mapDispatchToProps)(EditLocation);

import { IMPORT_MENU_EXCEL_ERROR, IMPORT_MENU_EXCEL_LOADING, IMPORT_MENU_EXCEL_SUCCESS, RESET_IMPORT_MENU_EXCEL } from "../../type/Constant";

let initialState = {
  importExcel: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
  },
};

export default function importMenuItemExcel(state = initialState, action) {
  switch (action.type) {
    case IMPORT_MENU_EXCEL_LOADING:
      return {
        ...state,
        importExcel: {
          ...state.importExcel,
          loading: true,
          error: false,
          errors: [],
          success: false,
        },
      };

    case IMPORT_MENU_EXCEL_SUCCESS:
      return {
        ...state,
        importExcel: {
          ...state.importExcel,
          loading: false,
          error: false,
          errors: [],
          success: true,
          //   data: action.response,
        },
      };

    case IMPORT_MENU_EXCEL_ERROR:
      return {
        ...state,
        importExcel: {
          ...state.importExcel,
          loading: false,
          error: true,
          errors: action.errors,
          success: false,
          data: null,
        },
      };

    case RESET_IMPORT_MENU_EXCEL:
      return {
        ...state,
        importExcel: {
          ...state.importExcel,
          loading: false,
          error: false,
          errors: null,
          success: false,
        },
      };

    default:
      return state;
  }
}

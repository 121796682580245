import {
  ADD_DELIVERY_AGENTS_DATA_ERROR,
  ADD_DELIVERY_AGENTS_DATA_LOADING,
  ADD_DELIVERY_AGENTS_DATA_SUCCESS,
  RESET_ADD_DELIVERY_DATA,
} from "../type/Constant";

let initialState = {
  loading: false,
  user: [{}],
  error: null,
  errors: null,
  success: false,
  payload: {},
};

export default function createDeliveryAgentsReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case ADD_DELIVERY_AGENTS_DATA_LOADING:
      return { ...state, error: false, loading: true, success: false };

    case ADD_DELIVERY_AGENTS_DATA_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        success: true,
        message: action.msg,
      };

    case ADD_DELIVERY_AGENTS_DATA_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        success: false,
        errors: action.errors,
      };

    case RESET_ADD_DELIVERY_DATA:
      return {
        ...state,
        loading: false,
        user: [{}],
        error: false,
        errors: [],
        success: false,
        payload: {},
      };

    default:
      return state;
  }
}

import {
  RESET_UPDATE_AGENTS_DATA,
  UPDATE_AGENTS_DATA_ERROR,
  UPDATE_AGENTS_DATA_LOADING,
  UPDATE_AGENTS_DATA_SUCCESS,
} from "../type/Constant";

let initialState = {
  error: false,
  success: false,
  loading: false,
  errors: [],
};

export default function editAgentsReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_AGENTS_DATA_LOADING:
      return { ...state, error: false, loading: true, success: false };
    case UPDATE_AGENTS_DATA_SUCCESS:
      return {
        ...state,
        error: false,
        success: true,
        loading: false,
        message: action.msg,
      };
    case UPDATE_AGENTS_DATA_ERROR:
      return { ...state, error: true, success: false, loading: false, errors: action.errors };

    case RESET_UPDATE_AGENTS_DATA:
      return {
        ...state,
        error: false,
        loading: false,
        success: false,
        errors: [],
      };
  
    default:
      return state;
  }
}

import React, { useEffect, useState, CSSProperties } from "react";
import { Button, Form } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
// import SelectSearch from "react-select-search";
import { toast } from "react-toastify";
import {
  createCutomerAddressDataAction,
  createCutomerDataAction,
  getAgentsData,
  getCutomerAddressDataAction,
  getCutomerDataAction,
} from "../../../Store/Action/CreateAgents/UserAction";
import {
  contactValidation,
  countryCodeValidation,
  onlyNumberValidation,
  validEmailRegex,
  validPassword,
} from "../../../Store/type/Constant";
import Footer from "../Userlayout/Footer";
import Header from "../Userlayout/Header";
// import Creatable from 'react-select/creatable';
// import CreatableSelect from "react-select/dist/declarations/src/Creatable";

const CreateCustomer = (props) => {
  

  return (
    <>
      <Header />
      <main className="userMain">
       
      </main>
      <Footer />
    </>
  );
};

const mapStateToProp = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  // getCutomerAddressHandler: (user) =>
  //   dispatch(getCutomerAddressDataAction(user)),
});
export default connect(mapStateToProp, mapDispatchToProps)(CreateCustomer);

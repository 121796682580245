// const baseUrl = "http://192.168.0.35:3300";

const baseUrl = "https://server.caterize.io";

// .................  ProfileData  ...............
export const getSuperAdminProfileDetails_Url = `${baseUrl}/getsuperadmindata`;

export const adminLoginUrl = `${baseUrl}/superlogin`;

export const createAgentUrl = `${baseUrl}/api/addlocation`;

export const getAgentsUrl = `${baseUrl}/api/location`;

export const getLocationListUrl = `${baseUrl}/api/locationlist`;

export const deleteAgentsUrl = `${baseUrl}/api/deletelocation`;

export const updateAgentsUrl = `${baseUrl}/api/updatelocation`;

export const updateViewLocationUrl = `${baseUrl}/api/viewlocation`;

export const locationwiseTipDetails = `${baseUrl}/api/locationowisetipamount`;

// ........................  Prm  ................

export const getPrmUrl = `${baseUrl}/api/prmforsuperadmin`;

export const createPrmUrl = `${baseUrl}/api/addprm`;

export const deletePrmUrl = `${baseUrl}/api/deleteprm`;

export const updatePrmUrl = `${baseUrl}/api/updateprm`;

export const updatePrmByID_Url = `${baseUrl}/api/viewprm`;

export const locationByPRMUrl = `${baseUrl}/api/locationowiseprm`;

// ........................  Menu Item ................

export const getMenuItemUrl = `${baseUrl}/api/menuitemforsuperadmin`;

export const createMenuItemUrl = `${baseUrl}/api/addmenuitem`;

export const updateMenuItemUrl = `${baseUrl}/api/updatemenuitem`;

export const updateMenuByIdUrl = `${baseUrl}/api/viewmenuitem`;

export const deleteMenuItemUrl = `${baseUrl}/api/deletemenuitem`;

// ........................  Drivers ................

export const getdeliveryagentUrl = `${baseUrl}/api/getagentforsuperadmin`;

export const createdeliveryItemUrl = `${baseUrl}/api/addagent`;

export const updatedeliveryagentUrl = `${baseUrl}/api/updateagent`;

export const getdeliveryagentByIDUrl = `${baseUrl}/api/viewagent`;

// export const deletedeliveryagentUrl = `${baseUrl}/api/viewagent`;

export const deletedeliveryagentUrl = `${baseUrl}/api/deleteagent`;

export const locationByAgentUrl = `${baseUrl}/api/locationwiseagents`;

// ........................  Customer ................

export const getcustomerUrl = `${baseUrl}/api/getcustomer`;

export const createcustomerUrl = `${baseUrl}/api/createcustomer`;

export const updatecustomerUrl = `${baseUrl}/api/updatecustomer`;

export const deletecustomerUrl = `${baseUrl}/api/deletecustomer`;

export const getcustomerByIdUrl = `${baseUrl}/api/customerdata`;

// ........................  Customer Address ................

export const getcustomeeAddressUrl = `${baseUrl}/api/getaddress`;

export const createcustomeeAddressUrl = `${baseUrl}/api/customeraddress`;

export const updatecustomeeAddressUrl = `${baseUrl}/api/updateaddress`;

export const deletecustomeeAddressUrl = `${baseUrl}/api/deleteaddress`;

// export const deletecustomeeAddressUrl = `${baseUrl}/api/deleteaddress`;

// ........................  Order ................

export const getordarUrl = `${baseUrl}/api/getallorder`;

export const createordarUrl = `${baseUrl}/api/createorder`;

export const updateordarUrl = `${baseUrl}/api/updateordar`;

export const deleteordarUrl = `${baseUrl}/api/deleteorder`;

export const findCustomer_Url = `${baseUrl}/api/getcustomercontact`; //post

export const locationByMenuUrl = `${baseUrl}/api/locationmenuitem`;

export const locationByAgentsUrl = `${baseUrl}/api/locationwiseagents`;

export const locationByOrderUrl = `${baseUrl}/api/locationowiseorder`;

export const CustomerSerachByContactUrl = `${baseUrl}/api/customer`;

export const CustomerSerachByNameUrl = `${baseUrl}/api/searchbynameandnumber`;

export const updateorderUrl = `${baseUrl}/api/updateorder`;

// ............................   Change password ............................

export const forgotPassword_Url = `${baseUrl}/superadminforgotpassword`;
export const verifyOtp_Url = `${baseUrl}/superadminotpvarify`;
export const resetPassword_Url = `${baseUrl}/superadminresetpassword`;
export const changepasswordUrl = `${baseUrl}/superadminchangepassword`;
export const SuperadminchangepasswordUrl = `${baseUrl}/admin/changepasswordbysuperadmin`;
export const agentchangepasswordUrl = `${baseUrl}/api/changepasswordbyadmin`;

export const locationWiseagentsUrl = `${baseUrl}/api/locationwiseagents`;

export const exportPdf_Url = `${baseUrl}/api/orderpdf`;

export const exportTipPdf_Url = `${baseUrl}/api/tipamountpdf`;

export const agentsWiseOrder = `${baseUrl}/api/agentwiseorder`;

export const reports_Order_Url = `${baseUrl}/api/report`;

export const reports_Location_Order_Url = `${baseUrl}/api/locationowiseordercount`;

export const freeAgent_Url = `${baseUrl}/api/freeagent`;

export const SuperadminOrderFilter_Url = `${baseUrl}/api/filterforsuperadmin`;

export const getswapagent_Url = `${baseUrl}/api/get-agent-with-Order`;

export const upload_csv_Url = `${baseUrl}/api/upload-menu`;

export const UpdatePRM_for_location_Url = `${baseUrl}/api/update-prm-Location`;

export const location_wise_customer_Url = `${baseUrl}/api/getcustomer`;

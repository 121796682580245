import {
  GET_VIEW_LOCATION_BY_ID_DATA_LOADING,
  GET_VIEW_LOCATION_BY_ID_DATA_SUCCESS,
  GET_VIEW_LOCATION_BY_ID__DATA_ERROR,
  LOCATION_WISE_TOTAL_TIP_ERROR,
  LOCATION_WISE_TOTAL_TIP_LOADING,
  LOCATION_WISE_TOTAL_TIP_SUCCESS,
  RESET_GET_VIEW_LOCATIONBY_ID_DATA_SUCCESS,
  RESET_LOCATION_WISE_TOTAL_TIP,
} from "../type/Constant";

let initialState = {
  getViewLocationById: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
  },
  LocationTotalTip: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
};

export default function getViewLocationByIdReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_VIEW_LOCATION_BY_ID_DATA_LOADING:
      return {
        ...state,
        getViewLocationById: {
          ...state.getViewLocationById,
          loading: true,
          error: false,
          errors: [],
          success: false,
        },
      };

    case GET_VIEW_LOCATION_BY_ID_DATA_SUCCESS:
      return {
        ...state,
        getViewLocationById: {
          ...state.getViewLocationById,
          loading: false,
          error: false,
          errors: [],
          success: true,
          data: action.response.data,
        },
      };

    case GET_VIEW_LOCATION_BY_ID__DATA_ERROR:
      return {
        ...state,
        getViewLocationById: {
          ...state.getViewLocationById,
          loading: false,
          error: true,
          errors: action.errors,
          success: false,
          data: null,
        },
      };

    case RESET_GET_VIEW_LOCATIONBY_ID_DATA_SUCCESS:
      return {
        ...state,
        getViewLocationById: {
          ...state.getViewLocationById,
          loading: false,
          error: false,
          errors: null,
          success: false,
        },
      };
    // ......................................................
    case LOCATION_WISE_TOTAL_TIP_LOADING:
      return {
        ...state,
        LocationTotalTip: {
          ...state.LocationTotalTip,
          loading: true,
          error: false,
          errors: [],
          success: false,
        },
      };

    case LOCATION_WISE_TOTAL_TIP_SUCCESS:
      return {
        ...state,
        LocationTotalTip: {
          ...state.LocationTotalTip,
          loading: false,
          error: false,
          errors: [],
          success: true,
          data: action.response.data,
        },
      };

    case LOCATION_WISE_TOTAL_TIP_ERROR:
      return {
        ...state,
        LocationTotalTip: {
          ...state.LocationTotalTip,
          loading: false,
          error: true,
          errors: action.errors,
          success: false,
          data: null,
        },
      };

    case RESET_LOCATION_WISE_TOTAL_TIP:
      return {
        ...state,
        LocationTotalTip: {
          ...state.LocationTotalTip,
          loading: false,
          error: false,
          errors: null,
          success: false,
        },
      };
    default:
      return state;
  }
}

import {
    GET_SIGNIN_ERROR,
    GET_SIGNIN_LOADING,
    GET_SIGNIN_SUCCESS,
    RESET_SIGNIN_DATA,
  } from "../../type/Constant";
  
  let initialState = {
    loginReducer: {
     loading: false,
     data: null,
     error: false,
     errors: null,
     success: false
    }
   };
  
  export const signinReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_SIGNIN_LOADING:
       return { ...state, loginReducer: { ...state.loginReducer, loading: true, error: false, errors: [], success: false } };
    
      case GET_SIGNIN_SUCCESS:
       return { ...state, loginReducer: { ...state.loginReducer, loading: false, error: false, errors: [], success: true, data: action.response.data } };
    
      case GET_SIGNIN_ERROR:
       return { ...state, loginReducer: { ...state.loginReducer, loading: false, error: true, errors: action.errors, success: false, data: null } };
    
      case RESET_SIGNIN_DATA:
       return { ...state, loginReducer: { ...state.loginReducer, loading: false, error: false, errors: null, success: false } };
     
      default:
        return state;
    }
  };
  
  export default signinReducer;

// const baseUrl = "http://192.168.0.35:3300/api";
// const baseUrlAuth = "http://192.168.0.35:3300/admin";

const baseUrl = "https://server.caterize.io/api";
const baseUrlAuth = "https://server.caterize.io/admin";

// auth
export const login_Url = `${baseUrlAuth}/adminlogin`;

// get profile data
export const getProfileDetails_Url = `${baseUrlAuth}/getadmindata`;

// menus
export const addMenus_Url = `${baseUrl}/addmenuitemsbyadmin`; //post
export const updateMenus_Url = `${baseUrl}/updatemenuitembyadmin`; //post /:id
export const getMenus_Url = `${baseUrl}/menuitemforadmin `; //get // all
export const deleteMenus_Url = `${baseUrl}/deletemenuitembyadmin`; //get /:id
export const getMenuById_Url = `${baseUrl}/viewmenuitembyadmin`; /// get /:id

// agent
export const addAgent_Url = `${baseUrl}/addagentbyadmin`; //post
export const updateAgent_Url = `${baseUrl}/updateagentbyadmin`; //post :id
export const deleteAgent_Url = `${baseUrl}/deleteagentbyadmin`; //get :id
export const getAgent_Url = `${baseUrl}/locationagentforadmin`; //get
export const getAgentbyId_Url = `${baseUrl}/viewagentbyadmin`; //get  :id

// Prm
export const addPrm_Url = `${baseUrl}/addprmbyadmin`; //post
export const updatePrm_Url = `${baseUrl}/updateprmbyadmin`; //post :id
export const deletePrm_Url = `${baseUrl}/deleteprmbyadmin`; //get :id
export const getPrm_Url = `${baseUrl}/prmforadmin  `; //get
export const getPrmbyId_Url = `${baseUrl}/viewprmbyadmin`; //get  :id

// order
export const getAllOrder_Url = `${baseUrl}/locationorderforadmin `; //get  :id
export const createOrder_Url = `${baseUrl}/createorderbyadmin`; //get  :id
export const deleteOrder_Url = `${baseUrl}/deleteorderbyadmin`; //get  :id
export const getOrderDetails_Url = `${baseUrl}/getorderbyadmin`; //get  :id

// find customer
export const findCustomer_Url = `${baseUrl}/getcustomercontact`; //post
export const admin_contact_search = `${baseUrl}/customerforadmin`; //post
export const admin_customer_search = `${baseUrl}/searchbynameandnumberforadmin`; //post

// logout

export const logout_Url = `${baseUrlAuth}/adminlogout`; //post

// password and otp

export const forgotPassword_Url = `${baseUrlAuth}/adminforgotpassword`;
export const verifyOtp_Url = `${baseUrlAuth}/adminotpvarify`;
export const resetPassword_Url = `${baseUrlAuth}/adminresetpassword`;

export const changePassword_Url = `${baseUrlAuth}/adminchangepassword`;

export const customertotalordercount_Url = `${baseUrl}/datewiseorderforcustomer`;

// export const getOtp_Url = `${baseUrl}/adminchangepassword`

export const freeAgentByAdmin_Url = `${baseUrl}/freeagentbyadmin`;
export const getadmindeliveryagentUrl = `${baseUrl}/getagentforadmin`;
export const adminagentwiseOrderUrl = `${baseUrl}/agentwiseorderbyadmin`;

export const updateadminorderUrl = `${baseUrl}/updateorderbyadmin`;

export const exportadminpdfUrl = `${baseUrl}/orderpdfforadmin`;

export const adminOrderFilter_Url = `${baseUrl}/filterforadmin`;

export const adminuploadcsv_Url = `${baseUrl}/upload-admin-menu`;

export const getAdminswapagent_Url = `${baseUrl}/get-agent-with-Order-admin`;

export const getReport_Url = `${baseUrl}/report-admin`;

export const getAdminCoustomer_Url = `${baseUrl}/getcustomer-admin`;

export const getAdminCoustomerbyId_Url = `${baseUrl}/customerdatabyadmin`;

export const getAdminCoustomerOrder_Url = `${baseUrl}/datewiseorderforcustomerbyadmin`;

export const deleteCoustomerAddress_Url = `${baseUrl}/deleteaddressbyadmin`;

export const editCoustomer_Url = `${baseUrl}/updatecustomerbyadmin`;

export const deleteCoustomer_Url = `${baseUrl}/deletecustomerbyadmin`;

export const createCoustomer_Url = `${baseUrl}/createcustomerbyadmin`;

import React, { useEffect, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getSuperAdminProfileDetails,
  LogInDataAction,
} from "../../../Store/Action/CreateAgents/UserAction";
// import imageurl from "../../images";
import Sidebar from "./Sidebar";
import CreateOrder from "../Order/CreateOrder";
import {
  RESET_FIND_SUPERADMIN_CUSTOMER_BY_CONTACT,
  removeStorage,
} from "../../../Store/type/Constant";
import { toast } from "react-toastify";

const Header = (props) => {
  const [show, setShow] = useState(false);
  const [isCreateOrder, setIsCreateOrder] = useState(false);
  // const [isDelete, setIsDelete] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [ShortName, setShortName] = useState("");
  const [NameArray, setNameArray] = useState("");

  const navigate = useNavigate();
  const clearLocalStorage = () => {
    localStorage.removeItem("userid");
    localStorage.removeItem("usertype");
    localStorage.removeItem("token");
    navigate("/superadmin/login");
  };
  useEffect(() => {
    if (props.getSuperAdminProfileReducer.profileDetails.success) {
      const data = props.getSuperAdminProfileReducer.profileDetails?.data;
      const profileNameArray = data?.name?.split(" ");
      const profileShortName = data?.name?.slice(0, 2);
      setShortName(profileShortName);
      setNameArray(profileNameArray);
    } else if (props.getSuperAdminProfileReducer.profileDetails.error) {
      const errMsg = props.getSuperAdminProfileReducer.errors;
      console.log(errMsg, "errMsg");
      if (errMsg == "UnAuthorized") {
        removeStorage();
        navigate("/superadmin/login");
      }
    }
  }, [props.getSuperAdminProfileReducer]);

  return (
    <>
      <header className="user-Header">
        <div className="d-flex align-items-center">
          <img
            crossOrigin="anonymous"
            src={"/images/logo.png"}
            className="img-fluid logo"
            alt="Logo"
          />
        </div>
        <div className="d-flex align-items-center">
          <button
            id="profile"
            className=" btn1 profile-Order pointer bg-white"
            onClick={() => setIsCreateOrder(true)}
            type="button"
          >
            {/* <i className="fa-solid fa-plus text-white"></i> */}
            <img
              crossOrigin="anonymous"
              src={"/images/plus.svg"}
              className="img-fluid"
              alt="Logo"
            />
          </button>
          <Dropdown className="profile-drp">
            <Dropdown.Toggle id="profile">
              <span className="profile-icon">
                {NameArray?.length > 1
                  ? `${NameArray?.[0]?.slice(0, 1)}${NameArray?.[1]?.slice(
                      0,
                      1
                    )}`
                  : ShortName}
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {/* <Dropdown.Item href="/profile" className="text-decoration-none">
                <i className="fa fa-user me-3 "></i>Profile
              </Dropdown.Item> */}
              {/* <Dropdown.Item
                href="#"
                onClick={() => navigate("/admin/profile")}
                className="text-decoration-none"
              >
                <i className="fa fa-child-reaching me-2 text-primary"></i> Change Profile
              </Dropdown.Item> */}
              <Dropdown.Item
                href="#"
                onClick={() => navigate("/superadmin/change-password")}
                className="text-decoration-none"
              >
                <i className="fa fa-key me-2"></i> Change Password
              </Dropdown.Item>
              <Dropdown.Item
                // href="/superadmin/login"
                onClick={() => {
                  // setIsDelete(true);
                  handleShow();
                }}
                className="text-decoration-none"
              >
                <i className="fa fa-right-from-bracket me-3"></i>Log out
              </Dropdown.Item>
              {/* <Dropdown.Item href="#/action-3"><i className="fa-solid fa-table-columns me-2 text-primary"></i>Set Column result</Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </header>
      <Sidebar />
      {/* <!-- Modal --> */}
      <Modal
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <div className="d-flex justify-content-center align-items-center my-3 py-3">
          <div className="text-center">
            <i
              className="fa fa-circle-xmark text-red"
              style={{ fontSize: "80px" }}
            ></i>
            <h4 className="text-center mt-3">
              Are you sure you want to log out?{" "}
            </h4>
            <h4 className="text-center mt-3"></h4>
            <div className="d-flex justify-content-center align-items-center mt-5">
              <button
                onClick={() => handleClose()}
                className="text-white bg-red border-0 fs-5 rounded py-2 px-5 me-4"
              >
                No
              </button>
              <button
                onClick={clearLocalStorage}
                className="text-white bg-red border-0 fs-5 rounded py-2 px-5"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        show={isCreateOrder}
        size="xl"
        onHide={() => {
          setIsCreateOrder(false);
          props.resetFindCustomer();
        }}
        dialogClassName="modal-dialog-scrollable"
        backdrop="static"
      >
        <CreateOrder setIsCreateOrder={setIsCreateOrder} />
      </Modal>
    </>
  );
};

const mapStateToProp = (state) => ({
  getSuperAdminProfileReducer: state.getSuperAdminProfileReducer,
  signinReducer: state.signinReducer.loginReducer,
});

const mapDispatchToProps = (dispatch) => ({
  LoginHandler: () => dispatch(LogInDataAction()),
  getSuperAdminProfileDetails: () => dispatch(getSuperAdminProfileDetails()),
  resetFindCustomer: () =>
    dispatch({ type: RESET_FIND_SUPERADMIN_CUSTOMER_BY_CONTACT }),
});
export default connect(mapStateToProp, mapDispatchToProps)(Header);

import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import classNames from "classnames";
import Toaster from "../../../Components/Toaster/Toaster";
import { toast } from "react-toastify";
import {
  contactValidation,
  countryCodeValidation,
  validEmailRegex,
  validPassword,
} from "../../Constants/Constants";
import { connect } from "react-redux";
import { getPrmById, updatePrm } from "../../Services/Actions/PrmAction";
import {
  Us_Pattern_Number,
  formatPhoneNumber,
} from "../../../Store/type/Constant";
import MaskedInput from "react-text-mask";
import { Loader } from "rsuite";

function UpdatePrm(props) {
  const notifyError = (msg) => toast.error(msg);
  const [btnLoading, setbtnLoading] = useState(false);

  const [state, setState] = useState({
    prmName: "",
    prmEmail: "",
    contactNo: "",
    countryCode: "91",
    prmPassword: "",
    confirmPassword: "",
    status: "",
  });
  const [error, setError] = useState({
    prmName: false,
    contactNo: false,
    countryCode: false,
    prmEmail: false,
    prmPassword: false,
    confirmPassword: false,
  });

  useEffect(() => {
    if (props.updatePrmReducer.success) {
      setbtnLoading(false);
    }
    if (props.updatePrmReducer.error) {
      setbtnLoading(false);
    }
  }, [props.updatePrmReducer]);

  // useeffect

  useEffect(() => {
    if (props.updatePrmId) {
      props.getPrmById(props.updatePrmId);
    }
  }, [props.updatePrmId]);

  useEffect(() => {
    if (props.getPrmByIdReducer.success) {
      const data = props.getPrmByIdReducer.data;
      setState((prevState) => ({
        ...prevState,
        prmName: data[0].name,
        prmEmail: data[0].email,
        contactNo: data[0]?.number ? formatPhoneNumber(data[0]?.number) : "N/A",
        countryCode:
          data[0].countrycode === undefined ? "" : data[0].countrycode,
        prmPassword: data[0].password,
        confirmPassword: data[0].password,
        status: data[0]?.status == 1 ? "active" : "Inactive",
      }));
    }
  }, [props.getPrmByIdReducer]);

  var res = state?.contactNo
    ?.replace(/\D+/g, "")
    .replace(/^(\d{3})(\d{3})(\d{4}).*/, "$1$2$3");

  const handleInput = (e) => {
    const { name, value } = e.target;

    if (name === "countryCode") {
      if (value.length <= 4) {
        setState((prevState) => ({
          ...prevState,
          countryCode: value === "+" ? parseInt("0") : parseInt(value),
        }));
      } else return;
    }
    if (name === "contactNo") {
      if (value.length <= 14) {
        setState((prevState) => ({
          ...prevState,
          [name]: value.replace(/^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/),
        }));
      }
    }
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    switch (name) {
      case "prmName":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            prmName: "Enter Prm name",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            prmName: false,
          }));
        }
        break;

      case "prmEmail":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            prmEmail: "Enter Email",
          }));
        } else if (!validEmailRegex.test(value)) {
          setError((prevState) => ({
            ...prevState,
            prmEmail: "Enter Valid Email",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            prmEmail: false,
          }));
        }
        break;

      case "contactNo":
        if (e.target.value.length == 0) {
          setError((prevState) => ({
            ...prevState,
            contactNo: "contactNo can not be empty",
          }));
        } else if (!Us_Pattern_Number.test(e.target.value)) {
          setError((prevState) => ({
            ...prevState,
            contactNo: "Enter valid contactNo",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            contactNo: false,
          }));
        }
        break;

      case "countryCode":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            countryCode: "Enter Country code",
          }));
        } else if (!countryCodeValidation.test(value)) {
          setError((prevState) => ({
            ...prevState,
            countryCode: "Enter country code",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            countryCode: false,
          }));
        }
        break;

      case "prmPassword":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            prmPassword: "Enter Agent password",
          }));
        } else if (!validPassword.test(value)) {
          setError((prevState) => ({
            ...prevState,
            prmPassword: "Enter Valid Email",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            prmPassword: false,
          }));
        }
        break;

      case "confirmPassword":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            confirmPassword: "Enter Confirm password",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            confirmPassword: false,
          }));
        }
        break;

      default:
        break;
    }
  };

  const handleCancel = () => {
    props.setIsUpdatePrm(false);
  };

  const handleUpdatePrm = () => {
    if (state.prmName === "") {
      setError((prevState) => ({
        ...prevState,
        prmName: "Enter Prm name",
      }));
    }
    if (state.contactNo == "") {
      setError((prevState) => ({
        ...prevState,
        contactNo: "contactNo can not be empty",
      }));
    } else if (!Us_Pattern_Number.test(state.contactNo)) {
      setError((prevState) => ({
        ...prevState,
        contactNo: "Enter valid contactNo",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        contactNo: false,
      }));
    }
    if (state.countryCode === "") {
      setError((prevState) => ({
        ...prevState,
        countryCode: "Enter Country code",
      }));
    }
    if (state.prmEmail === "") {
      setError((prevState) => ({
        ...prevState,
        prmEmail: "Enter Email",
      }));
    }
    if (state.prmPassword === "") {
      setError((prevState) => ({
        ...prevState,
        prmPassword: "Enter Agent password",
      }));
    }
    if (state.confirmPassword === "") {
      setError((prevState) => ({
        ...prevState,
        confirmPassword: "Enter Confirm password",
      }));
    }

    if (state.confirmPassword !== state.prmPassword) {
      notifyError("Password and Confirm Password noy match");
    } else {
      if (
        state.prmName !== "" &&
        state.prmEmail !== "" &&
        state.contactNo !== "" &&
        state.countryCode !== "" &&
        state.prmPassword !== "" &&
        state.confirmPassword !== "" &&
        !error.prmName &&
        !error.prmEmail &&
        !error.contactNo &&
        !error.countryCode &&
        !error.prmPassword &&
        !error.confirmPassword
      ) {
        const details = {
          name: state.prmName,
          email: state.prmEmail,
          password: state.prmPassword,
          number: res,
          countrycode: state.countryCode,
          status: state.status === "active" ? 1 : 0,
          location: localStorage.getItem("userid"),
          type: "2",
        };
        props.updatePrm({
          id: props.updatePrmId,
          data: details,
        });
        props.setIsUpdatePrm(false);
        setbtnLoading(true);
      }
    }
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Update PRM</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="row">
          <div className="">
            <div className="row">
              <Form.Group className="mt-4 col-6">
                <Form.Label style={{ fontWeight: "bold" }}>
                  PRM Name:
                </Form.Label>
                <Form.Control
                  name="prmName"
                  type="text"
                  placeholder="Enter Prm Name"
                  value={state.prmName}
                  onChange={handleInput}
                  className={classNames({
                    requireField: error.prmName,
                  })}
                />
              </Form.Group>

              <Form.Group className="mt-4 col-6">
                <Form.Label style={{ fontWeight: "bold" }}>
                  Email Address:
                </Form.Label>
                <Form.Control
                  name="prmEmail"
                  type="text"
                  // disabled
                  placeholder="Enter Email Address"
                  value={state.prmEmail}
                  onChange={handleInput}
                  className={classNames({
                    requireField: error.prmEmail,
                  })}
                />
              </Form.Group>
            </div>

            <Form.Group className="mt-4">
              <Form.Label style={{ fontWeight: "bold" }}>
                Contact No:
              </Form.Label>
              <div className="row">
                {/* <div className="col-2">
                <Form.Control
                  name="countryCode"
                  type="text"
                  placeholder="+91"
                  value={`+${state.countryCode}`}
                  onChange={handleInput}
                  className={classNames({
                    requireField: error.countryCode,
                  })}
                />
              </div> */}
                <div className="col-6 mb-4">
                  <MaskedInput
                    mask={[
                      "(",
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      ")",
                      " ",
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      "-",
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                    ]}
                    guide={false}
                    id="my-input-id number"
                    name="contactNo"
                    placeholder="Enter Contact No."
                    value={state.contactNo}
                    onChange={handleInput}
                    className={classNames("form-control mb-3", {
                      requireField: error.contactNo,
                    })}
                  />
                </div>
              </div>
            </Form.Group>

            <div className="row">
              {/* <Form.Group className="mt-4 col-6">
              <Form.Label style={{ fontWeight: "bold" }}>Password :</Form.Label>
              <Form.Control
                name="prmPassword"
                type="password"
                value={state.prmPassword}
                onChange={handleInput}
                className={classNames({
                  requireField: error.prmPassword,
                })}
              />
            </Form.Group> */}
              {/* <Form.Group className="mt-4 col-6">
              <Form.Label style={{ fontWeight: "bold" }}>
                Confirm Password :
              </Form.Label>
              <Form.Control
                name="confirmPassword"
                type="password"
                value={state.confirmPassword}
                onChange={handleInput}
                className={classNames({
                  requireField: error.confirmPassword,
                })}
              />
            </Form.Group> */}
              <div className="col-md-3 col-4">
                <Form.Label style={{ fontWeight: "bold" }}>Status:</Form.Label>
                <Form.Select
                  value={state?.status}
                  onChange={(e) => {
                    setState((prevState) => ({
                      ...prevState,
                      status: e.target.value,
                    }));
                  }}
                >
                  <option value="active">Active</option>
                  <option value="Inactive">Inactive</option>
                </Form.Select>
              </div>
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer className="mt-3">
        {/* <div className='py-2 px-4 border rounded pointer bg-secondary text-white mx-2' onClick={handleCancel}>Cancel</div> */}
        <div
          className="py-2 px-4 border rounded pointer text-white bg-red mx-2"
          onClick={handleUpdatePrm}
        >
          {btnLoading ? <Loader /> : "Submit"}
        </div>
      </Modal.Footer>

      <Toaster />
    </>
  );
}

const mapStateToProp = (state) => ({
  getPrmByIdReducer: state.PrmReducer.getPrmById,
  updatePrmReducer: state.PrmReducer.updatePrm,
});

const mapDispatchToProps = (dispatch) => ({
  getPrmById: (id) => dispatch(getPrmById(id)),
  updatePrm: (details) => dispatch(updatePrm(details)),
});

export default connect(mapStateToProp, mapDispatchToProps)(UpdatePrm);

import {
  redirect,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router";
import Login from "./superAdmin/Components/Auth/Login";
import Register from "./superAdmin/Components/Auth/Register";
import CreateLocation from "./superAdmin/Components/CreateLocation/CreateLocation";
import Dashboard from "./superAdmin/Components/dashboard/Dashboard";
import EditLocation from "./superAdmin/Components/EditLocation/EditLocation";
import Location from "./superAdmin/Components/Location/Location";
import CreateMenu from "./superAdmin/Components/Menu/CreateMenu";
// import EditMenu from "./Componant/Menu/EditMenu";
// import Menu from "./Componant/Menu/Menu";
// import CreatePrm from "./superAdmin/Components/PRM/Prm/CreatePrm";
import EditPrm from "./superAdmin/Components/PRM/Prm/EditPrm";
import Prm from "./superAdmin/Components/PRM/Prm/Prm";
import CreatePrm from "./superAdmin/Components/PRM/Prm/CreatePrm";
import EditMenu from "./superAdmin/Components/Menu/EditMenu";
import Menu from "./superAdmin/Components/Menu/Menu";
import { useEffect } from "react";
import AdminLogin from "./Admin/Pages/Auth/AdminLogin";
import AdminDashboard from "./Admin/Pages/Dashboard/AdminDashboard";
import { connect } from "react-redux";
import Menus from "./Admin/Pages/AdminMenus/Menus";
import Footer from "./superAdmin/Components/Userlayout/Footer";
import AdminHeader from "./Components/Header/AdminHeader";
import AdminDeliveryAgent from "./Admin/Pages/AdminDeliveryAgent/AdminDeliveryAgent";
import AdminPRM from "./Admin/Pages/AdminPRM/AdminPRM";
import AdminOrder from "./Admin/Pages/AdminOrder/AdminOrder";
import DeliveryAgent from "./superAdmin/Components/Delivery Agent/DeliveryAgent";
import Order from "./superAdmin/Components/Order/Order";
import EditDeliveryAgents from "./superAdmin/Components/Delivery Agent/EditDeliveryAgents";
import Customer from "./superAdmin/Components/Customer/Customer";
import CreateCustomer from "./superAdmin/Components/Customer/CreateCustomer";
import InsideCustomerData from "./superAdmin/Components/Customer/InsideCustomerData";
import EditCustomer from "./superAdmin/Components/Customer/EditCustomer";
import CreateOrder from "./superAdmin/Components/Order/CreateOrder";
import AdminForgotPassword from "./Admin/Pages/Auth/AdminForgotPassword";
import ChangePassword from "./Admin/Pages/ChangePassword/ChangePassword";
import AdminProfile from "./Admin/Pages/AdminProfile/AdminProfile";
import { getProfileDetails } from "./Admin/Services/Actions/AuthAction";
import { getSuperAdminProfileDetails } from "./Store/Action/CreateAgents/UserAction";
import Header from "./superAdmin/Components/Userlayout/Header";
import Chnagepassword from "./superAdmin/Components/Auth/Chnagepassword";
import ForgotPassword from "./superAdmin/Components/Auth/ForgotPassword";
import Reports from "./superAdmin/Components/Reports/Reports";
import Privecy from "./superAdmin/Components/Privecy";
import AdminReports from "./Admin/Pages/Report/AdminReports";
import AdminCustomer from "./Admin/Pages/Customer/AdminCustomer";

function App(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem("token");
  const userType = localStorage.getItem("usertype");
  

  useEffect(() => {
    if (
      token === null &&
      location.pathname !== "/superadmin/login" &&
      location.pathname !== "/admin/login" &&
      location.pathname !== "/admin/forgotpassword" &&
      location.pathname !== "/superadmin/register"
    ) {
      navigate("/superadmin/login");
    }
  }, []);

  useEffect(() => {
    if (userType === "2") {
      props.adminProfileDetails();
    }
  }, []);

  useEffect(() => {
    if (userType === "1") {
      props.superadminProfileDetails();
    }
  }, []);

  useEffect(() => {
    if (
      token !== null &&
      (location.pathname === "/" ||
        location.pathname === "/superadmin/login" ||
        location.pathname === "/superadmin/register" ||
        location.pathname === "/admin/login" ||
        location.pathname === "/admin/forgotpassword" ||
        location.pathname === "/admin" ||
        location.pathname.includes("/admin") ||
        location.pathname === "/superadmin") &&
      userType === "1"
    ) {
      navigate("/superadmin/dashboard");
    }
    if (
      token !== null &&
      (location.pathname === "/" ||
        location.pathname === "/superadmin/login" ||
        location.pathname === "/superadmin/register" ||
        location.pathname === "/admin/login" ||
        location.pathname === "/admin/forgotpassword" ||
        location.pathname === "/admin" ||
        location.pathname.includes("/superadmin") ||
        location.pathname === "/superadmin") &&
      userType === "2"
    ) {
      navigate("/admin/dashboard");
    }
  }, [location.pathname]);

  useEffect(() => {}, [location.pathname]);

  useEffect(() => {
    if (
      token === null &&
      (location.pathname !== "/" ||
        location.pathname !== "/superadmin/login" ||
        location.pathname !== "/superadmin/register" ||
        location.pathname !== "/admin/login" ||
        location.pathname !== "/admin/forgotpassword" ||
        location.pathname !== "/admin" ||
        location.pathname !== "/admin/" ||
        location.pathname !== "/privacy/" ||
        location.pathname !== "/superadmin") &&
      userType === "1"
    ) {
      navigate("/superadmin/login");
    }
    if (
      token === null &&
      (location.pathname !== "/" ||
        location.pathname !== "/superadmin/login" ||
        location.pathname !== "/superadmin/register" ||
        location.pathname !== "/admin/login" ||
        location.pathname !== "/admin/forgotpassword" ||
        location.pathname !== "/admin" ||
        location.pathname !== "/admin/" ||
        location.pathname !== "/privacy/" ||
        location.pathname !== "/superadmin") &&
      userType === "2"
    ) {
      navigate("/admin/login");
    } else if (
      !!token &&
      (location.pathname === "/superadmin/login" ||
        location.pathname === "/superadmin/register" ||
        location.pathname === "/superadmin/forgotPassword")
    ) {
      navigate("/superadmin/dashboard");
    }
  }, [location.pathname]);

  useEffect(() => {
    if (
      (token === null || token !== null) &&
      location.pathname === "/privacy"
    ) {
      navigate("/privacy");
    }
  }, [location.pathname]);

  return (
    <div className="App">
      {token === null ? (
        <>
          <Routes>
            <Route
              exact
              path="/"
              element={() => redirect("/superadmin/login")}
            />
            <Route
              exact
              path="/superadmin"
              element={() => redirect("/superadmin/login")}
            />
            <Route exact path="/superadmin/login" element={<Login />} />
            <Route exact path="/superadmin/register" element={<Register />} />
            <Route
              exact
              path="/admin"
              element={() => redirect("/admin/login")}
            />
            <Route exact path="/admin/login" element={<AdminLogin />} />
            <Route
              exact
              path="/admin/forgotpassword"
              element={<AdminForgotPassword />}
            />
            <Route
              exact
              path="/superadmin/forgot-password"
              element={<ForgotPassword />}
            />
            <Route exact path="/privacy" element={<Privecy />} />
          </Routes>
        </>
      ) : (
        <>
          {userType === "1" && (
            <>
              {/* <Header /> */}
              <Routes>
                <Route exact path="/privacy" element={<Privecy />} />
                {/* <Route exact path="/" element={<Login />} /> */}
                {/* <Route exact path="/superadmin/register" element={<Register />} /> */}
                <Route
                  exact
                  path="/superadmin/dashboard"
                  element={<Dashboard />}
                />
                <Route
                  exact
                  path="/superadmin/location"
                  element={<Location />}
                />
                <Route
                  exact
                  path="/superadmin/create-location"
                  element={<CreateLocation />}
                />
                <Route
                  exact
                  path="/superadmin/Edit-Location/:id"
                  element={<EditLocation />}
                />
                <Route exact path="/superadmin/prm" element={<Prm />} />
                <Route
                  exact
                  path="/superadmin/CreatePrm"
                  element={<CreatePrm />}
                />
                <Route
                  exact
                  path="/superadmin/Edit-Prm/:id"
                  element={<EditPrm />}
                />
                <Route exact path="/superadmin/menu" element={<Menu />} />
                <Route
                  exact
                  path="/superadmin/createmenu"
                  element={<CreateMenu />}
                />
                <Route exact path="/superadmin/reports" element={<Reports />} />
                <Route
                  exact
                  path="/superadmin/Edit-Menu/:id"
                  element={<EditMenu />}
                />
                <Route exact path="/superadmin/order" element={<Order />} />
                <Route
                  exact
                  path="/superadmin/createorder"
                  element={<CreateOrder />}
                />
                <Route
                  exact
                  path="/superadmin/deliveryAgent"
                  element={<DeliveryAgent />}
                />
                <Route
                  exact
                  path="/superadmin/Edit-deliveryAgent/:id"
                  element={<EditDeliveryAgents />}
                />
                {/* <Route exact path="/superadmin/customer/:id" element={<Customer />} /> */}
                <Route
                  exact
                  path="/superadmin/customer"
                  element={<Customer />}
                />
                <Route
                  exact
                  path="/superadmin/createcustomer"
                  element={<CreateCustomer />}
                />
                <Route
                  exact
                  path="/superadmin/insidecustomer/:id/:id1"
                  element={<InsideCustomerData />}
                />
                <Route
                  exact
                  path="/superadmin/editcustomer/:id"
                  element={<EditCustomer />}
                />
                <Route
                  exact
                  path="/superadmin/Change-password"
                  element={<Chnagepassword />}
                />
                {/* <Route exact path="/superadmin/additem" element={<Additem />} /> */}
                {/* <Route Exact path='*' element={ <Navigate to="/404" />} /> */}
              </Routes>
            </>
          )}
          {userType === "2" && (
            <>
              {location.pathname !== "/privacy" ? (
                <>
                  {" "}
                  <AdminHeader />
                  <div>
                    <main className="userMain">
                      <div className="content-block">
                        <div>
                          <Routes>
                            <Route
                              exact
                              path="/privacy"
                              element={<Privecy />}
                            />
                            <Route
                              path="/admin/login"
                              element={() => redirect("/admin/dashboard")}
                            />
                            <Route
                              path="/admin/forgotpassword"
                              element={() => redirect("/admin/dashboard")}
                            />
                            <Route
                              path="/admin"
                              element={() => redirect("/admin/dashboard")}
                            />
                            <Route
                              path="/admin/profile"
                              element={<AdminProfile />}
                            />
                            <Route
                              path="/admin/dashboard"
                              element={<AdminDashboard />}
                            />
                            <Route path="/admin/menus" element={<Menus />} />
                            <Route
                              path="/admin/delivery_agent"
                              element={<AdminDeliveryAgent />}
                            />
                            <Route
                              path="/admin/reports"
                              element={<AdminReports />}
                            />
                            <Route
                              path="/admin/customer"
                              element={<AdminCustomer />}
                            />
                            <Route path="/admin/prm" element={<AdminPRM />} />
                            <Route
                              path="/admin/order"
                              element={<AdminOrder />}
                            />
                            <Route
                              path="/admin/change-password"
                              element={<ChangePassword />}
                            />
                          </Routes>
                        </div>
                      </div>
                    </main>
                  </div>
                  <Footer />
                </>
              ) : (
                <>
                  <Routes>
                    <Route exact path="/privacy" element={<Privecy />} />
                  </Routes>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

// export default App;
const mapStateToProp = (state) => ({
  adminLoginReducer: state.AuthReducer.loginReducer,
});

const mapDispatchToProps = (dispatch) => ({
  adminProfileDetails: () => dispatch(getProfileDetails()),
  superadminProfileDetails: () => dispatch(getSuperAdminProfileDetails()),
  // resetLoginReducer: () => dispatch({ type: RESET_LOGIN }),
});

export default connect(mapStateToProp, mapDispatchToProps)(App);

import React from "react";
import { Form, Modal } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import classNames from "classnames";
import { toast } from "react-toastify";
import moment from "moment";
import { formatPhoneNumber } from "../../../Store/type/Constant";
import {
  getCoustomerById,
  getCoustomerOrder,
} from "../../Services/Actions/CustomerAction";
import {
  RESET_GET_ADMIN_CUSTOMER_DETAILS,
  RESET_GET_ADMIN_CUSTOMER_ORDER,
} from "../../Constants/Constants";

function AdminCustomerDetails(props) {
  const dispatch = useDispatch();
  const [customerData, setcustomerData] = useState();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [dateRange, setDateRange] = useState(["", ""]);
  const [startDatee, endDatee] = dateRange;
  const [error, seterror] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const [customerallData, setcustomerallData] = useState(null);
  const [customerorderdata, setcustomerorderdata] = useState(null);

  useEffect(() => {
    dispatch(
      getCoustomerById({
        id: props.SelectedCustomerDetailsId,
      })
    );
    dispatch(
      getCoustomerOrder({
        id: props.SelectedCustomerDetailsId,
      })
    );
  }, []);

  const coustomerDetailReducer = useSelector(
    (state) => state.CustomerReducer.getCoustomerById
  );
  const coustomerOrderReducer = useSelector(
    (state) => state.CustomerReducer.getCoustomerOrder
  );

  useEffect(() => {
    if (coustomerDetailReducer.success) {
      const data = coustomerDetailReducer.data;
      setcustomerallData(data[0]);
      dispatch({ type: RESET_GET_ADMIN_CUSTOMER_DETAILS });
    }
  }, [coustomerDetailReducer]);
  useEffect(() => {
    if (coustomerOrderReducer.success) {
      const data = coustomerOrderReducer.data;
      setcustomerorderdata(data);
      dispatch({ type: RESET_GET_ADMIN_CUSTOMER_ORDER });
    }
  }, [coustomerOrderReducer]);

  useEffect(() => {
    if (endDate !== "" && endDate !== null) {
      dispatch(
        getCoustomerOrder({
          id: props.SelectedCustomerDetailsId,
          detail: {
            from_date: moment(startDate).format("MM-DD-yyyy"),
            to_date: moment(endDate).format("MM-DD-yyyy"),
          },
        })
      );
    }
  }, [endDate]);

  useEffect(() => {
    if (
      startDate !== "" &&
      startDate !== null &&
      (endDate === "" || endDate === null) &&
      !isOpen
    ) {
      toast.error("Please select the next date in the picker.");
    }
  }, [isOpen]);

  const numberConvert = (number) => {
    // Convert the random number to a string.
    const numberString = String(number);

    // Split the string into two parts at the decimal point.
    let [integerPart, decimalPart] = numberString.split(".");

    // If the decimal part is empty, set it to '00'.
    // debugger;
    if (!decimalPart) {
      decimalPart = "00";
    }

    // If the decimal part has more than two digits, trim it to two digits.
    if (decimalPart.length > 2) {
      decimalPart = decimalPart.substring(0, 2);
    }

    // Return the formatted number.
    return `${integerPart}.${decimalPart}`;
  };


  return (
    <div
      style={{
        maxHeight: "880px",
        overflowY: "auto",
      }}
    >
      <Modal.Header closeButton>
        <h4 className="me-2">Customer Details</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="row mb-3">
          <div className="col-12 mt-3">
            <div className="address-container ">
              <div className="col-12 border-bottom mb-3">
                <div className="row gx-2 w-100">
                  <div className="col-7" style={{ fontWeight: "bold" }}>
                    <Form.Group className="">
                      <Form.Label
                        className="mb-1"
                        style={{ marginBottom: "-20px" }}
                      >
                        From / To Date:
                      </Form.Label>
                      <ReactDatePicker
                        onInputClick={() => setIsOpen(true)}
                        onClickOutside={() => setIsOpen(false)}
                        showYearDropdown
                        selectsRange={true}
                        startDate={startDatee}
                        endDate={endDatee}
                        placeholderText="MM-DD-YYYY"
                        dateFormat={"MM-dd-yyyy"}
                        className={classNames("form-control", {
                          requireField: error,
                        })}
                        onChange={(date) => {
                          console.log("date", date);
                          if (!!date) {
                            setDateRange(date);
                            setStartDate(date[0]);
                            setEndDate(date[1]);
                          } else {
                            // setOrderList([...allorder]);
                          }
                          if (date[1]) {
                            setIsOpen(false);
                          }
                        }}
                        // isClearable={true}
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="address-container ">
          <div className="col-12 border-bottom mb-3">
            <div className="d-flex align-items-end  w-100">
              <div className="row gx-2 w-100">
                <div className="d-flex align-items-end  w-100">
                  <div className="row gx-2 w-100">
                    <div className="col-6 mb-1" style={{ fontWeight: "bold" }}>
                      Customer Name:
                    </div>
                    <div className="col-6 mb-1" style={{ fontWeight: "bold" }}>
                      DOB:
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-end  w-100">
                  <div className="row gx-2 w-100">
                    <div
                      className="col-6 text-gray"
                      style={{ fontWeight: "bold" }}
                    >
                      {customerallData?.name}
                    </div>
                    <div
                      className="col-6 text-gray"
                      style={{ fontWeight: "bold" }}
                    >
                      {moment(customerallData?.dob)?.format("MMMM Do, YYYY")}
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-end mt-3 w-100">
                  <div className="row gx-2 w-100">
                    <div className="col-6 mb-1" style={{ fontWeight: "bold" }}>
                      Contact No:
                    </div>
                    <div className="col-6 mb-1" style={{ fontWeight: "bold" }}>
                      Email Address:
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-end w-100">
                  <div className="row gx-2 w-100">
                    <div
                      className="col-6 text-gray"
                      style={{ fontWeight: "bold" }}
                    >
                      {formatPhoneNumber(customerallData?.number)}
                    </div>
                    <div
                      className="col-6 text-gray"
                      style={{ fontWeight: "bold" }}
                    >
                      {customerallData?.email}
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-end mt-3 w-100">
                  <div className="row gx-2 w-100">
                    <div className="col-6 mb-1" style={{ fontWeight: "bold" }}>
                      Note:
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-end w-100">
                  <div className="row gx-2 w-100">
                    <div
                      className="col-12 text-gray"
                      style={{ fontWeight: "bold" }}
                    >
                      {customerallData?.note}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mt-3">
            <div className="address-container ">
              <div style={{ fontWeight: "bold" }} className="mb-2">
                Address:
              </div>
              <div className="col-12 border-bottom mb-3">
                <div>
                  <div className="text-dark" style={{ fontWeight: "bold" }}>
                    {customerallData?.address[0]?.name}
                  </div>
                  <div>{customerallData?.address[0]?.address1},</div>
                  <div>{customerallData?.address[0]?.address2},</div>
                  <div>
                    {customerallData?.address[0]?.city},
                    {" " + customerallData?.address[0]?.state + " "}
                    {customerallData?.address[0]?.pincode}
                  </div>
                </div>
                <div className="d-flex align-items-center  w-100 mt-2">
                  <div className="row gx-2 w-100">
                    <div className="col-4" style={{ fontWeight: "bold" }}>
                      City:
                    </div>
                    <div className="col-4" style={{ fontWeight: "bold" }}>
                      State:
                    </div>
                    <div className="col-4" style={{ fontWeight: "bold" }}>
                      Zip Code:
                    </div>
                  </div>
                </div>
                <div className="row gx-2 w-100 mt-2">
                  <div className="col-4" style={{ fontWeight: "bold" }}>
                    <div className="text-gray">
                      {customerallData?.address[0]?.city}
                    </div>
                  </div>
                  <div className="col-4" style={{ fontWeight: "bold" }}>
                    <div className="text-gray">
                      {customerallData?.address[0]?.state}
                    </div>
                  </div>
                  <div className="col-4" style={{ fontWeight: "bold" }}>
                    <div className="text-gray">
                      {customerallData?.address[0]?.pincode}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 mt-3">
            <div className="address-container ">
              <div className="col-12 border-bottom mb-3">
                <div className="d-flex align-items-end  w-100">
                  <div className="row gx-2 w-100">
                    <div className="col-4 mb-1" style={{ fontWeight: "bold" }}>
                      Total Order:
                    </div>
                    <div className="col-4 mb-1" style={{ fontWeight: "bold" }}>
                      Total Spent:
                    </div>
                    <div className="col-4 mb-1" style={{ fontWeight: "bold" }}>
                      Average Order Value:
                    </div>
                  </div>
                </div>
                <div className="row gx-2 w-100">
                  <div
                    className="col-4 text-gray"
                    style={{ fontWeight: "bold" }}
                  >
                    {!!customerorderdata ? customerorderdata?.Totalorder : 0}
                  </div>
                  <div
                    className="col-4 text-gray"
                    style={{ fontWeight: "bold" }}
                  >
                    $
                    {!!customerorderdata
                      ? parseFloat(numberConvert(customerallData?.totalspent)).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                      : 0}
                  </div>
                  <div
                    className="col-4 text-gray"
                    style={{ fontWeight: "bold" }}
                  >
                    $
                    {!!customerorderdata &&
                    customerallData?.totalspent !== 0 &&
                    parseFloat(customerallData?.totalspent).toFixed(2)
                      ? parseFloat(numberConvert(
                        customerallData?.totalspent /
                          customerallData?.totalorder
                      )).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                      : 0}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {customerallData?.order_data.length !== 0 && (
          <div className="row">
            <div className="col-12 mt-3">
              <div className="address-container ">
                <div className="d-flex border-bottom">
                  <div
                    style={{ fontWeight: "bold", width: "50%" }}
                    className="mb-2"
                  >
                    Order Date
                  </div>
                  <div
                    style={{ fontWeight: "bold", width: "50%" }}
                    className="mb-2"
                  >
                    Order Amount
                  </div>
                </div>
                <div className="mt-2">
                  {customerallData?.order_data
                    .sort((a, b) => moment(b.date) - moment(a.date))
                    .map((e, i) => {
                      return (
                        <div className="d-flex" key={i}>
                          <div
                            style={{ fontWeight: "bold", width: "50%" }}
                            className="mb-2 text-gray"
                          >
                            {moment(e.date).format("MM-DD-YYYY")}
                          </div>
                          <div
                            style={{ fontWeight: "bold", width: "50%" }}
                            className="mb-2 text-gray"
                          >
                            {`$${e.amount.toLocaleString("en-US")}`}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
    </div>
  );
}

const mapStateToProp = (state) => ({
  // getCustomerByIdReducer: state.getCustomerByIdReducer.getCustomerById,
  // customerordercountReducer: state.customerordercountReducer.customerordercount,
});

const mapDispatchToProps = (dispatch) => ({
  // getViewCustomerById: (id) => dispatch(getViewCustomerById(id)),
  // customerordercountAction: (id) => dispatch(customerordercountAction(id)),
  // resetcustomerordercount: () =>
  //   dispatch({ type: RESET_CUSTOMER_TOTAL_ORDER_COUNT }),
});
export default connect(
  mapStateToProp,
  mapDispatchToProps
)(AdminCustomerDetails);

import { Pagination, TablePagination } from "@mui/material";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Form, Modal, Table } from "react-bootstrap";
import DataTable from "react-data-table-component";
import ReactDatePicker from "react-datepicker";
import { connect, useDispatch, useSelector } from "react-redux";
import Spinner from "../../../Components/Spinner/Spinner";
import {
  RESET_ADD_ORDER,
  RESET_ADMIN_EXPORT_PDF,
  RESET_DELETE_ORDER,
  RESET_FIND_CUSTOMER_BY_CONTACT,
  RESET_GET_ORDER,
  RESET_GET_ORDER_BY_ID,
  RESET_UPDATE_ORDER,
} from "../../Constants/Constants";
import {
  deleteOrder,
  exportadminPdf,
  getAdminDeliveryAgentsDataAction,
  getAdminOrderFilterAction,
  getAdminagentwiseorderAction,
  getAllOrder,
} from "../../Services/Actions/OrderAction";
import CreateOrder from "./CreateOrder";
import OrderDetails from "./OrderDetails";
import DeletePopUp from "../../../superAdmin/Components/DeletePopup";
import { toast } from "react-toastify";
import Toaster from "../../../Components/Toaster/Toaster";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { formatPhoneNumber } from "../../../Store/type/Constant";
import EditOrderAdmin from "./EditOrderAdmin";
import { debounce } from "lodash";

const FilterComponent = ({ filterText, onFilter }) => (
  <>
    <div className="form-outline order-search">
      <label
        className="form-label me-2 mb-0"
        style={{ marginTop: "25px" }}
        htmlFor="form1"
      >
        {/* Search : */}
      </label>
      <input
        id="search"
        type="text"
        className="form-control"
        placeholder="Search"
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
        style={{ marginBottom: "30px" }}
      />
    </div>
  </>
);

function AdminOrder(props) {
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(false);
  const [OrderEdit, setOrderEdit] = useState(false);
  const [totalrows, setTotalRows] = useState(100);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [SelectedEditID, setSelectedEditID] = useState();
  const successMsg = (msg) => toast.success(msg);
  const notifyError = (msg) => toast.error(msg);
  const [pdfloader, setpdfloader] = useState(false);

  const orderData = useSelector((state) => state.OrderReducer.getOrder).data;

  const [orderStatus, setOrderStatus] = useState("All");

  const [isCreateOrder, setIsCreateOrder] = useState(false);
  const [orderList, setOrderList] = useState([]);

  const [deleteOrderId, setDeleteOrderId] = useState(null);
  const [isDeleteOrder, setIsDeleteOrder] = useState(false);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [IsOrderDetails, setIsOrderDetails] = useState(false);
  const [orderDetailId, setOrderDetailId] = useState(null);

  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filterText, setFilterText] = useState("");

  const [dateRange, setDateRange] = useState(["", ""]);
  const [startDatee, endDatee] = dateRange;

  const [deleteId, setDeleteId] = useState(null);
  const [isDelete, setIsDelete] = useState(false);
  const [error, seterror] = useState(false);
  const [agent, setagent] = useState("");

  const AgentByorder = useSelector(
    (state) => state.OrderReducer.adminagentwiseorder?.data
  );

  const allDeliveryAgents = props.adminagentOrder.data;

  useEffect(() => {
    if (props.profileDetails?.success) {
      const ID = props.profileDetails?.data?.location;
      props.getAdminDeliveryAgentsDataAction({ id: ID });
    }
  }, [props.profileDetails]);

  const dispatch = useDispatch();

  // useEffect(() => {
  //   props.getAllOrder();
  // }, []);

  useEffect(() => {
    if (props.exportadminpdfReducer?.success) {
      setpdfloader(false);
    }
  }, [props.exportadminpdfReducer]);

  const handleDeleteOrder = () => {
    props.deleteOrder(deleteOrderId);
  };

  useEffect(() => {
    if (props.DeleteOrderReducer.success) {
      props.getAdminOrderFilterAction({
        from_date: startDate ? moment(startDate).format("yyyy-MM-DD") : "",
        to_date: endDate ? moment(endDate).format("yyyy-MM-DD") : "",
        agent_id: agent,
        isDelivered:
          orderStatus === "All" ? "" : orderStatus === "Ordered" ? "0" : "1",
      });
      toast.success("Order deleted successfully. ");
      props.resetDeleteOrder();
      setLoading(true);
    } else if (props.DeleteOrderReducer.error) {
      notifyError(props.DeleteOrderReducer.errors);
      props.resetDeleteOrder();
    }
  }, [props.DeleteOrderReducer]);

  useEffect(() => {
    if (props.createOrderReducer.success) {
      props.getAdminOrderFilterAction({
        from_date: startDate ? moment(startDate).format("yyyy-MM-DD") : "",
        to_date: endDate ? moment(endDate).format("yyyy-MM-DD") : "",
        agent_id: agent,
        isDelivered:
          orderStatus === "All" ? "" : orderStatus === "Ordered" ? "0" : "1",
      });
      setLoading(true);
      setTimeout(() => {
        props.resetCreateOrder();
      }, 100);
    }
  }, [props.createOrderReducer]);

  useEffect(() => {
    const Query = {
      from_date: startDate ? moment(startDate).format("yyyy-MM-DD") : "",
      to_date: endDate ? moment(endDate).format("yyyy-MM-DD") : "",
      agent_id: agent,
      isDelivered:
        orderStatus === "All" ? "" : orderStatus === "Ordered" ? "0" : "1",
      currentPage,
      limit,
    };
    if (filterText && filterText?.trim()?.length) {
      Query.filterText = filterText;
    }
    props.getAdminOrderFilterAction(Query);
    setLoading(true);
  }, [endDate, orderStatus, agent, currentPage, limit,filterText]);

  useEffect(() => {
    // setLoading(props.orderfilterReducer.loading);
    if (props.orderfilterAdminReducer.success) {
      const data = props.orderfilterAdminReducer?.data;

      setTotalRows(props.orderfilterAdminReducer?.fullData?.totalItems);
      setOrderList(data);
      setLoading(false);
    } else if (props.orderfilterAdminReducer.error) {
      setLoading(false);
    }
  }, [props.orderfilterAdminReducer]);

  useEffect(() => {
    if (endDate !== null && endDate !== "") {
      setLoading2(true);
      setTimeout(() => {
        setLoading2(false);
      }, 200);
    }
  }, [endDate]);

  const columns = [
    {
      name: "Order ID",
      selector: (row) => row.order_name,
      sortable: true,
    },
    {
      name: "Customer Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Contact No",
      selector: (row) => row.number,
      sortable: true,
    },
    {
      name: "Delivery Date / Time",
      selector: (row) => row.deliverytime,
      sortable: true,
    },

    {
      name: "Order Status",
      selector: (row) => row.orderstatus,
      sortable: true,
    },
    {
      name: "ezCater / OLO",
      selector: (row) => row.ezCater,
      sortable: true,
      // width:"240px"
    },
    {
      name: "Action",
      selector: (row) => row.action,
      width: "160px",
    },
  ];

  const data = orderList?.map((data, i) => {
    return {
      id: data._id,
      order_name: data?.order_name ? (data?.order_name).toUpperCase() : "N/A",
      name: data?.address?.[0]?.name ? data?.address?.[0]?.name : "N/A",
      orderstatus: data?.isDelivered === 0 ? "Ordered" : "Delivered",
      // customerlocation: data?.locationdata[0]?.location_name,
      number: data?.customers?.[0]?.number
        ? formatPhoneNumber(data?.customers[0]?.number)
        : "N/A",
      // location_name: data?.locationdata[0]?.location_name,
      deliverytime:
        !!data.deliveryDateUnform &&
        `${moment(data.deliveryDateUnform, "MM-DD-yyyy").format(
          "MMMM Do, YYYY"
        )}${"/"}${data?.deliverytimestring}`,
      // deliverytime: !!data.deliveryDateUnform && `${moment(data.deliveryDateUnform, 'MM-DD-yyyy').format(
      //   "MMMM Do, YYYY"
      // )}${"/"}${moment(data.createdAt).format("h:mm a")}`,
      ezCater:
        data?.ezCater == 1 ? (
          <span style={{ width: "60px" }}>ezCater</span>
        ) : data?.ezCater == 2 ? (
          <span style={{ width: "60px" }}>OLO</span>
        ) : (
          <span style={{ width: "60px" }}>N/A</span>
        ),
      action: (
        <>
          {data?.isDelivered === 0 && (
            <button
              className="btn p-0 me-2"
              onClick={() => {
                setOrderEdit(true);
                setSelectedEditID(data?._id);
              }}
            >
              <i
                className="fa fa-pencil-square-o pointer fs-4"
                aria-hidden="true"
              ></i>
            </button>
          )}
          <button
            className="btn p-0"
            onClick={() => {
              props.exportadminPdf(data?._id);
              setpdfloader(true);
            }}
          >
            <i
              className="fa-regular fa-file-pdf fs-4 me-2 pointer"
              aria-hidden="true"
            ></i>
          </button>
          <button
            className="btn p-0"
            onClick={() => {
              setIsOrderDetails(true);
              setOrderDetailId(data?._id);
            }}
          >
            <i className="fa fa-eye fs-4 mt-1 pointer" aria-hidden="true"></i>
          </button>
          <button
            className="btn p-0"
            onClick={() => {
              setIsDelete(true);
              setDeleteId(data?._id);
            }}
          >
            <i
              className="fa fa-trash text-danger fs-4 ms-2"
              aria-hidden="true"
            ></i>
          </button>
        </>
      ),
    };
  });

  const filteredItems = data?.filter(
    (item) =>
      (item.order_name &&
        item.order_name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.name &&
        item.name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.email &&
        item.email.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.order_name &&
        item.order_name.toLowerCase().includes(filterText.toLowerCase()))
  );

 

  // useEffect(() => {
  //   setOrderStatus("All");
  // }, [startDate]);

  const [isOpen, setIsOpen] = useState(true);

  // useEffect(() => {
  //   if (
  //     startDate !== "" &&
  //     startDate !== null &&
  //     (endDate === "" || endDate === null) &&
  //     !isOpen
  //   ) {
  //     toast.error("Please select the next date in the picker.");
  //   }
  // }, [isOpen]);

  // useEffect(() => {
  //   setOrderStatus("");
  //   setDateRange(["", ""]);
  // }, [AgentByorder]);

  useEffect(() => {
    if (props.editOrderReducer.success) {
      props.getAdminOrderFilterAction({
        from_date: startDate ? moment(startDate).format("yyyy-MM-DD") : "",
        to_date: endDate ? moment(endDate).format("yyyy-MM-DD") : "",
        agent_id: agent,
        isDelivered:
          orderStatus === "All" ? "" : orderStatus === "Ordered" ? "0" : "1",
      });
      setLoading(true);
      props.resetUpdateOrder();
    }
  }, [props.editOrderReducer]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    setCurrentPage(page);
    setLimit(newPerPage);
  };
  const debouncedSetFilterText = useCallback(
    debounce((value) => {
      setFilterText(value);
    }, 300),
    [] // Ensure the debounce function is created only once
  );
  return (
    <React.Fragment>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <div className="d-flex justify-content-center align-items-center">
          <Link to={"/"} className="text-dark">
            <i className="fa fa-house fs-2 me-3"></i>
          </Link>
          <h3 className="me-3">/</h3>
          <h3 className="fw-semibold">Orders</h3>
        </div>
        <div
          className="border rounded pointer px-4 py-2 text-white bg-red"
          onClick={() => setIsCreateOrder(true)}
        >
          Create
        </div>
      </div>
      <div className="mt-4">
        {/* date */}
        <div>
          <div className="row">
            {/* <div className="col-12 col-md-4"> */}
            <Form.Group className="col col-lg-3 col-md-4">
              <Form.Label style={{ fontWeight: "bold" }}>
                From / To Date:
              </Form.Label>
              <ReactDatePicker
                showYearDropdown
                onInputClick={() => setIsOpen(true)}
                onClickOutside={() => setIsOpen(false)}
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                placeholderText="MM-DD-YYYY"
                dateFormat={"MM-dd-yyyy"}
                onChange={(date) => {
                  if (!!date) {
                    setDateRange(date);
                    setStartDate(date[0]);
                    setEndDate(date[1]);
                  } else {
                    setOrderList([...orderData]);
                  }
                  if (date[1]) {
                    setIsOpen(false);
                  }
                }}
                className={classNames("form-control", {
                  requireField: error,
                })}

                // isClearable={true}
              />
            </Form.Group>
            {/* </div> */}

            <div className="col-12 col-lg-3 col-md-4">
              <Form.Group>
                <Form.Label style={{ fontWeight: "bold" }}>Status:</Form.Label>
                <Form.Select
                  name="delivery_agent"
                  value={orderStatus}
                  onChange={(e) => setOrderStatus(e.target.value)}
                >
                  <option value={"All"}>All</option>
                  <option value={"Ordered"}>Ordered</option>
                  <option value={"Delivered"}>Delivered</option>
                </Form.Select>
              </Form.Group>
            </div>
            <div className="form-group event-drp col col-lg-2 col-md-3 mb-1">
              <Form.Label>Drivers:</Form.Label>
              <select
                id="agents"
                name="agents"
                value={agent}
                onChange={(e) => setagent(e.target.value)}
                className="form-select"
              >
                <option value="" className="">
                  {`${"All Agents (Orders)"}`}
                </option>
                {allDeliveryAgents?.map((v, i) => {
                  return (
                    <option value={v?._id} key={i}>
                      {v.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
        <div
            className="col col-lg-2 col-md-3"
              // className="row gx-2"
              style={{
                // display: "flex",
                // justifyContent: "end",
                // alignItems: "center",
              }}
            >
              <FilterComponent
                onFilter={(e) => {
                  debouncedSetFilterText(e.target.value);
                }}
                onClear={() => {
                  setFilterText("");
                }}
                // filterText={filterText}
              />
            </div>
      </div>
      {loading ? (
        <div className="mt-5">
          <Spinner />
        </div>
      ) : (
        <>
          {orderList.length === 0 ? (
            orderStatus == "Ordered" || orderStatus == "Delivered" ? (
              <div className="text-center h5 mt-5 text-red">
                Sorry! There are no orders under the selected status.
              </div>
            ) : AgentByorder?.length === 0 ? (
              <div className="text-center h5 mt-5 text-red">
                Sorry! There are no orders under the selected agent.
              </div>
            ) : (
              <div className="text-center h5 mt-5 text-red">
                There are no orders in the selected date range.
              </div>
            )
          ) : loading2 ? (
            <>
              <div className="mt-5">
                <Spinner />
              </div>
            </>
          ) : (
            <>
              <>
                {/* <DataTable
                  columns={columns}
                  data={filteredItems}
                  pagination
                  paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                /> */}
              </>
            </>
          )}
        </>
      )}
      <DataTable
        columns={columns}
        data={filteredItems}
        pagination
        paginationServer
        progressPending={loading}
        // paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
        subHeader
        paginationTotalRows={totalrows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        // subHeaderComponent={subHeaderComponentMemo}
      />
      <Toaster />

      {pdfloader && (
        <div
          className=" text-center mt-3"
          style={{
            position: "absolute",
            top: "0px",
            bottom: "0px",
            right: "0px",
            left: "0px",
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          }}
        >
          <div className="d-flex justify-content-center align-items-center h-100 w-100">
            <div className="mt-3" style={{ opacity: 1 }}>
              <Spinner />
            </div>
          </div>
        </div>
      )}

      <Modal
        show={isCreateOrder}
        size="xl"
        onHide={() => {
          setIsCreateOrder(false);
          props.resetFindCustomer();
        }}
        dialogClassName="modal-dialog-scrollable"
        backdrop="static"
      >
        <CreateOrder setIsCreateOrder={setIsCreateOrder} />
      </Modal>

      {orderDetailId && (
        <Modal
          show={IsOrderDetails}
          size="md"
          backdrop="static"
          onHide={() => setIsOrderDetails(false)}
          dialogClassName="modal-dialog-scrollable"
          // style={{width:"100%",overflow:"auto"}}
        >
          <OrderDetails
            setIsOrderDetails={setIsOrderDetails}
            orderDetailId={orderDetailId}
          />
        </Modal>
      )}

      <Modal
        show={isDeleteOrder}
        size="lg"
        onHide={() => setIsDeleteOrder(false)}
        dialogClassName="modal-dialog-scrollable"
      >
        <div className="p-4">
          <div className="">
            <div className="text-center my-5">
              <h5>Are you sure you want to delete this order?</h5>
            </div>
            <Modal.Footer className="mt-3">
              <div
                className="py-2 px-4 border rounded pointer bg-secondary text-white mx-2"
                onClick={() => {
                  setIsDeleteOrder(false);
                  setDeleteOrderId(null);
                }}
              >
                Cancel
              </div>
              <div
                className="py-2 px-4 border rounded pointer text-white bg-red mx-2"
                onClick={handleDeleteOrder}
              >
                Delete Order
              </div>
            </Modal.Footer>
          </div>
        </div>
      </Modal>
      <DeletePopUp
        isOpen={isDelete}
        isClose={setIsDelete}
        handleEvent={() => dispatch(deleteOrder(deleteId))}
        confirmMsg="Are you sure about deleting this Order?"
      />
      <Modal
        show={OrderEdit}
        onHide={() => setOrderEdit(false)}
        size="xl"
        dialogClassName="modal-dialog-scrollable"
        backdrop="static"
      >
        <EditOrderAdmin
          SelectedEditID={SelectedEditID}
          setOrderEdit={setOrderEdit}
        />
      </Modal>
    </React.Fragment>
  );
}

const mapStateToProp = (state) => ({
  GetorderReducer: state.OrderReducer.getOrder,
  orderfilterAdminReducer: state.OrderReducer.orderfilterAdmin,
  DeleteOrderReducer: state.OrderReducer.deleteOrder,
  createOrderReducer: state.OrderReducer.addOrder,
  profileDetails: state.AuthReducer.profileDetails,
  adminagentOrder: state.OrderReducer.getadminagents,
  agentwiseorderreducer: state.OrderReducer.adminagentwiseorder,
  exportadminpdfReducer: state.exportadminpdfReducer.exportadminpdf,
  editOrderReducer: state.OrderReducer.updateOrder,
});

const mapDispatchToProps = (dispatch) => ({
  getAllOrder: (details) => dispatch(getAllOrder(details)),
  exportadminPdf: (details) => dispatch(exportadminPdf(details)),
  getAdminOrderFilterAction: (Details) =>
    dispatch(getAdminOrderFilterAction(Details)),
  getAdminagentwiseorderAction: (id) =>
    dispatch(getAdminagentwiseorderAction(id)),
  deleteOrder: (id) => dispatch(deleteOrder(id)),
  getAdminDeliveryAgentsDataAction: (id) =>
    dispatch(getAdminDeliveryAgentsDataAction(id)),
  resetDeleteOrder: () => dispatch({ type: RESET_DELETE_ORDER }),
  resetCreateOrder: () => dispatch({ type: RESET_ADD_ORDER }),
  resetFindCustomer: () => dispatch({ type: RESET_FIND_CUSTOMER_BY_CONTACT }),
  resetOrder: () => dispatch({ type: RESET_GET_ORDER_BY_ID }),
  resetExportadminPdf: () => dispatch({ type: RESET_ADMIN_EXPORT_PDF }),
  resetUpdateOrder: () => dispatch({ type: RESET_UPDATE_ORDER }),
});

export default connect(mapStateToProp, mapDispatchToProps)(AdminOrder);

import axios from "axios";
import {
  adminOrderFilter_Url,
  admin_contact_search,
  admin_customer_search,
  adminagentwiseOrderUrl,
  createOrder_Url,
  deleteOrder_Url,
  exportadminpdfUrl,
  getAdminswapagent_Url,
  getAllOrder_Url,
  getOrderDetails_Url,
  getadmindeliveryagentUrl,
  updateadminorderUrl,
} from "../../apiSheet";
import {
  ADD_ORDER_ERROR,
  ADD_ORDER_LOADING,
  ADD_ORDER_SUCCESS,
  ADMIN_AGENT_WISE_ORDER_ERROR,
  ADMIN_AGENT_WISE_ORDER_LOADING,
  ADMIN_AGENT_WISE_ORDER_SUCCESS,
  ADMIN_CUSTOMER_SEARCH_LOADING,
  ADMIN_CUSTOMER_SEARCH_SUCCESS,
  ADMIN_EXPORT_PDF_ERROR,
  ADMIN_EXPORT_PDF_SUCCESS,
  ADMIN_ORDER_FILTER_ERROR,
  ADMIN_ORDER_FILTER_LOADING,
  ADMIN_ORDER_FILTER_SUCCESS,
  CUSTOMER_CONTACT_SEARCH_ADMIN_LOADING,
  CUSTOMER_CONTACT_SEARCH_ADMIN_SUCCESS,
  DELETE_ORDER_ERROR,
  DELETE_ORDER_LOADING,
  DELETE_ORDER_SUCCESS,
  GET_ADMIN_DELIVERY_AGENTS_DATA_ERROR,
  GET_ADMIN_DELIVERY_AGENTS_DATA_LOADING,
  GET_ADMIN_DELIVERY_AGENTS_DATA_SUCCESS,
  GET_ORDER_BY_ID_ERROR,
  GET_ORDER_BY_ID_LOADING,
  GET_ORDER_BY_ID_SUCCESS,
  GET_ORDER_ERROR,
  GET_ORDER_LOADING,
  GET_ORDER_SUCCESS,
  UPDATE_ORDER_ERROR,
  UPDATE_ORDER_LOADING,
  UPDATE_ORDER_SUCCESS,
} from "../../Constants/Constants";
import moment from "moment";
import {
  GET_SWAP_AGENT_ADMIN_ERROR,
  GET_SWAP_AGENT_ADMIN_LOADING,
  GET_SWAP_AGENT_ADMIN_SUCCESS,
  removeStorage,
} from "../../../Store/type/Constant";

export const getAllOrder = () => {
  return (dispatch) => {
    dispatch({
      type: GET_ORDER_LOADING,
    });
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${getAllOrder_Url}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_ORDER_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message,
            pagination: {
              page: resp.data.page,
              limit: resp.data.limit,
              totalpage: resp.data.totalpage,
            },
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_ORDER_ERROR,
            netError: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: GET_ORDER_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const creaetOrder = (details) => {
  return (dispatch) => {
    dispatch({
      type: ADD_ORDER_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${createOrder_Url}`,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: ADD_ORDER_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: ADD_ORDER_ERROR,
            netError: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: ADD_ORDER_ERROR,
            errors: validationError,
          });
        } else if (data.status === 401) {
          window.location.reload();
        }
      });
  };
};

export const deleteOrder = (id) => {
  return (dispatch) => {
    dispatch({
      type: DELETE_ORDER_LOADING,
    });
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${deleteOrder_Url}/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: DELETE_ORDER_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: DELETE_ORDER_ERROR,
            netError: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: DELETE_ORDER_ERROR,
            errors: validationError,
          });
        } else if (data.status === 401) {
          window.location.reload();
        }
      });
  };
};

export const getOrderDetails = (id) => {
  return (dispatch) => {
    dispatch({
      type: GET_ORDER_BY_ID_LOADING,
    });
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${getOrderDetails_Url}/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_ORDER_BY_ID_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_ORDER_BY_ID_ERROR,
            netError: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: GET_ORDER_BY_ID_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const adminCustomerSearchByContactAction = (detail) => {
  return (dispatch) => {
    dispatch({
      type: CUSTOMER_CONTACT_SEARCH_ADMIN_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      // method: "post",
      url: admin_contact_search + `/${detail.id}`,
      data: detail.data,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: CUSTOMER_CONTACT_SEARCH_ADMIN_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            netError: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            errors: validationError,
          });
        } else if (data.status === 401) {
          window.location.reload();
        }
      });
  };
};

export const adminCustomerSearchByNameAction = (detail) => {
  return (dispatch) => {
    dispatch({
      type: ADMIN_CUSTOMER_SEARCH_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      // method: "post",
      url: admin_customer_search,
      data: detail,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: ADMIN_CUSTOMER_SEARCH_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            netError: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            errors: validationError,
          });
        } else if (data.status === 401) {
          window.location.reload();
        }
      });
  };
};

export const getAdminDeliveryAgentsDataAction = (details) => {
  return (dispatch) => {
    dispatch({
      type: GET_ADMIN_DELIVERY_AGENTS_DATA_LOADING,
    });

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${getadmindeliveryagentUrl}/${details.id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_ADMIN_DELIVERY_AGENTS_DATA_SUCCESS,
          payload: userData,
          data: resp.data.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_ADMIN_DELIVERY_AGENTS_DATA_ERROR,
            errors: validationError,
          });
        } else if (data.status === 401) {
          localStorage.clear();
        }
      });
  };
};

export const getAdminagentwiseorderAction = (details) => {
  return (dispatch) => {
    dispatch({
      type: ADMIN_AGENT_WISE_ORDER_LOADING,
    });

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${adminagentwiseOrderUrl}/${details}`,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: ADMIN_AGENT_WISE_ORDER_SUCCESS,
          payload: userData,
          data: resp.data.data,
          fullData: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: ADMIN_AGENT_WISE_ORDER_ERROR,
            errors: validationError,
          });
        } else if (data.status === 401) {
          localStorage.clear();
        }
      });
  };
};

export const UpdateAdminOrderAction = (detail) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_ORDER_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: updateadminorderUrl + `/${detail?.id}`,
      data: detail.details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: UPDATE_ORDER_SUCCESS,
          response: {
            data: resp.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: UPDATE_ORDER_ERROR,
            netError: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: UPDATE_ORDER_ERROR,
            errors: validationError,
          });
        } else if (data.status === 401) {
          window.location.reload();
        }
      });
  };
};

export const exportadminPdf = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      url: `${exportadminpdfUrl}/${details}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
    };
    axios(requestOptions)
      .then((resp) => {
        const blob = new Blob([resp.data]);
        const fileName = `Panos_Web_${moment().format("DDMMyyyy")}_${Math.floor(
          100000 + Math.random() * 9000
        )}.pdf`;
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        dispatch({
          type: ADMIN_EXPORT_PDF_SUCCESS,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: ADMIN_EXPORT_PDF_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: ADMIN_EXPORT_PDF_ERROR,
            msg: validationError,
          });
        } else if (data.status === 401) {
          localStorage.clear();
        }
      });
  };
};

export const getAdminOrderFilterAction = (details) => {
  return (dispatch) => {
    dispatch({
      type: ADMIN_ORDER_FILTER_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${adminOrderFilter_Url}`,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: ADMIN_ORDER_FILTER_SUCCESS,
          response: {
            data: resp.data.data,
            fullData: resp.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: ADMIN_ORDER_FILTER_ERROR,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: ADMIN_ORDER_FILTER_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          window.location.reload();
        }
      });
  };
};

export const getswapagantActionAdmin = (id) => {
  return (dispatch) => {
    dispatch({
      type: GET_SWAP_AGENT_ADMIN_LOADING,
    });
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${getAdminswapagent_Url}/${id.locationID}/${id.agentID}`,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_SWAP_AGENT_ADMIN_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message,
          },
          // payload: userData,
          // data: resp.data.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_SWAP_AGENT_ADMIN_ERROR,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: GET_SWAP_AGENT_ADMIN_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

import {
  GET_MENU_DATA_ERROR,
  GET_MENU_DATA_LOADING,
  GET_MENU_DATA_SUCCESS,
} from "../../type/Constant";

let initialState = {
  loading: false,
  user: [],
  error: false,
  errors: [],
  success: false,
  pagination: {},
};

export default function getMenuReducer(state = initialState, action) {  
  switch (action.type) {
    case GET_MENU_DATA_LOADING:
      return { ...state, success: false, loading: true };

    case GET_MENU_DATA_SUCCESS:
      return { ...state, loading: false, success: true, user: action.payload, pagination:action.pagination };

    case GET_MENU_DATA_ERROR:
      return { ...state, error: true, errors: action.errors };

    default:
      return state;
  }
}

import React, { useEffect, useState } from "react";
import { Button, Dropdown, Form } from "react-bootstrap";
// import Footer from "../../Userlayout/Footer";
// import Header from "../../Userlayout/Header";
// import {
//   createPrmDataAction,
//   getAgentsData,
// } from "../../../../Componant/Store/Action/CreateAgents/UserAction";
import { useNavigate } from "react-router";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  createPrmDataAction,
  getAgentsData,
  getPrmDataAction,
} from "../../../../Store/Action/CreateAgents/UserAction";
import Header from "../../Userlayout/Header";
import Footer from "../../Userlayout/Footer";
import {
  contactValidation,
  countryCodeValidation,
  countrycodeValidation,
  RESET_ADD_PRM_DATA,
  validEmailRegex,
} from "../../../../Store/type/Constant";

const CreatePrm = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [prmApiData, setprmApiData] = useState({
    name: "",
    email: "",
    countrycode: "",
    password: "",
    confirmpassword: "",
    number: "",
    location: "",
    countrycode: "",
  });
  const [apiData, setapiData] = useState([]);
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    countrycode: false,
    password: false,
    confirmpassword: false,
    number: false,
    location: false,
  });

  const allLocationData = useSelector(
    (state) => state.getAgentsReducer.user.data
  );
  const allPrmData = useSelector((state) => state.getPrmReducer.user.data);

  // useEffect(() => {
  //   dispatch(
  //     getPrmDataAction({
  //       page: 1,
  //       limit: 10,
  //     })
  //   );
  // }, []);

  useEffect(() => {
    if (props.createPrmReducer.success) {
      navigate("/superadmin/prm");
    }
  }, [props.createPrmReducer]);

  useEffect(() => {
    dispatch(
      getAgentsData({
        page: 1,
        limit: 10,
      })
    );
  }, []);

  const onChangeInput = (e) => {
    setprmApiData({ ...prmApiData, [e.target.name]: e.target.value });

    switch (e.target.name) {
      case "location":
        if (e.target.value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            location: "location can not be empty",
          }));

          // document.getElementById("location").classList.add("error");
        } else {
          setErrors((prevState) => ({
            ...prevState,
            location: false,
          }));

          // document.getElementById("name").classList.remove("error");
        }
        break;

      case "name":
        if (e.target.value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            name: "name can not be empty",
          }));

          // document.getElementById("name").classList.add("error");
        } else {
          setErrors((prevState) => ({
            ...prevState,
            name: false,
          }));

          // document.getElementById("name").classList.remove("error");
        }
        break;

      case "email":
        if (e.target.value.length === "") {
          setErrors((prevState) => ({
            ...prevState,
            email: "Location Email can not be empty",
          }));

          document.getElementById("email").classList.add("error");
        } else if (!validEmailRegex.test(e.target.value)) {
          setErrors((prevState) => ({
            ...prevState,
            email: "Enter valid email",
          }));
          // document.getElementById("email").classList.add("error");
        } else {
          setErrors((prevState) => ({
            ...prevState,
            email: false,
          }));
          document.getElementById("email").classList.remove("error");
        }
        break;

      case "password":
        if (e.target.value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            password: "Password can not be empty",
          }));

          // document.getElementById("password").classList.add("error");
        } else {
          setErrors((prevState) => ({
            ...prevState,
            password: false,
          }));

          // document.getElementById("password").classList.remove("error");
        }
        break;

      case "confirmpassword":
        if (e.target.value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            confirmpassword: "confirmpassword can not be empty",
          }));

          // document.getElementById("confirmpassword").classList.add("error");
        } else if (prmApiData.password === prmApiData.confirmpassword) {
          setErrors((prevState) => ({
            ...prevState,
            confirmpassword: "confirmpassword can not be match",
          }));
          document.getElementById("confirmpassword").classList.remove("error");
        } else {
          setErrors((prevState) => ({
            ...prevState,
            confirmpassword: false,
          }));

          // document.getElementById("confirmpassword").classList.remove("error");
        }
        break;

      case "number":
        if (e.target.value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            number: "number can not be empty",
          }));

          document.getElementById("number").classList.add("error");
        } else if (!contactValidation.test(e.target.value)) {
          setErrors((prevState) => ({
            ...prevState,
            number: "Enter valid number",
          }));
          // document.getElementById("number").classList.add("error");
        } else {
          setErrors((prevState) => ({
            ...prevState,
            number: false,
          }));

          document.getElementById("number").classList.remove("error");
        }
        break;

      case "countrycode":
        if (e.target.value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            countrycode: "countrycode can not be empty",
          }));

          // document.getElementById("countrycode").classList.add("error");
        } else if (!countryCodeValidation.test(e.target.value)) {
          setErrors((prevState) => ({
            ...prevState,
            countrycode: "countrycode can not be match",
          }));
          document.getElementById("countrycode").classList.remove("error");
        } else {
          setErrors((prevState) => ({
            ...prevState,
            countrycode: false,
          }));

          // document.getElementById("countrycode").classList.remove("error");
        }
        break;

      default:
    }
  };

  const onSubmite = (e) => {
    e.preventDefault();

    for (let i = 0; i < allPrmData.length; i++) {
      if (allPrmData[i]?.email === prmApiData.email) {
        setErrors((prevState) => ({
          ...prevState,
          email: "Email already used",
        }));
      }
    }

    if (prmApiData.location == "") {
      setErrors((prevState) => ({
        ...prevState,
        location: "location can not be empty",
      }));
      // document.getElementById("location").classList.add("error");
    }

    if (prmApiData.name == "") {
      setErrors((prevState) => ({
        ...prevState,
        name: "name can not be empty",
      }));
      // document.getElementById("name").classList.add("error");
    }

    if (prmApiData.number == "") {
      setErrors((prevState) => ({
        ...prevState,
        number: "number can not be empty",
      }));
      // document.getElementById("number").classList.add("error");
    }

    if (prmApiData.email == "") {
      setErrors((prevState) => ({
        ...prevState,
        email: "email can not be empty",
      }));
      // document.getElementById("email").classList.add("error");
    }

    if (prmApiData.password == "") {
      setErrors((prevState) => ({
        ...prevState,
        password: "password can not be empty",
      }));
      // document.getElementById("password").classList.add("error");
    }

    if (prmApiData.confirmpassword == "") {
      setErrors((prevState) => ({
        ...prevState,
        confirmpassword: "confirmpassword can not be empty",
      }));
      // document.getElementById("confirmpassword").classList.add("error");
    }

    if (prmApiData.password !== prmApiData.confirmpassword) {
      setErrors((prevState) => ({
        ...prevState,
        confirmpassword: "password cant not match",
        password: "password can not match",
      }));
    }

    if (prmApiData.countrycode === "") {
      setErrors((prevState) => ({
        ...prevState,
        countrycode: "countrycode can not be empty",
      }));
    }

    if (
      prmApiData.name !== "" &&
      prmApiData.location !== "" &&
      prmApiData.email !== "" &&
      prmApiData.password !== "" &&
      prmApiData.number !== "" &&
      prmApiData.number.length == 10 &&
      prmApiData.confirmpassword !== "" &&
      prmApiData.countrycode !== "" &&
      prmApiData.password == prmApiData.confirmpassword
    ) {
      dispatch(
        createPrmDataAction({
          name: prmApiData.name,
          location: prmApiData.location,
          email: prmApiData.email,
          password: prmApiData.password,
          number: prmApiData.number,
          countrycode: prmApiData.countrycode,
          confirmpassword: prmApiData.confirmpassword,
        })
      );
    }
  };

  useEffect(() => {
    setapiData(allLocationData);
  }, [allLocationData]);

  return (
    <>
      <Header />
      <main className="userMain">
        <div className="content-block"> </div>
      </main>
      <Footer />
    </>
  );
};

const mapStateToProp = (state) => ({
  // submit: state.createLiabilityReducer,
  createPrmReducer: state.createPrmReducer,
});

const mapDispatchToProps = (dispatch) => ({
  // createprmdata: (user) => dispatch(createPrmDataAction(user)),
  // resetCreateLiabilityReducer: () => dispatch({ type: RESET_CREATE_LIABILITY }),
  getAgentsData: (Details) => dispatch(getAgentsData(Details)),
  resetprm: () => dispatch({ type: RESET_ADD_PRM_DATA }),
});
export default connect(mapStateToProp, mapDispatchToProps)(CreatePrm);

export const RESET_STORE = "RESET_STORE";

// ............................................Sign In.......................................................

// profile Details
export const SUPERADMIN_PROFILE_LOADING = "SUPERADMIN_PROFILE_LOADING";
export const SUPERADMIN_PROFILE_SUCCESS = "SUPERADMIN_PROFILE_SUCCESS";
export const SUPERADMIN_PROFILE_ERROR = "SUPERADMIN_PROFILE_ERROR";
export const RESET_SUPERADMIN_PROFILE = "RESET_SUPERADMIN_PROFILE";

export const GET_SIGNIN_LOADING = "GET_SIGNIN_LOADING";
export const GET_SIGNIN_SUCCESS = "GET_SIGNIN_SUCCESS";
export const GET_SIGNIN_ERROR = "GET_SIGNIN_ERROR";
export const RESET_SIGNIN_DATA = "RESET_SIGNIN_DATA";

// ..............................................Location...........................................................

export const GET_AGENTS_DATA_LOADING = "GET_AGENTS_DATA_LOADING";
export const GET_AGENTS_DATA_SUCCESS = "GET_AGENTS_DATA_SUCCESS";
export const GET_AGENTS_DATA_ERROR = "GET_AGENTS_DATA_ERROR";
export const DELETE_AGENTS_DATA = "DELETE_AGENTS_DATA";
export const RESET_LOCATION_DATA = "RESET_LOCATION_DATA";

export const ADD_AGENTS_DATA_LOADING = "ADD_AGENTS_DATA_LOADING";
export const ADD_AGENTS_DATA_SUCCESS = "ADD_AGENTS_DATA_SUCCESS";
export const ADD_AGENTS_DATA_ERROR = "ADD_AGENTS_DATA_ERROR";
export const RESET_ADD_AGENTS_DATA = "RESET_ADD_AGENTS_DATA";

export const UPDATE_AGENTS_DATA_LOADING = "UPDATE_AGENTS_DATA_LOADING";
export const UPDATE_AGENTS_DATA_SUCCESS = "UPDATE_AGENTS_DATA_SUCCESS";
export const UPDATE_AGENTS_DATA_ERROR = "UPDATE_AGENTS_DATA_ERROR";
export const RESET_UPDATE_AGENTS_DATA = "RESET_UPDATE_AGENTS_DATA";

// ..............................................Location List...........................................................

export const GET_LOCATIONLIST_DATA_LOADING = "GET_LOCATIONLIST_DATA_LOADING";
export const GET_LOCATIONLIST_DATA_SUCCESS = "GET_LOCATIONLIST_DATA_SUCCESS";
export const GET_LOCATIONLIST_DATA_ERROR = "GET_LOCATIONLIST_DATA_ERROR";
export const DELETE_LOCATIONLIST_DATA = "DELETE_LOCATIONLIST_DATA";
export const RESET_LOCATIONLIST_DATA = "RESET_LOCATIONLIST_DATA";

export const ADD_LOCATIONLIST_DATA_LOADING = "ADD_LOCATIONLIST_DATA_LOADING";
export const ADD_LOCATIONLIST_DATA_SUCCESS = "ADD_LOCATIONLIST_DATA_SUCCESS";
export const ADD_LOCATIONLIST_DATA_ERROR = "ADD_LOCATIONLIST_DATA_ERROR";
export const RESET_ADD_LOCATIONLIST_DATA = "RESET_ADD_LOCATIONLIST_DATA";

export const UPDATE_LOCATIONLIST_DATA_LOADING =
  "UPDATE_LOCATIONLIST_DATA_LOADING";
export const UPDATE_LOCATIONLIST_DATA_SUCCESS =
  "UPDATE_LOCATIONLIST_DATA_SUCCESS";
export const UPDATE_LOCATIONLIST_DATA_ERROR = "UPDATE_LOCATIONLIST_DATA_ERROR";
export const RESET_UPDATE_LOCATIONLIST_DATA = "RESET_UPDATE_LOCATIONLIST_DATA";

export const GET_VIEW_LOCATION_BY_ID_DATA_LOADING =
  "GET_VIEW_LOCATION_BY_ID_DATA_LOADING";
export const GET_VIEW_LOCATION_BY_ID_DATA_SUCCESS =
  "GET_VIEW_LOCATION_BY_ID_DATA_SUCCESS";
export const GET_VIEW_LOCATION_BY_ID__DATA_ERROR =
  "GET_VIEW_LOCATION_BY_ID__DATA_ERROR";
export const RESET_GET_VIEW_LOCATIONBY_ID_DATA_SUCCESS =
  "RESET_GET_VIEW_LOCATIONBY_ID_DATA_SUCCESS";

// ..................................................PRM.....................................................

export const GET_PRM_DATA_LOADING = "GET_PRM_DATA_LOADING";
export const GET_PRM_DATA_SUCCESS = "GET_PRM_DATA_SUCCESS";
export const GET_PRM_DATA_ERROR = "GET_PRM_DATA_ERROR";

export const ADD_PRM_DATA_LOADING = "ADD_PRM_DATA_LOADING";
export const ADD_PRM_DATA_SUCCESS = "ADD_PRM_DATA_SUCCESS";
export const ADD_PRM_DATA_ERROR = "ADD_PRM_DATA_ERROR";
export const RESET_ADD_PRM_DATA = "RESET_ADD_PRM_DATA";

export const UPDATE_PRM_DATA_LOADING = "UPDATE_PRM_DATA_LOADING";
export const UPDATE_PRM_DATA_SUCCESS = "UPDATE_PRM_DATA_SUCCESS";
export const UPDATE_PRM_DATA_ERROR = "UPDATE_PRM_DATA_ERROR";
export const RESET_UPDATE_PRM_DATA = "RESET_UPDATE_PRM_DATA";

export const DELETE_PRM_DATA_LOADING = " DELETE_PRM_DATA_LOADING";
export const DELETE_PRM_DATA_SUCCESS = " DELETE_PRM_DATA_SUCCESS";
export const DELETE_PRM_DATA_ERROR = " DELETE_PRM_DATA_ERROR";
export const RESET_DELETE_PRM_DATA = "RESET_DELETE_PRM_DATA";

export const GET_PRM_BY_ID_DATA_LOADING = "GET_PRM_BY_ID_DATA_LOADING";
export const GET_PRM_BY_ID_DATA_SUCCESS = "GET_PRM_BY_ID_DATA_SUCCESS";
export const GET_PRM_BY_ID__DATA_ERROR = "GET_PRM_BY_ID__DATA_ERROR";
export const RESET_GET_PRM_BY_ID_ = "RESET_GET_PRM_BY_ID_";

// .....................................................Menu...............................................................

// menu
export const GET_MENU_DATA_LOADING = "GET_MENU_DATA_LOADING";
export const GET_MENU_DATA_SUCCESS = "GET_MENU_DATA_SUCCESS";
export const GET_MENU_DATA_ERROR = "GET_MENU_DATA_ERROR";
export const DELETE_MENU_ITEM_DATA = "DELETE_MENU_ITEM_DATA";

export const ADD_MENU_DATA_LOADING = "ADD_MENU_DATA_LOADING";
export const ADD_MENU_DATA_SUCCESS = "ADD_MENU_DATA_SUCCESS";
export const ADD_MENU_DATA_ERROR = "ADD_MENU_DATA_ERROR";
export const RESET_ADD_MENU_DATA = "RESET_ADD_MENU_DATA";

export const UPDATE_MENU_DATA_LOADING = "UPDATE_MENU_DATA_LOADING";
export const UPDATE_MENU_DATA_SUCCESS = "UPDATE_MENU_DATA_SUCCESS";
export const UPDATE_MENU_DATA_ERROR = "UPDATE_MENU_DATA_ERROR";
export const RESET_UPDATE_MENU_DATA = "RESET_UPDATE_MENU_DATA";

export const DELETE_MENUITEMS_DATA_LOADING = " DELETE_MENUITEMS_DATA_LOADING";
export const DELETE_MENUITEMS_DATA_SUCCESS = " DELETE_MENUITEMS_DATA_SUCCESS";
export const DELETE_MENUITEMS_DATA_ERROR = " DELETE_MENUITEMS_DATA_ERROR";
export const RESET_DELETE_MENUITEMS_DATA = "RESET_DELETE_MENUITEMS_DATA";

export const GET_MENU_BY_ID_DATA_LOADING = "GET_MENU_BY_ID_DATA_LOADING";
export const GET_MENU_BY_ID_DATA_SUCCESS =
  "GET_DELIVERY_BY_ID_AGENTS_DATA_SUCCESS";
export const GET_MENU_BY_ID__DATA_ERROR =
  "GET_DELIVERY_BY_ID_AGENTS_DATA_ERROR";
export const RESET_GET_MENU_BY_ID_ = "RESET_GET_MENU_BY_ID_";

// ......................................................DeliveryAgents.....................................

// menu
export const GET_DELIVERY_AGENTS_DATA_LOADING =
  "GET_DELIVERY_AGENTS_DATA_LOADING";
export const GET_DELIVERY_AGENTS_DATA_SUCCESS =
  "GET_DELIVERY_AGENTS_DATA_SUCCESS";
export const GET_DELIVERY_AGENTS_DATA_ERROR = "GET_DELIVERY_AGENTS_DATA_ERROR";
export const DELETE_DELIVERY_AGENTS_ITEM_DATA =
  "DELETE_DELIVERY_AGENTS_ITEM_DATA";
export const RESET_GET_DELIVERY_DATA = "RESET_GET_DELIVERY_DATA";

export const ADD_DELIVERY_AGENTS_DATA_LOADING =
  "ADD_DELIVERY_AGENTS_DATA_LOADING";
export const ADD_DELIVERY_AGENTS_DATA_SUCCESS =
  "ADD_DELIVERY_AGENTS_DATA_SUCCESS";
export const ADD_DELIVERY_AGENTS_DATA_ERROR = "ADD_DELIVERY_AGENTS_DATA_ERROR";
export const RESET_ADD_DELIVERY_DATA = "RESET_ADD_DELIVERY_DATA";

export const UPDATE_DELIVERY_AGENTS_DATA_LOADING =
  "UPDATE_DELIVERY_AGENTS_DATA_LOADING";
export const UPDATE_DELIVERY_AGENTS_DATA_SUCCESS =
  "UPDATE_DELIVERY_AGENTS_DATA_SUCCESS";
export const UPDATE_DELIVERY_AGENTS_DATA_ERROR =
  "UPDATE_DELIVERY_AGENTS_DATA_ERROR";
export const RESET_UPDATE_DELIVERY_AGENTS_DATA_SUCCESS =
  "RESET_UPDATE_DELIVERY_AGENTS_DATA_SUCCESS";

export const GET_DELIVERY_AGENTS_BY_ID_DATA_LOADING =
  "GET_DELIVERY_AGENTS_BY_ID_DATA_LOADING";
export const GET_DELIVERY_AGENTS_BY_ID_DATA_SUCCESS =
  "GET_DELIVERY_BY_ID_AGENTS_DATA_SUCCESS";
export const GET_DELIVERY_AGENTS_BY_ID__DATA_ERROR =
  "GET_DELIVERY_AGENTS_BY_ID__DATA_ERROR";
export const RESET_GET_DELIVERY_BY_ID_AGENTS_DATA_SUCCESS =
  "RESET_GET_DELIVERY_BY_ID_AGENTS_DATA_SUCCESS";

export const DELETE_DELIVERY_AGENTS_DATA_LOADING =
  "DELETE_DELIVERY_AGENTS_DATA_LOADING";
export const DELETE_DELIVERY_AGENTS_DATA_SUCCESS =
  "DELETE_DELIVERY_AGENTS_DATA_SUCCESS";
export const DELETE_DELIVERY_AGENTS_DATA_ERROR =
  "DELETE_DELIVERY_AGENTS_DATA_ERROR";
export const RESET_DELETE_DELIVERY_AGENTS_DATA =
  "RESET_DELETE_DELIVERY_AGENTS_DATA";

// ...................................................... Cutomer .......................................................

export const GET_CUSTOMER_LOADING = "GET_CUSTOMER_LOADING";
export const GET_CUSTOMER_SUCCESS = "GET_CUSTOMER_SUCCESS";
export const GET_CUSTOMER_ERROR = "GET_CUSTOMER_ERROR";
export const DELETE_CUSTOMER_DATA = "DELETE_CUTOMER_DATA";
export const RESET_GET_CUSTOMER = "RESET_GET_CUSTOMER";

export const ADD_CUSTOMER_LOADING = "ADD_CUSTOMER_LOADING";
export const ADD_CUSTOMER_SUCCESS = "ADD_CUSTOMER_SUCCESS";
export const ADD_CUSTOMER_ERROR = "ADD_CUSTOMER_ERROR";
export const RESET_ADD_CUSTOMER = "RESET_ADD_CUSTOMER";

export const UPDATE_CUSTOMER_LOADING = "UPDATE_CUSTOMER_LOADING";
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS";
export const UPDATE_CUSTOMER_ERROR = "UPDATE_CUSTOMER_ERROR";
export const RESET_UPDATE_CUSTOMER = "RESET_UPDATE_CUSTOMER";

export const DELETE_CUSTOMER_DATA_LOADING = "DELETE_CUSTOMER_DATA_LOADING";
export const DELETE_CUSTOMER_DATA_SUCCESS = "DELETE_CUSTOMER_DATA_SUCCESS";
export const DELETE_CUSTOMER_DATA_ERROR = "DELETE_CUSTOMER_DATA_ERROR";
export const RESET_DELETE_CUSTOMER_DATA = "RESET_DELETE_CUSTOMER_DATA";

export const GET_CUSTOMER_BY_ID_DATA_LOADING =
  "GET_CUSTOMER_BY_ID_DATA_LOADING";
export const GET_CUSTOMER_BY_ID_DATA_SUCCESS =
  "GET_CUSTOMER_BY_ID_DATA_SUCCESS";
export const GET_CUSTOMER_BY_ID__DATA_ERROR = "GET_CUSTOMER_BY_ID__DATA_ERROR";
export const RESET_GET_CUSTOMER_BY_ID_DATA_SUCCESS =
  "RESET_GET_CUSTOMER_BY_ID_DATA_SUCCESS";

// ...................................................... Cutomer Address .......................................................

export const GET_CUSTOMER_ADDRESS_LOADING = "GET_CUSTOMER_ADDRESS_LOADING";
export const GET_CUSTOMER_ADDRESS_SUCCESS = "GET_CUSTOMER_ADDRESS_SUCCESS";
export const GET_CUSTOMER_ADDRESS_ERROR = "GET_CUSTOMER_ADDRESS_ERROR";
export const DELETE_CUSTOMER_ADDRESS_DATA = "DELETE_CUTOMER_DATA";

export const ADD_CUSTOMER_ADDRESS_LOADING = "ADD_CUSTOMER_ADDRESS_LOADING";
export const ADD_CUSTOMER_ADDRESS_SUCCESS = "ADD_CUSTOMER_ADDRESS_SUCCESS";
export const ADD_CUSTOMER_ADDRESS_ERROR = "ADD_CUSTOMER_ADDRESS_ERROR";

export const DELETE_CUSTOMER_ADDRESS_LOADING =
  "DELETE_CUSTOMER_ADDRESS_LOADING";
export const DELETE_CUSTOMER_ADDRESS_SUCCESS =
  "DELETE_CUSTOMER_ADDRESS_SUCCESS";
export const DELETE_CUSTOMER_ADDRESS_ERROR = "DELETE_CUSTOMER_ADDRESS_ERROR";
export const RESET_DELETE_CUSTOMER_ADDRESS = "RESET_DELETE_CUSTOMER_ADDRESS";

export const UPDATE_CUSTOMER_ADDRESS_LOADING =
  "UPDATE_CUSTOMER_ADDRESS_LOADING";
export const UPDATE_CUSTOMER_ADDRESS_SUCCESS =
  "UPDATE_CUSTOMER_ADDRESS_SUCCESS";
export const UPDATE_CUSTOMER_ADDRESS_ERROR = "UPDATE_CUSTOMER_ADDRESS_ERROR";

// ...................................................... Order .......................................................

export const GET_SUPER_ADMIN_ORDER_LOADING = "GET_SUPER_ADMIN_ORDER_LOADING";
export const GET_SUPER_ADMIN_ORDER_SUCCESS = "GET_SUPER_ADMIN_ORDER_SUCCESS";
export const GET_SUPER_ADMIN_ORDER_ERROR = "GET_SUPER_ADMIN_ORDER_ERROR";
export const RESET_GET_SUPER_ADMIN_ORDER = "RESET_GET_SUPER_ADMIN_ORDER";

export const GET_SUPER_ADMIN_ORDER_BY_ID_LOADING =
  "GET_SUPER_ADMIN_ORDER_BY_ID_LOADING";
export const GET_SUPER_ADMIN_ORDER_BY_ID_SUCCESS =
  "GET_SUPER_ADMIN_ORDER_BY_ID_SUCCESS";
export const GET_SUPER_ADMIN_ORDER_BY_ID_ERROR =
  "GET_SUPER_ADMIN_ORDER_BY_ID_ERROR";
export const RESET_GET_SUPER_ADMIN_ORDER_BY_ID =
  "RESET_GET_SUPER_ADMIN_ORDER_BY_ID";

export const ADD_SUPER_ADMIN_ORDER_LOADING = "ADD_SUPER_ADMIN_ORDER_LOADING";
export const ADD_SUPER_ADMIN_ORDER_SUCCESS = "ADD_SUPER_ADMIN_ORDER_SUCCESS";
export const ADD_SUPER_ADMIN_ORDER_ERROR = "ADD_SUPER_ADMIN_ORDER_ERROR";
export const RESET_ADD_SUPER_ADMIN_ORDER = "RESET_ADD_SUPER_ADMIN_ORDER";

export const UPDATE_SUPER_ADMIN_ORDER_LOADING =
  "UPDATE_SUPER_ADMIN_ORDER_LOADING";
export const UPDATE_SUPER_ADMIN_ORDER_SUCCESS =
  "UPDATE_SUPER_ADMIN_ORDER_SUCCESS";
export const UPDATE_SUPER_ADMIN_ORDER_ERROR = "UPDATE_SUPER_ADMIN_ORDER_ERROR";
export const RESET_UPDATE_SUPER_ADMIN_ORDER = "RESET_UPDATE_SUPER_ADMIN_ORDER";

export const DELETE_SUPER_ADMIN_ORDER_LOADING =
  "DELETE_SUPER_ADMIN_ORDER_LOADING";
export const DELETE_SUPER_ADMIN_ORDER_SUCCESS =
  "DELETE_SUPER_ADMIN_ORDER_SUCCESS";
export const DELETE_SUPER_ADMIN_ORDER_ERROR = "DELETE_SUPER_ADMIN_ORDER_ERROR";
export const RESET_DELETE_SUPER_ADMIN_ORDER = "RESET_DELETE_SUPER_ADMIN_ORDER";
export const DELETE_SUPER_ADMIN_ORDER_DATA = "DELETE_SUPER_ADMIN_ORDER_DATA";

export const GET_SUPERADMIN_ORDER_FILTER_LOADING =
  "GET_SUPERADMIN_ORDER_FILTER_LOADING";
export const GET_SUPERADMIN_ORDER_FILTER_SUCCESS =
  "GET_SUPERADMIN_ORDER_FILTER_SUCCESS";
export const GET_SUPERADMIN_ORDER_FILTER_ERROR =
  "GET_SUPERADMIN_ORDER_FILTER_ERROR";
export const RESET_GET_SUPERADMIN_ORDER_FILTER =
  "RESET_GET_SUPERADMIN_ORDER_FILTER";

// ....................  Location By Menu Items ..............................

export const GET_SUPER_ADMIN_LOCATION_BY_MENU_LOADING =
  "GET_SUPER_ADMIN_LOCATION_BY_MENU_LOADING";
export const GET_SUPER_ADMIN_LOCATION_BY_MENU_SUCCESS =
  "GET_SUPER_ADMIN_LOCATION_BY_MENU_SUCCESS";
export const GET_SUPER_ADMIN_LOCATION_BY_MENU_ERROR =
  "GET_SUPER_ADMIN_LOCATION_BY_MENU_ERROR";
export const RESET_GET_SUPER_ADMIN_LOCATION_BY_MENU =
  "RESET_GET_SUPER_ADMIN_LOCATION_BY_MENU";

export const ADD_SUPER_ADMIN_LOCATIOHN_BY_MENU_ITEMS_LOADING =
  "ADD_SUPER_ADMIN_LOCATIOHN_BY_MENU_ITEMS_LOADING";
export const ADD_SUPER_ADMIN_LOCATIOHN_BY_MENU_ITEMS_SUCCESS =
  "ADD_SUPER_ADMIN_LOCATIOHN_BY_MENU_ITEMS_SUCCESS";
export const ADD_SUPER_ADMIN_LOCATIOHN_BY_MENU_ITEMS_ERROR =
  "ADD_SUPER_ADMIN_LOCATIOHN_BY_MENU_ITEMS_ERROR";
export const RESET_ADD_SUPER_ADMIN_LOCATIOHN_BY_MENU_ITEMS =
  "RESET_ADD_SUPER_ADMIN_LOCATIOHN_BY_MENU_ITEMS";

export const UPDATE_SUPER_ADMIN_LOCATIOHN_BY_MENU_ITEMS_LOADING =
  "UPDATE_LOCATIOHN_BY_MENU_ITEMS_LOADING";
export const UPDATE_SUPER_ADMIN_LOCATIOHN_BY_MENU_ITEMS_SUCCESS =
  "UPDATE_LOCATIOHN_BY_MENU_ITEMS_SUCCESS";
export const UPDATE_SUPER_ADMIN_LOCATIOHN_BY_MENU_ITEMS_ERROR =
  "UPDATE_LOCATIOHN_BY_MENU_ITEMS_ERROR";
export const RESET_UPDATE_SUPER_ADMIN_LOCATIOHN_BY_MENU_ITEMS =
  "RESET_UPDATE_LOCATIOHN_BY_MENU_ITEMS";

export const DELETE_SUPER_ADMIN_LOCATIOHN_BY_MENU_ITEMS_LOADING =
  "DELETE_LOCATIOHN_BY_MENU_ITEMS_LOADING";
export const DELETE_SUPER_ADMIN_LOCATIOHN_BY_MENU_ITEMS_SUCCESS =
  "DELETE_LOCATIOHN_BY_MENU_ITEMS_SUCCESS";
export const DELETE_SUPER_ADMIN_LOCATIOHN_BY_MENU_ITEMS_ERROR =
  "DELETE_LOCATIOHN_BY_MENU_ITEMS_ERROR";
export const RESET_DELETE_SUPER_ADMIN_LOCATIOHN_BY_MENU_ITEMS =
  "RESET_DELETE_LOCATIOHN_BY_MENU_ITEMS";
export const DELETE_SUPER_ADMIN_LOCATIOHN_BY_MENU_ITEMS_DATA =
  "DELETE_LOCATIOHN_BY_MENU_ITEMS_DATA";

// ....................  Location By Agents ..............................

export const GET_SUPER_ADMIN_LOCATION_BY_Agents_LOADING =
  "GET_SUPER_ADMIN_LOCATION_BY_Agents_LOADING";
export const GET_SUPER_ADMIN_LOCATION_BY_Agents_SUCCESS =
  "GET_SUPER_ADMIN_LOCATION_BY_Agents_SUCCESS";
export const GET_SUPER_ADMIN_LOCATION_BY_Agents_ERROR =
  "GET_SUPER_ADMIN_LOCATION_BY_Agents_ERROR";
export const RESET_GET_SUPER_ADMIN_LOCATION_BY_Agents =
  "RESET_GET_SUPER_ADMIN_LOCATION_BY_Agents";

// ....................  Location By Order ..............................

export const GET_SUPER_ADMIN_LOCATION_BY_OREDR_LOADING =
  "GET_SUPER_ADMIN_LOCATION_BY_OREDR_LOADING";
export const GET_SUPER_ADMIN_LOCATION_BY_OREDR_SUCCESS =
  "GET_SUPER_ADMIN_LOCATION_BY_OREDR_SUCCESS";
export const GET_SUPER_ADMIN_LOCATION_BY_OREDR_ERROR =
  "GET_SUPER_ADMIN_LOCATION_BY_OREDR_ERROR";
export const RESET_GET_SUPER_ADMIN_LOCATION_BY_OREDR =
  "RESET_GET_SUPER_ADMIN_LOCATION_BY_OREDR";

// find customer by contact

export const FIND_SUPERADMIN_CUSTOMER_BY_CONTACT_LOADING =
  "FIND_SUPERADMIN_CUSTOMER_BY_CONTACT_LOADING";
export const FIND_SUPERADMIN_CUSTOMER_BY_CONTACT_SUCCESS =
  "FIND_SUPERADMIN_CUSTOMER_BY_CONTACT_SUCCESS";
export const FIND_SUPERADMIN_CUSTOMER_BY_CONTACT_ERROR =
  "FIND_SUPERADMIN_CUSTOMER_BY_CONTACT_ERROR";
export const RESET_FIND_SUPERADMIN_CUSTOMER_BY_CONTACT =
  "RESET_FIND_SUPERADMIN_CUSTOMER_BY_CONTACT";

// Change password

export const SUPERADMIN_CHANGE_PASSWORD_LOADING =
  "SUPERADMIN_CHANGE_PASSWORD_LOADING";
export const SUPERADMIN_CHANGE_PASSWORD_SUCCESS =
  "SUPERADMIN_CHANGE_PASSWORD_SUCCESS";
export const SUPERADMIN_CHANGE_PASSWORD_ERROR =
  "SUPERADMIN_CHANGE_PASSWORD_ERROR";
export const RESET_SUPERADMIN_CHANGE_PASSWORD =
  "RESET_SUPERADMIN_CHANGE_PASSWORD";

// forgot password
export const SUPERADMIN_FORGOT_PASSWORD_LOADING =
  "SUPERADMIN_FORGOT_PASSWORD_LOADING";
export const SUPERADMIN_FORGOT_PASSWORD_SUCCESS =
  "SUPERADMIN_FORGOT_PASSWORD_SUCCESS";
export const SUPERADMIN_FORGOT_PASSWORD_ERROR =
  "SUPERADMIN_FORGOT_PASSWORD_ERROR";
export const RESET_SUPERADMIN_FORGOT_PASSWORD =
  "RESET_SUPERADMIN_FORGOT_PASSWORD";

// verify otp
export const SUPERADMIN_OTP_VERIFY_LOADING = "SUPERADMIN_OTP_VERIFY_LOADING";
export const SUPERADMIN_OTP_VERIFY_SUCCESS = "SUPERADMIN_OTP_VERIFY_SUCCESS";
export const SUPERADMIN_OTP_VERIFY_ERROR = "SUPERADMIN_OTP_VERIFY_ERROR";
export const RESET_SUPERADMIN_OTP_VERIFY = "RESET_SUPERADMIN_OTP_VERIFY";

// reset password
export const RESET_SUPERADMIN_PASSWORD_LOADING =
  "RESET_SUPERADMIN_PASSWORD_LOADING";
export const RESET_SUPERADMIN_PASSWORD_SUCCESS =
  "RESET_SUPERADMIN_PASSWORD_SUCCESS";
export const RESET_SUPERADMIN_PASSWORD_ERROR =
  "RESET_SUPERADMIN_PASSWORD_ERROR";
export const RESET_RESET_SUPERADMIN_PASSWORD =
  "RESET_RESET_SUPERADMIN_PASSWORD";

// regexp
export const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
export const validPassword = RegExp(
  /^(?:(?:(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]))|(?:(?=.*[a-z])(?=.*[A-Z])(?=.*[*.!@$%^&(){}[]:;<>,.?~_+-=|\]))|(?:(?=.*[0-9])(?=.*[A-Z])(?=.*[*.!@$%^&(){}[]:;<>,.?~_+-=|\]))|(?:(?=.*[0-9])(?=.*[a-z])(?=.*[*.!@$%^&(){}[]:;<>,.?~_+-=|\]))).{8,35}$/
);
export const contactValidation = RegExp(/^(?!0+\s+,?$)\d{10}\s*,?$/);
export const countryCodeValidation = RegExp(/^(\+?\d{1,3}|\d{1,4})$/);
export const onlyNumberValidation = RegExp(/^[0-9]*$/);
export const Sales_tax_Validation = RegExp(/^0*(?:[1-9][0-9]?|100)$/);
export const pincodeValidation = RegExp(/\b\d{5}\b/g);
export const Us_Pattern_Number = RegExp(
  /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/
);

// ....................  Location By PRM ..............................

export const GET_SUPER_ADMIN_LOCATION_BY_PRM_LOADING =
  "GET_SUPER_ADMIN_LOCATION_BY_PRM_LOADING";
export const GET_SUPER_ADMIN_LOCATION_BY_PRM_SUCCESS =
  "GET_SUPER_ADMIN_LOCATION_BY_PRM_SUCCESS";
export const GET_SUPER_ADMIN_LOCATION_BY_PRM_ERROR =
  "GET_SUPER_ADMIN_LOCATION_BY_PRM_ERROR";
export const RESET_GET_SUPER_ADMIN_LOCATION_BY_PRM =
  "RESET_GET_SUPER_ADMIN_LOCATION_BY_PRM";

// ....................  Location Wise agents ..............................

export const GET_SUPER_ADMIN_LOCATION_WISE_AGENTS_LOADING =
  "GET_SUPER_ADMIN_LOCATION_WISE_AGENTS_LOADING";
export const GET_SUPER_ADMIN_LOCATION_WISE_AGENTS_SUCCESS =
  "GET_SUPER_ADMIN_LOCATION_WISE_AGENTS_SUCCESS";
export const GET_SUPER_ADMIN_LOCATION_WISE_AGENTS_ERROR =
  "GET_SUPER_ADMIN_LOCATION_WISE_AGENTS_ERROR";
export const RESET_GET_SUPER_ADMIN_LOCATION_WISE_AGENTS =
  "RESET_GET_SUPER_ADMIN_LOCATION_WISE_AGENTS";

// ..........superadminchangepassword............
export const SUPERADMIN_TO_ADMIN_CHANGE_PASSWORD_LOADING =
  "SUPERADMIN_TO_ADMIN_CHANGE_PASSWORD_LOADING";
export const SUPERADMIN_TO_ADMIN_CHANGE_PASSWORD_SUCCESS =
  "SUPERADMIN_TO_ADMIN_CHANGE_PASSWORD_SUCCESS";
export const SUPERADMIN_TO_ADMIN_CHANGE_PASSWORD_ERROR =
  "SUPERADMIN_TO_ADMIN_CHANGE_PASSWORD_ERROR";
export const RESET_SUPERADMIN_TO_ADMIN_CHANGE_PASSWORD =
  "RESET_SUPERADMIN_TO_ADMIN_CHANGE_PASSWORD";

// ..........Customer Serach By ContactUrl............
export const CUSTOMER_SERACH_BY_CONTACT_LOADING =
  "CUSTOMER_SERACH_BY_CONTACT_LOADING";
export const CUSTOMER_SERACH_BY_CONTACT_SUCCESS =
  "CUSTOMER_SERACH_BY_CONTACT_SUCCESS";
export const CUSTOMER_SERACH_BY_CONTACT_ERROR =
  "CUSTOMER_SERACH_BY_CONTACT_ERROR";
export const RESET_CUSTOMER_SERACH_BY_CONTACT =
  "RESET_CUSTOMER_SERACH_BY_CONTACT";

// ..........Customer Serach By Name............
export const CUSTOMER_SERACH_BY_NAME_LOADING =
  "CUSTOMER_SERACH_BY_NAME_LOADING";
export const CUSTOMER_SERACH_BY_NAME_SUCCESS =
  "CUSTOMER_SERACH_BY_NAME_SUCCESS";
export const CUSTOMER_SERACH_BY_NAME_ERROR = "CUSTOMER_SERACH_BY_NAME_ERROR";
export const RESET_CUSTOMER_SERACH_BY_NAME = "RESET_CUSTOMER_SERACH_BY_NAME";

// ..........export pdf............
export const EXPORT_PDF_LOADING = "EXPORT_PDF_LOADING";
export const EXPORT_PDF_SUCCESS = "EXPORT_PDF_SUCCESS";
export const EXPORT_PDF_ERROR = "EXPORT_PDF_ERROR";
export const RESET_EXPORT_PDF = "RESET_EXPORT_PDF";

// ..........agents wise order............
export const AGENTSWISE_ORDER_LOADING = "AGENTSWISE_ORDER_LOADING";
export const AGENTSWISE_ORDER_SUCCESS = "AGENTSWISE_ORDER_SUCCESS";
export const AGENTSWISE_ORDER_ERROR = "AGENTSWISE_ORDER_ERROR";
export const RESET_AGENTSWISE_ORDER = "RESET_AGENTSWISE_ORDER";

// ..........agentschangepassword............
export const AGENTS_CHANGE_PASSWORD_LOADING = "AGENTS_CHANGE_PASSWORD_LOADING";
export const AGENTS_CHANGE_PASSWORD_SUCCESS = "AGENTS_CHANGE_PASSWORD_SUCCESS";
export const AGENTS_CHANGE_PASSWORD_ERROR = "AGENTS_CHANGE_PASSWORD_ERROR";
export const RESET_AGENTS_CHANGE_PASSWORD = "RESET_AGENTS_CHANGE_PASSWORD";

// ..........See Location Wise Tip............
export const LOCATION_WISE_TOTAL_TIP_LOADING =
  "LOCATION_WISE_TOTAL_TIP_LOADING";
export const LOCATION_WISE_TOTAL_TIP_SUCCESS =
  "LOCATION_WISE_TOTAL_TIP_SUCCESS";
export const LOCATION_WISE_TOTAL_TIP_ERROR = "LOCATION_WISE_TOTAL_TIP_ERROR";
export const RESET_LOCATION_WISE_TOTAL_TIP = "RESET_LOCATION_WISE_TOTAL_TIP";

// ..........export Tip pdf............
export const EXPORT_TIP_PDF_LOADING = "EXPORT_TIP_PDF_LOADING";
export const EXPORT_TIP_PDF_SUCCESS = "EXPORT_TIP_PDF_SUCCESS";
export const EXPORT_TIP_PDF_ERROR = "EXPORT_TIP_PDF_ERROR";
export const RESET_EXPORT_TIP_PDF = "RESET_EXPORT_TIP_PDF";

// ..........reports Order............
export const REPORTS_ORDER_COUNT_LOADING = "REPORTS_ORDER_COUNT_LOADING";
export const REPORTS_ORDER_COUNT_SUCCESS = "REPORTS_ORDER_COUNT_SUCCESS";
export const REPORTS_ORDER_COUNT_ERROR = "REPORTS_ORDER_COUNT_ERROR";
export const RESET_REPORTS_ORDER_COUNT = "RESET_REPORTS_ORDER_COUNT";

// ............ customer total Order count ..............
export const CUSTOMER_TOTAL_ORDER_COUNT_LOADING =
  "CUSTOMER_TOTAL_ORDER_COUNT_LOADING";
export const CUSTOMER_TOTAL_ORDER_COUNT_SUCCESS =
  "CUSTOMER_TOTAL_ORDER_COUNT_SUCCESS";
export const CUSTOMER_TOTAL_ORDER_COUNT_ERROR =
  "CUSTOMER_TOTAL_ORDER_COUNT_ERROR";
export const RESET_CUSTOMER_TOTAL_ORDER_COUNT =
  "RESET_CUSTOMER_TOTAL_ORDER_COUNT";

// ..........reports location Order............
export const REPORTS_LOCATION_ORDER_COUNT_LOADING =
  "REPORTS_LOCATION_ORDER_COUNT_LOADING";
export const REPORTS_LOCATION_ORDER_COUNT_SUCCESS =
  "REPORTS_LOCATION_ORDER_COUNT_SUCCESS";
export const REPORTS_LOCATION_ORDER_COUNT_ERROR =
  "REPORTS_LOCATION_ORDER_COUNT_ERROR";
export const RESET_REPORTS_LOCATION_ORDER_COUNT =
  "RESET_REPORTS_LOCATION_ORDER_COUNT";

// ........................ get swapagant  ...............................

export const GET_SWAP_AGENT_LOADING = "GET_SWAP_AGENT_LOADING";
export const GET_SWAP_AGENT_SUCCESS = "GET_SWAP_AGENT_SUCCESS";
export const GET_SWAP_AGENT_ERROR = "GET_SWAP_AGENT_ERROR";
export const RESET_GET_SWAP_AGENT = "RESET_GET_SWAP_AGENT";

// ........................ get Admin swapagant  ...............................

export const GET_SWAP_AGENT_ADMIN_LOADING = "GET_SWAP_AGENT_ADMIN_LOADING";
export const GET_SWAP_AGENT_ADMIN_SUCCESS = "GET_SWAP_AGENT_ADMIN_SUCCESS";
export const GET_SWAP_AGENT_ADMIN_ERROR = "GET_SWAP_AGENT_ADMIN_ERROR";
export const RESET_GET_SWAP_AGENT_ADMIN = "RESET_GET_SWAP_AGENT_ADMIN";

// ........................ Export menu pdf  ...............................

export const IMPORT_MENU_EXCEL_LOADING = "IMPORT_MENU_EXCEL_LOADING";
export const IMPORT_MENU_EXCEL_SUCCESS = "IMPORT_MENU_EXCEL_SUCCESS";
export const IMPORT_MENU_EXCEL_ERROR = "IMPORT_MENU_EXCEL_ERROR";
export const RESET_IMPORT_MENU_EXCEL = "RESET_IMPORT_MENU_EXCEL";

export const UPDATE_PRM_FOR_LOCATION_LOADING =
  "UPDATE_PRM_FOR_LOCATION_LOADING";
export const UPDATE_PRM_FOR_LOCATION_SUCCESS =
  "UPDATE_PRM_FOR_LOCATION_SUCCESS";
export const UPDATE_PRM_FOR_LOCATION_ERROR = "UPDATE_PRM_FOR_LOCATION_ERROR";
export const RESET_UPDATE_PRM_FOR_LOCATION = "RESET_UPDATE_PRM_FOR_LOCATION";

export const LOCATION_WISE_CUSTOMER_LOADING = "LOCATION_WISE_CUSTOMER_LOADING";
export const LOCATION_WISE_CUSTOMER_SUCCESS = "LOCATION_WISE_CUSTOMER_SUCCESS";
export const LOCATION_WISE_CUSTOMER_ERROR = "LOCATION_WISE_CUSTOMER_ERROR";
export const RESET_LOCATION_WISE_CUSTOMER = "RESET_LOCATION_WISE_CUSTOMER";

export const SUPERADMIN_SEARCH_LOCATION_LOADING = "SUPERADMIN_SEARCH_LOCATION_LOADING";
export const SUPERADMIN_SEARCH_LOCATION_SUCCESS = "SUPERADMIN_SEARCH_LOCATION_SUCCESS";
export const SUPERADMIN_SEARCH_LOCATION_ERROR = "SUPERADMIN_SEARCH_LOCATION_ERROR";
export const RESET_SUPERADMIN_SEARCH_LOCATION = "RESET_SUPERADMIN_SEARCH_LOCATION";

export const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
};

export const removeStorage = () => {
  localStorage.clear();
  localStorage.removeItem("token");
  window.location = "/superadmin/login";
};

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const getDateWithPrefix = (dom) => {
  if (dom == 31 || dom == 21 || dom == 1) return dom + "st";
  else if (dom == 22 || dom == 2) return dom + "nd";
  else if (dom == 23 || dom == 3) return dom + "rd";
  else return dom + "th";
};

import { ADMIN_LOGOUT_ERROR, ADMIN_LOGOUT_LOADING, ADMIN_LOGOUT_SUCCESS, LOGIN_ERROR, LOGIN_LOADING, LOGIN_SUCCESS, PROFILE_ERROR, PROFILE_LOADING, PROFILE_SUCCESS, RESET_ADMIN_LOGOUT, RESET_LOGIN, RESET_PROFILE } from "../../../Constants/Constants";

let initialState = {
  loginReducer: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
  logOutReducer: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
  profileDetails: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  }
};

export default function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_LOADING:
      return { ...state, loginReducer: { ...state.loginReducer, loading: true, error: false, errors: [], success: false } };

    case LOGIN_SUCCESS:
      return { ...state, loginReducer: { ...state.loginReducer, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case LOGIN_ERROR:
      return { ...state, loginReducer: { ...state.loginReducer, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_LOGIN:
      return { ...state, loginReducer: { ...state.loginReducer, loading: false, error: false, errors: null, success: false } };


    // logout
    case ADMIN_LOGOUT_LOADING:
      return { ...state, logOutReducer: { ...state.logOutReducer, loading: true, error: false, errors: [], success: false } };

    case ADMIN_LOGOUT_SUCCESS:
      return { ...state, logOutReducer: { ...state.logOutReducer, loading: false, error: false, errors: [], success: true, data: action.response.msg } };

    case ADMIN_LOGOUT_ERROR:
      return { ...state, logOutReducer: { ...state.logOutReducer, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_ADMIN_LOGOUT:
      return { ...state, logOutReducer: { ...state.logOutReducer, loading: false, error: false, errors: null, success: false } };


      // profile
    case PROFILE_LOADING:
      return { ...state, profileDetails: { ...state.profileDetails, loading: true, error: false, errors: [], success: false } };

    case PROFILE_SUCCESS:
      return { ...state, profileDetails: { ...state.profileDetails, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case PROFILE_ERROR:
      return { ...state, profileDetails: { ...state.profileDetails, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_PROFILE:
      return { ...state, profileDetails: { ...state.profileDetails, loading: false, error: false, errors: null, success: false } };

    default:
      return state;
  }
}

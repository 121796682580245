import { GET_SUPER_ADMIN_LOCATION_WISE_AGENTS_ERROR, GET_SUPER_ADMIN_LOCATION_WISE_AGENTS_LOADING, GET_SUPER_ADMIN_LOCATION_WISE_AGENTS_SUCCESS } from "../type/Constant";

let initialState = {
  getLocationWiseAgents: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
    pagination:{}
  },
};

export default function getLocationWiseAgents(state = initialState, action) {
  switch (action.type) {
    
    case GET_SUPER_ADMIN_LOCATION_WISE_AGENTS_LOADING:
      return { ...state, getLocationWiseAgents: { ...state.getLocationWiseAgents, loading: true, error: false, errors: [], success: false } };

    case GET_SUPER_ADMIN_LOCATION_WISE_AGENTS_SUCCESS:
      return { ...state, getLocationWiseAgents: { ...state.getLocationWiseAgents, loading: false, error: false, errors: [], success: true, data: action.response.data, pagination: action.pagination } };

    case GET_SUPER_ADMIN_LOCATION_WISE_AGENTS_ERROR:
      return { ...state, getLocationWiseAgents: { ...state.getLocationWiseAgents, loading: false, error: true, errors: action.errors, success: false, data: null } };

    default:
      return state;
  }
}

import {
  GET_SUPER_ADMIN_LOCATION_BY_OREDR_ERROR,
  GET_SUPER_ADMIN_LOCATION_BY_OREDR_LOADING,
  GET_SUPER_ADMIN_LOCATION_BY_OREDR_SUCCESS,
  RESET_GET_SUPER_ADMIN_LOCATION_BY_OREDR,
} from "../type/Constant";

let initialState = {
  getLocationByOrder: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
    pagination: {},
  },
};

export default function getLocationByOrder(state = initialState, action) {
  switch (action.type) {
    case GET_SUPER_ADMIN_LOCATION_BY_OREDR_LOADING:
      return {
        ...state,
        getLocationByOrder: {
          ...state.getLocationByOrder,
          loading: true,
          error: false,
          errors: [],
          success: false,
        },
      };

    case GET_SUPER_ADMIN_LOCATION_BY_OREDR_SUCCESS:
      return {
        ...state,
        getLocationByOrder: {
          ...state.getLocationByOrder,
          loading: false,
          error: false,
          errors: [],
          success: true,
          data: action.response.data,
          pagination: action.pagination,
        },
      };

    case GET_SUPER_ADMIN_LOCATION_BY_OREDR_ERROR:
      return {
        ...state,
        getLocationByOrder: {
          ...state.getLocationByOrder,
          loading: false,
          error: true,
          errors: action.errors,
          success: false,
          data: null,
        },
      };

    case RESET_GET_SUPER_ADMIN_LOCATION_BY_OREDR:
      return {
        ...state,
        getLocationByOrder: {
          ...state.getLocationByOrder,
          loading: false,
          error: false,
          errors: false,
          success: false,
          data: null,
        },
      };

    default:
      return state;
  }
}

import {
  DELETE_CUSTOMER_ADDRESS_ERROR,
  DELETE_CUSTOMER_ADDRESS_LOADING,
  DELETE_CUSTOMER_ADDRESS_SUCCESS,
  RESET_DELETE_CUSTOMER_ADDRESS,
} from "../type/Constant";

let initialState = {
  loading: false,
  data: null,
  error: false,
  errors: null,
  success: false,
  flag: null,
};

export default function DeleteCustomerAddressReducer(state = initialState, action) {
  switch (action.type) {
    //  delete Menu by id
    case DELETE_CUSTOMER_ADDRESS_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        errors: [],
        success: false,
      };

    case DELETE_CUSTOMER_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errors: [],
        success: true,
        data: action.response.data,
        flag: action?.response?.flag,
      };

    case DELETE_CUSTOMER_ADDRESS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errors: action.errors,
        success: false,
        data: null,
        flag: action?.flag,
      };

    case RESET_DELETE_CUSTOMER_ADDRESS:
      return {
        ...state,
        loading: false,
        error: false,
        errors: null,
        success: false,
        flag: null,
      };

    default:
      return state;
  }
}

import {
  DELETE_CUSTOMER_DATA_ERROR,
  DELETE_CUSTOMER_DATA_LOADING,
  DELETE_CUSTOMER_DATA_SUCCESS,
  RESET_DELETE_CUSTOMER_DATA,
} from "../type/Constant";

let initialState = {
  loading: false,
  data: null,
  error: false,
  errors: null,
  success: false,
};

export default function DeleteCustomerReducer(state = initialState, action) {
  switch (action.type) {
    //  delete Menu by id
    case DELETE_CUSTOMER_DATA_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        errors: [],
        success: false,
      };

    case DELETE_CUSTOMER_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errors: [],
        success: true,
        data: action.response.data,
      };

    case DELETE_CUSTOMER_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errors: action.errors,
        success: false,
        data: null,
      };

    case RESET_DELETE_CUSTOMER_DATA:
      return {
        ...state,
        loading: false,
        error: false,
        errors: null,
        success: false,
      };

    default:
      return state;
  }
}

import { ADD_SUPER_ADMIN_LOCATIOHN_BY_MENU_ITEMS_ERROR, ADD_SUPER_ADMIN_LOCATIOHN_BY_MENU_ITEMS_LOADING, ADD_SUPER_ADMIN_LOCATIOHN_BY_MENU_ITEMS_SUCCESS, DELETE_SUPER_ADMIN_LOCATIOHN_BY_MENU_ITEMS_DATA, DELETE_SUPER_ADMIN_LOCATIOHN_BY_MENU_ITEMS_ERROR, DELETE_SUPER_ADMIN_LOCATIOHN_BY_MENU_ITEMS_LOADING, DELETE_SUPER_ADMIN_LOCATIOHN_BY_MENU_ITEMS_SUCCESS, GET_SUPER_ADMIN_LOCATION_BY_MENU_ERROR, GET_SUPER_ADMIN_LOCATION_BY_MENU_LOADING, GET_SUPER_ADMIN_LOCATION_BY_MENU_SUCCESS, RESET_ADD_SUPER_ADMIN_LOCATIOHN_BY_MENU_ITEMS, RESET_GET_SUPER_ADMIN_LOCATION_BY_MENU, RESET_UPDATE_SUPER_ADMIN_LOCATIOHN_BY_MENU_ITEMS, UPDATE_SUPER_ADMIN_LOCATIOHN_BY_MENU_ITEMS_ERROR, UPDATE_SUPER_ADMIN_LOCATIOHN_BY_MENU_ITEMS_LOADING, UPDATE_SUPER_ADMIN_LOCATIOHN_BY_MENU_ITEMS_SUCCESS } from "../type/Constant";

let initialState = {
  getLocationByMenu: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
    pagination:{}
  },
  getLocationByMenuId: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
  updateLocationByMenu: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
  deleteLocationByMenu: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
  addLocationByMenu: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
};

export default function getLocationByMenu(state = initialState, action) {
  switch (action.type) {
    
    case GET_SUPER_ADMIN_LOCATION_BY_MENU_LOADING:
      return { ...state, getLocationByMenu: { ...state.getLocationByMenu, loading: true, error: false, errors: [], success: false } };

    case GET_SUPER_ADMIN_LOCATION_BY_MENU_SUCCESS:
      return { ...state, getLocationByMenu: { ...state.getLocationByMenu, loading: false, error: false, errors: [], success: true, data: action.response.data, pagination: action.pagination } };

    case GET_SUPER_ADMIN_LOCATION_BY_MENU_ERROR:
      return { ...state, getLocationByMenu: { ...state.getLocationByMenu, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_GET_SUPER_ADMIN_LOCATION_BY_MENU:
      return { ...state, getLocationByMenu: { ...state.getLocationByMenu, loading: false, error: false, errors: null, success: false } };


    //  get Menu by id
    // case GET_SUPER_ADMIN_ORDER_BY_ID_LOADING:
    //   return { ...state, getLocationByMenuId: { ...state.getLocationByMenuId, loading: true, error: false, errors: [], success: false } };

    // case GET_SUPER_ADMIN_ORDER_BY_ID_SUCCESS:
    //   return { ...state, getLocationByMenuId: { ...state.getLocationByMenuId, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    // case GET_SUPER_ADMIN_ORDER_BY_ID_ERROR:
    //   return { ...state, getLocationByMenuId: { ...state.getLocationByMenuId, loading: false, error: true, errors: action.errors, success: false, data: null } };

    // case RESET_GET_SUPER_ADMIN_ORDER_BY_ID:
    //   return { ...state, getLocationByMenuId: { ...state.getLocationByMenuId, loading: false, error: false, errors: null, success: false } };


    //  update Menu by id
    case UPDATE_SUPER_ADMIN_LOCATIOHN_BY_MENU_ITEMS_LOADING:
      return { ...state, updateLocationByMenu: { ...state.updateLocationByMenu, loading: true, error: false, errors: [], success: false } };

    case UPDATE_SUPER_ADMIN_LOCATIOHN_BY_MENU_ITEMS_SUCCESS:
      return { ...state, updateLocationByMenu: { ...state.updateLocationByMenu, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case UPDATE_SUPER_ADMIN_LOCATIOHN_BY_MENU_ITEMS_ERROR:
      return { ...state, updateLocationByMenu: { ...state.updateLocationByMenu, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_UPDATE_SUPER_ADMIN_LOCATIOHN_BY_MENU_ITEMS:
      return { ...state, updateLocationByMenu: { ...state.updateLocationByMenu, loading: false, error: false, errors: null, success: false } };


    //  delete Menu by id
    case DELETE_SUPER_ADMIN_LOCATIOHN_BY_MENU_ITEMS_LOADING:
      return { ...state, deleteLocationByMenu: { ...state.deleteLocationByMenu, loading: true, error: false, errors: [], success: false } };

    case DELETE_SUPER_ADMIN_LOCATIOHN_BY_MENU_ITEMS_SUCCESS:
      return { ...state, deleteLocationByMenu: { ...state.deleteLocationByMenu, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case DELETE_SUPER_ADMIN_LOCATIOHN_BY_MENU_ITEMS_ERROR:
      return { ...state, deleteLocationByMenu: { ...state.deleteLocationByMenu, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case DELETE_SUPER_ADMIN_LOCATIOHN_BY_MENU_ITEMS_DATA:
      return { ...state, deleteLocationByMenu: { ...state.deleteLocationByMenu, loading: false, error: false, errors: null, success: false } };


    //  add menu
    case ADD_SUPER_ADMIN_LOCATIOHN_BY_MENU_ITEMS_LOADING:
      return { ...state, addLocationByMenu: { ...state.addLocationByMenu, loading: true, error: false, errors: [], success: false } };

    case ADD_SUPER_ADMIN_LOCATIOHN_BY_MENU_ITEMS_SUCCESS:
      return { ...state, addLocationByMenu: { ...state.addLocationByMenu, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case ADD_SUPER_ADMIN_LOCATIOHN_BY_MENU_ITEMS_ERROR:
      return { ...state, addLocationByMenu: { ...state.addLocationByMenu, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_ADD_SUPER_ADMIN_LOCATIOHN_BY_MENU_ITEMS:
      return { ...state, addLocationByMenu: { ...state.addLocationByMenu, loading: false, error: false, errors: null, success: false } };

    default:
      return state;
  }
}

import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import {
  SuperadminchangePassword,
  createPrmDataAction,
  getAgentsData,
  getPrmById,
  updatePrmDataAction,
} from "../../../../Store/Action/CreateAgents/UserAction";
import {
  contactValidation,
  countryCodeValidation,
  countrycodeValidation,
  formatPhoneNumber,
  RESET_ADD_PRM_DATA,
  RESET_SUPERADMIN_TO_ADMIN_CHANGE_PASSWORD,
  Us_Pattern_Number,
  validEmailRegex,
} from "../../../../Store/type/Constant";
import classNames from "classnames";
import MaskedInput from "react-text-mask";
import { toast } from "react-toastify";
import Toaster from "../../../../Components/Toaster/Toaster";
import { Loader } from "rsuite";

const EditPrm = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [btnLoading, setbtnLoading] = useState(false);
  const notifyError = (msg) => toast.error(msg);
  const successTost = (msg) => toast.success(msg);

  const [ShowChangePassword, setShowChangePassword] = useState(false);
  const [visible, setvisible] = useState(false);
  const [Confirmvisible, setConfirmvisible] = useState(false);
  const [state, setState] = useState({
    new_password: "",
    confirm_password: "",
  });

  const [error, setError] = useState({
    new_password: false,
    confirm_password: false,
  });

  const allLocationData = useSelector(
    (state) => state.getAgentsReducer.user.data
  );

  const passwordvisible = () => {
    setvisible(!visible);
  };
  const Confirmpasswordvisible = () => {
    setConfirmvisible(!Confirmvisible);
  };


  const [prmApiData, setprmApiData] = useState({
    name: "",
    email: "",
    password: "",
    location: "",
    number: "",
    countrycode: "",
    status: "active",
  });

  const [errors, setErrors] = useState({
    name: false,
    email: false,
    password: false,
    location: false,
    number: false,
    countrycode: false,
  });

  const [apiData, setapiData] = useState([]);

  useEffect(() => {
    if (props.selectedPrmId) {
      props.getPrmById(props.selectedPrmId);
    }
  }, [props.selectedPrmId]);

  useEffect(() => {
    if (props.getPrmByIdReducer.success) {
      const data = props.getPrmByIdReducer.data.data;
      setprmApiData((prevState) => ({
        name: data[0]?.name,
        email: data[0]?.email,
        location: data[0]?.locationdata[0]?._id,
        number: data[0]?.number ? formatPhoneNumber(data[0]?.number) : "N/A",
        status: data[0]?.status == 1 ? "active" : "Inactive",
      }));
    }
  }, [props.getPrmByIdReducer]);


  var res = prmApiData?.number
    ?.replace(/\D+/g, "")
    .replace(/^(\d{3})(\d{3})(\d{4}).*/, "$1$2$3");

  useEffect(() => {
    if (props.editPrmReducer.success) {
      props.resetprm();
      props.setEditShow(false);
      setbtnLoading(false);
    }
    if (props.editPrmReducer.error) {
      props.resetprm();
      notifyError(props.editPrmReducer.errors);
      setbtnLoading(false);
    }
  }, [props.editPrmReducer]);

  useEffect(() => {
    if (props.SuperadminchangePasswordReducer?.success) {
      successTost("Password changed successfully.");
      BackBack(true);
      setTimeout(() => {
        props.resetchangePassword();
      }, 2200);
    } else if (props.SuperadminchangePasswordReducer.error) {
      notifyError(props.SuperadminchangePasswordReducer.errors);
      props.resetchangePassword();
    }
  }, [props.SuperadminchangePasswordReducer]);

  useEffect(() => {
    dispatch(getAgentsData());
  }, []);

  useEffect(() => {
    setapiData(allLocationData);
  }, [allLocationData]);

  const onChangeInput = (e) => {
    const { value, name } = e.target;
    if (e.target.name === "number") {
      if (e.target.value.length <= 14) {
        setprmApiData((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value.replace(
            /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/
          ),
        }));
      }
    }
    setprmApiData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    switch (name) {
      case "name":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            name: "Division name can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            name: false,
          }));
        }
        break;

      case "email":
        if (e.target.value === "") {
          setErrors((prevState) => ({
            ...prevState,
            email: "Location Email can not be empty",
          }));
        } else if (!validEmailRegex.test(e.target.value)) {
          setErrors((prevState) => ({
            ...prevState,
            email: "Enter valid email",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            email: false,
          }));
        }
        break;

      case "password":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            password: "Division name can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            password: false,
          }));
        }
        break;

      case "confirmpassword":
        if (e.target.value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            confirmpassword: "confirmpassword can not be empty",
          }));
        } else if (prmApiData.password === prmApiData.confirmpassword) {
          setErrors((prevState) => ({
            ...prevState,
            confirmpassword: "confirmpassword can not be match",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            confirmpassword: false,
          }));
        }
        break;

      case "location":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            location: "Division name can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            location: false,
          }));
        }
        break;

      case "number":
        if (e.target.value == "") {
          setErrors((prevState) => ({
            ...prevState,
            number: "number can not be empty",
          }));
        } else if (!Us_Pattern_Number.test(e.target.value)) {
          setErrors((prevState) => ({
            ...prevState,
            number: "Enter valid number",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            number: false,
          }));
        }
        break;

      case "countrycode":
        if (e.target.value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            countrycode: "countrycode can not be empty",
          }));
        } else if (!countryCodeValidation.test(e.target.value)) {
          setErrors((prevState) => ({
            ...prevState,
            countrycode: "countrycode can not be match",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            countrycode: false,
          }));
        }
        break;

      default:
    }
  };

  const onSubmite = (e) => {
    if (prmApiData.location == "") {
      setErrors((prevState) => ({
        ...prevState,
        location: "location can not be empty",
      }));
    }

    if (prmApiData.name == "") {
      setErrors((prevState) => ({
        ...prevState,
        name: "name can not be empty",
      }));
    }

    if (prmApiData.number == "") {
      setErrors((prevState) => ({
        ...prevState,
        number: "number can not be empty",
      }));
    } else if (!Us_Pattern_Number.test(prmApiData.number)) {
      setErrors((prevState) => ({
        ...prevState,
        number: "Enter valid number",
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        number: false,
      }));
    }

    if (prmApiData.email == "") {
      setErrors((prevState) => ({
        ...prevState,
        email: "email can not be empty",
      }));
    }

    if (prmApiData.password == "") {
      setErrors((prevState) => ({
        ...prevState,
        password: "password can not be empty",
      }));
    }

    if (prmApiData.confirmpassword == "") {
      setErrors((prevState) => ({
        ...prevState,
        confirmpassword: "confirmpassword can not be empty",
      }));
    }

    if (prmApiData.password == prmApiData.confirmpassword) {
      setErrors((prevState) => ({
        ...prevState,
        confirmpassword: "password cant not match",
        password: "password can not match",
      }));
    }

    if (prmApiData.countrycode === "") {
      setErrors((prevState) => ({
        ...prevState,
        countrycode: "countrycode can not be empty",
      }));
    }

    if (
      prmApiData?.name !== "" &&
      !errors.number &&
      prmApiData?.number !== ""
    ) {
      const details = {
        name: prmApiData.name,
        status: prmApiData.status === "active" ? 1 : 0,
        email: prmApiData.email,
        location: prmApiData.location,
        number: res,
      };
      props.updatePrmDataAction({
        id: props.selectedPrmId,
        data: details,
      });
      setbtnLoading(true);
      // props.setEditShow(false);
    }
  };

  const Back = () => {
    setShowChangePassword(true);
    setState({
      old_password: "",
      new_password: "",
      confirm_password: "",
    });
  };
  const BackBack = () => {
    setShowChangePassword(false);
  };


  const getValue = (e) => {
    const { name, value } = e.target

    setState(prev => ({
      ...prev,
      [name]: value
    }))

    switch (name) {
      case "new_password":
        if (value === '') {
          error['new_password'] = 'Required'
        } else {
          error['new_password'] = false
        } break;

      case "confirm_password":
        if (value === '') {
          error['confirm_password'] = 'Required'
        } else if (value !== state.new_password) {
          error['confirm_password'] = 'Password not Matched'
        } else {
          error['confirm_password'] = false
        } break;

      default: break
    }
  }

  const savePassword = (e) => {
    e.preventDefault();
    if (state.new_password === "") {
      setError((prevState) => ({
        ...prevState,
        new_password: "Enter new Password",
      }));
    } else if (state.confirm_password === "") {
      setError((prevState) => ({
        ...prevState,
        confirm_password: "Enter confirm new password",
      }));
    } else if (state.new_password !== state.confirm_password) {
      setError((prevState) => ({
        ...prevState,
        confirm_password: "new password and confirm password not match",
      }));
    }

    if (state.new_password !== state.confirm_password) {
      notifyError("Password does not match");
    }

    if (
      // state.old_password !== "" &&
      state.new_password !== "" &&
      state.confirm_password !== "" &&
      // !error.old_password &&
      !error.new_password &&
      !error.confirm_password &&
      state.confirm_password === state.new_password
    ) {
      const details = {
        // current_password: state.old_password,
        new_password: state.new_password,
        confirm_password: state.confirm_password,
        id: props.selectedPrmId,
      };
      props.SuperadminchangePassword(details);
    }
  };
  return (
    <>
      <div>
        {" "}
        <Modal.Header closeButton>
          <Modal.Title>Edit PRM</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-area">
            <Form className="row mx-0">
              <div className="col-6 mt-2">
                <Form.Group className="mb-3">
                  <Form.Label style={{ fontWeight: "bold" }}>
                    Location:
                  </Form.Label>
                  <div className="form-group event-drp ">
                    <select
                      id="location"
                      name="location"
                      onChange={onChangeInput}
                      className={classNames("form-select", {
                        requireField: errors.location,
                      })}
                      value={prmApiData.location}
                      disabled={
                        props?.getPrmByIdReducer?.data?.data[0]?.primary_admin ===
                        1
                      }
                    >
                      <option value="" className="d-none">
                        Select Location *
                      </option>
                      {allLocationData?.map((v, i) => {
                        return (
                          <option key={i} value={v._id}>
                            {v?.location_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {/* <Form.Control value={prmApiData?.location} disabled /> */}
                </Form.Group>
              </div>
              <div className="col-6 mt-2">
                <Form.Group className="mb-3">
                  <Form.Label style={{ fontWeight: "bold" }}>
                    Admin Name:
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter name"
                    name="name"
                    id="name"
                    value={prmApiData?.name}
                    autoComplete="off"
                    className={classNames({
                      requireField: errors.name,
                    })}
                    // label="name"
                    onChange={onChangeInput}
                  />
                  {/* {errors.name && (
                  <span
                    className="text-danger small d-block"
                    style={{ fontWeight: "bold" }}
                  >
                    * {errors.name}
                  </span>
                )} */}
                </Form.Group>
              </div>

              <div className="col-6 mt-2">
                <Form.Group className="mb-3">
                  <Form.Label style={{ fontWeight: "bold" }}>Email Address: </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter Email Address"
                    name="email"
                    id="email"
                    value={prmApiData.email}
                    autoComplete="off"
                    // label="email"
                    // disabled
                    onChange={onChangeInput}
                    className={classNames({
                      requireField: errors.email,
                    })}
                  />
                </Form.Group>
              </div>

              <div className="col-6 mt-2">
                {" "}
                <Form.Group className="">
                  <Form.Label style={{ fontWeight: "bold" }}>
                    Contact No:
                  </Form.Label>
                  <MaskedInput
                    mask={[
                      "(",
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      ")",
                      " ",
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      "-",
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                    ]}
                    name="number"
                    placeholder="Enter Contact No"
                    guide={false}
                    id="my-input-id number"
                    onKeyUp={onChangeInput}
                    value={prmApiData?.number}
                    autoComplete="off"
                    onChange={onChangeInput}
                    className={classNames("form-control mb-3", {
                      requireField: errors.number,
                    })}
                  />
                </Form.Group>
                {/* <div className="col-4"></div> */}
              </div>
              <div className="col-md-3 col-4">
                <Form.Label style={{ fontWeight: "bold" }}>Status:</Form.Label>
                <Form.Select
                  value={prmApiData?.status}
                  onChange={(e) => {
                    setprmApiData((prevState) => ({
                      ...prevState,
                      status: e.target.value,
                    }));
                  }}
                >
                  <option value="active">Active</option>
                  <option value="Inactive">Inactive</option>
                </Form.Select>
              </div>

              {ShowChangePassword && (
                <div className="address-container row w-100 gx-2 mt-3 mx-0 justify-content-between">
                  <h4 className="text-center mb-4">Change Password</h4>
                  <Form.Group className="col-6 position-relative">
                    <Form.Label>New Password:</Form.Label>
                    <Form.Control
                      type={!!visible ? "text" : "password"}
                      placeholder="Enter New Password"
                      name="new_password"
                      value={state.new_password}
                      onChange={getValue}
                      className={classNames("", {
                        requireField: error.new_password,
                      })}
                    />
                    <div onClick={passwordvisible} >
                      {visible ? (
                        <i className="fa fa-eye eye-location pointer fs-5" />
                      ) : (
                        <i className="fa fa-eye-slash eye-location pointer fs-5" />
                      )}
                    </div>
                  </Form.Group>
                  <Form.Group className="col-6 position-relative">
                    <Form.Label>Confirm Password:</Form.Label>
                    <Form.Control
                      type={!!Confirmvisible ? "text" : "password"}
                      name="confirm_password"
                      placeholder="Enter Confirm Password"
                      value={state.confirm_password}
                      onChange={getValue}
                      className={classNames("", {
                        requireField: error.confirm_password,
                      })}
                    />
                    <div onClick={Confirmpasswordvisible}>
                      {Confirmvisible ? (
                        <i className="fa fa-eye eye-location pointer fs-5" />
                      ) : (
                        <i className="fa fa-eye-slash eye-location pointer fs-5" />
                      )}
                    </div>
                  </Form.Group>
                  <div className="text-end">
                    <Button
                      type="button"
                      className="me-2 border rounded pointer px-4 py-2 text-white bg-red mt-4"
                      onClick={savePassword}
                    >
                      Save Password
                    </Button>
                  </div>
                </div>
              )}


            </Form>
          </div>

          <div className="text-end">

            {ShowChangePassword ? (
              <Button
                type="button"
                className="me-2 border rounded pointer px-4 py-2 text-white bg-red mt-4 mb-3"
                onClick={BackBack}
              >
                Back
              </Button>
            ) : (
              <Button
                type="button"
                className="border rounded pointer px-4 py-2 text-white bg-red mt-4 me-2 mb-3"
                onClick={Back}
              >
                Change Password
              </Button>
            )}

            <Button
              type="button"
              className="border rounded pointer px-4 py-2 text-white bg-red mt-4 mb-3"
              onClick={onSubmite}
            >
              {btnLoading ? <Loader /> : "Submit"}
            </Button>
          </div>
        </Modal.Body>
      </div>
      <Toaster />

    </>
  );
};

const mapStateToProp = (state) => ({
  editPrmReducer: state.editPrmReducer,
  getPrmByIdReducer: state.getPrmByIdReducer.getPrmById,
  SuperadminchangePasswordReducer:
    state.SuperadminPasswordReducer.SuperadminchangePassword,
});

const mapDispatchToProps = (dispatch) => ({
  updatePrmDataAction: (Details) => dispatch(updatePrmDataAction(Details)),
  getPrmById: (id) => dispatch(getPrmById(id)),
  resetprm: () => dispatch({ type: RESET_ADD_PRM_DATA }),
  SuperadminchangePassword: (details) =>
    dispatch(SuperadminchangePassword(details)),
  resetchangePassword: () =>
    dispatch({ type: RESET_SUPERADMIN_TO_ADMIN_CHANGE_PASSWORD }),
  // resetEditDivisionReducer: () => dispatch({ type: RESET_EDIT_DIVISION }),
  // getAllDivisionHandler: user => dispatch((user))
});
export default connect(mapStateToProp, mapDispatchToProps)(EditPrm);

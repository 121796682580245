import { ADD_MENU_ERROR, ADD_MENU_LOADING, ADD_MENU_SUCCESS, DELETE_MENU_ERROR, DELETE_MENU_LOADING, DELETE_MENU_SUCCESS, GET_MENU_ERROR, GET_MENU_ID_ERROR, GET_MENU_ID_LOADING, GET_MENU_ID_SUCCESS, GET_MENU_LOADING, GET_MENU_SUCCESS, RESET_ADD_MENU, RESET_DELETE_MENU, RESET_GET_MENU, RESET_GET_MENU_ID, RESET_UPDATE_MENU, UPDATE_MENU_ERROR, UPDATE_MENU_LOADING, UPDATE_MENU_SUCCESS } from "../../../Constants/Constants";

let initialState = {
  getMenuItems: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
    pagination: {},
  },
  getMenuById: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
  updateMenu: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
  deleteMenu: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
  addMenu: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
};

export default function MenuItemsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_MENU_LOADING:
      return { ...state, getMenuItems: { ...state.getMenuItems, loading: true, error: false, errors: [], success: false, pagination: {} } };

    case GET_MENU_SUCCESS:
      return { ...state, getMenuItems: { ...state.getMenuItems, loading: false, error: false, errors: [], success: true, data: action.response.data, pagination: action.response.pagination } };

    case GET_MENU_ERROR:
      return { ...state, getMenuItems: { ...state.getMenuItems, loading: false, error: true, errors: action.errors, success: false, data: null, pagination: {} } };

    case RESET_GET_MENU:
      return { ...state, getMenuItems: { ...state.getMenuItems, loading: false, error: false, errors: null, success: false, pagination: {} } };


    //  get Menu by id
    case GET_MENU_ID_LOADING:
      return { ...state, getMenuById: { ...state.getMenuById, loading: true, error: false, errors: [], success: false } };

    case GET_MENU_ID_SUCCESS:
      return { ...state, getMenuById: { ...state.getMenuById, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case GET_MENU_ID_ERROR:
      return { ...state, getMenuById: { ...state.getMenuById, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_GET_MENU_ID:
      return { ...state, getMenuById: { ...state.getMenuById, loading: false, error: false, errors: null, success: false } };


    //  update Menu by id
    case UPDATE_MENU_LOADING:
      return { ...state, updateMenu: { ...state.updateMenu, loading: true, error: false, errors: [], success: false } };

    case UPDATE_MENU_SUCCESS:
      return { ...state, updateMenu: { ...state.updateMenu, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case UPDATE_MENU_ERROR:
      return { ...state, updateMenu: { ...state.updateMenu, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_UPDATE_MENU:
      return { ...state, updateMenu: { ...state.updateMenu, loading: false, error: false, errors: null, success: false } };


    //  delete Menu by id
    case DELETE_MENU_LOADING:
      return { ...state, deleteMenu: { ...state.deleteMenu, loading: true, error: false, errors: [], success: false } };

    case DELETE_MENU_SUCCESS:
      return { ...state, deleteMenu: { ...state.deleteMenu, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case DELETE_MENU_ERROR:
      return { ...state, deleteMenu: { ...state.deleteMenu, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_DELETE_MENU:
      return { ...state, deleteMenu: { ...state.deleteMenu, loading: false, error: false, errors: null, success: false } };


    //  add menu
    case ADD_MENU_LOADING:
      return { ...state, addMenu: { ...state.addMenu, loading: true, error: false, errors: [], success: false } };

    case ADD_MENU_SUCCESS:
      return { ...state, addMenu: { ...state.addMenu, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case ADD_MENU_ERROR:
      return { ...state, addMenu: { ...state.addMenu, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_ADD_MENU:
      return { ...state, addMenu: { ...state.addMenu, loading: false, error: false, errors: null, success: false } };

    default:
      return state;
  }
}

import {
  AGENTS_CHANGE_PASSWORD_ERROR,
  AGENTS_CHANGE_PASSWORD_LOADING,
  AGENTS_CHANGE_PASSWORD_SUCCESS,
  RESET_AGENTS_CHANGE_PASSWORD,
  RESET_RESET_SUPERADMIN_PASSWORD,
  RESET_SUPERADMIN_CHANGE_PASSWORD,
  RESET_SUPERADMIN_FORGOT_PASSWORD,
  RESET_SUPERADMIN_OTP_VERIFY,
  RESET_SUPERADMIN_PASSWORD_ERROR,
  RESET_SUPERADMIN_PASSWORD_LOADING,
  RESET_SUPERADMIN_PASSWORD_SUCCESS,
  RESET_SUPERADMIN_TO_ADMIN_CHANGE_PASSWORD,
  SUPERADMIN_CHANGE_PASSWORD_ERROR,
  SUPERADMIN_CHANGE_PASSWORD_LOADING,
  SUPERADMIN_CHANGE_PASSWORD_SUCCESS,
  SUPERADMIN_FORGOT_PASSWORD_ERROR,
  SUPERADMIN_FORGOT_PASSWORD_LOADING,
  SUPERADMIN_FORGOT_PASSWORD_SUCCESS,
  SUPERADMIN_OTP_VERIFY_ERROR,
  SUPERADMIN_OTP_VERIFY_LOADING,
  SUPERADMIN_OTP_VERIFY_SUCCESS,
  SUPERADMIN_TO_ADMIN_CHANGE_PASSWORD_ERROR,
  SUPERADMIN_TO_ADMIN_CHANGE_PASSWORD_LOADING,
  SUPERADMIN_TO_ADMIN_CHANGE_PASSWORD_SUCCESS,
} from "../type/Constant";

let initialState = {
  forgotPassword: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
  },
  verifyOtp: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
  },
  resetPassword: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
  },
  changePassword: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
  },
  SuperadminchangePassword: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
  },
  AgentschangePassword: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
  },
};

export default function SuperadminPasswordReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case SUPERADMIN_FORGOT_PASSWORD_LOADING:
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          loading: true,
          error: false,
          errors: [],
          success: false,
        },
      };

    case SUPERADMIN_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          loading: false,
          error: false,
          errors: [],
          success: true,
          data: action.response.data,
        },
      };

    case SUPERADMIN_FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          loading: false,
          error: true,
          errors: action.errors,
          success: false,
          data: null,
        },
      };

    case RESET_SUPERADMIN_FORGOT_PASSWORD:
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          loading: false,
          error: false,
          errors: null,
          success: false,
        },
      };

    // verify otp

    case SUPERADMIN_OTP_VERIFY_LOADING:
      return {
        ...state,
        verifyOtp: {
          ...state.verifyOtp,
          loading: true,
          error: false,
          errors: [],
          success: false,
        },
      };

    case SUPERADMIN_OTP_VERIFY_SUCCESS:
      return {
        ...state,
        verifyOtp: {
          ...state.verifyOtp,
          loading: false,
          error: false,
          errors: [],
          success: true,
          data: action.response.data,
        },
      };

    case SUPERADMIN_OTP_VERIFY_ERROR:
      return {
        ...state,
        verifyOtp: {
          ...state.verifyOtp,
          loading: false,
          error: true,
          errors: action.errors,
          success: false,
          data: null,
        },
      };

    case RESET_SUPERADMIN_OTP_VERIFY:
      return {
        ...state,
        verifyOtp: {
          ...state.verifyOtp,
          loading: false,
          error: false,
          errors: null,
          success: false,
        },
      };

    // reset pass

    case RESET_SUPERADMIN_PASSWORD_LOADING:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          loading: true,
          error: false,
          errors: [],
          success: false,
        },
      };

    case RESET_SUPERADMIN_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          loading: false,
          error: false,
          errors: [],
          success: true,
          data: action.response.data,
        },
      };

    case RESET_SUPERADMIN_PASSWORD_ERROR:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          loading: false,
          error: true,
          errors: action.errors,
          success: false,
          data: null,
        },
      };

    case RESET_RESET_SUPERADMIN_PASSWORD:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          loading: false,
          error: false,
          errors: null,
          success: false,
        },
      };

    // change pass

    case SUPERADMIN_CHANGE_PASSWORD_LOADING:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          loading: true,
          error: false,
          errors: [],
          success: false,
        },
      };

    case SUPERADMIN_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          loading: false,
          error: false,
          errors: [],
          success: true,
          data: action.response.data,
        },
      };

    case SUPERADMIN_CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          loading: false,
          error: true,
          errors: action.errors,
          success: false,
          data: null,
        },
      };

    case RESET_SUPERADMIN_CHANGE_PASSWORD:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          loading: false,
          error: false,
          errors: null,
          success: false,
        },
      };

      // .........Superadmin to admin Password Change.............

      case SUPERADMIN_TO_ADMIN_CHANGE_PASSWORD_LOADING:
        return {
          ...state,
          SuperadminchangePassword: {
            ...state.SuperadminchangePassword,
            loading: true,
            error: false,
            errors: [],
            success: false,
          },
        };
  
      case SUPERADMIN_TO_ADMIN_CHANGE_PASSWORD_SUCCESS:
        return {
          ...state,
          SuperadminchangePassword: {
            ...state.SuperadminchangePassword,
            loading: false,
            error: false,
            errors: [],
            success: true,
            data: action.response.data,
          },
        };
  
      case SUPERADMIN_TO_ADMIN_CHANGE_PASSWORD_ERROR:
        return {
          ...state,
          SuperadminchangePassword: {
            ...state.SuperadminchangePassword,
            loading: false,
            error: true,
            errors: action.errors,
            success: false,
            data: null,
          },
        };
  
      case RESET_SUPERADMIN_TO_ADMIN_CHANGE_PASSWORD:
        return {
          ...state,
          SuperadminchangePassword: {
            ...state.SuperadminchangePassword,
            loading: false,
            error: false,
            errors: null,
            success: false,
          },
        };
      // .........agents Password Change.............

      case AGENTS_CHANGE_PASSWORD_LOADING:
        return {
          ...state,
          AgentschangePassword: {
            ...state.AgentschangePassword,
            loading: true,
            error: false,
            errors: [],
            success: false,
          },
        };
  
      case AGENTS_CHANGE_PASSWORD_SUCCESS:
        return {
          ...state,
          AgentschangePassword: {
            ...state.AgentschangePassword,
            loading: false,
            error: false,
            errors: [],
            success: true,
            data: action.response.data,
          },
        };
  
      case AGENTS_CHANGE_PASSWORD_ERROR:
        return {
          ...state,
          AgentschangePassword: {
            ...state.AgentschangePassword,
            loading: false,
            error: true,
            errors: action.errors,
            success: false,
            data: null,
          },
        };
  
      case RESET_AGENTS_CHANGE_PASSWORD:
        return {
          ...state,
          AgentschangePassword: {
            ...state.AgentschangePassword,
            loading: false,
            error: false,
            errors: null,
            success: false,
          },
        };


    default:
      return state;
  }
}

import axios from "axios";
import {
  createCoustomer_Url,
  deleteCoustomerAddress_Url,
  deleteCoustomer_Url,
  editCoustomer_Url,
  findCustomer_Url,
  getAdminCoustomerOrder_Url,
  getAdminCoustomer_Url,
  getAdminCoustomerbyId_Url,
} from "../../apiSheet";
import {
  CREATE_COUSTOMER_ERROR,
  CREATE_COUSTOMER_LOADING,
  CREATE_COUSTOMER_SUCCESS,
  DELETE_ADMIN_CUSTOMER_ADDRESS_ERROR,
  DELETE_ADMIN_CUSTOMER_ADDRESS_LOADING,
  DELETE_ADMIN_CUSTOMER_ADDRESS_SUCCESS,
  DELETE_COUSTOMER_ERROR,
  DELETE_COUSTOMER_LOADING,
  DELETE_COUSTOMER_SUCCESS,
  EDIT_COUSTOMER_ERROR,
  EDIT_COUSTOMER_LOADING,
  EDIT_COUSTOMER_SUCCESS,
  FIND_CUSTOMER_BY_CONTACT_ERROR,
  FIND_CUSTOMER_BY_CONTACT_LOADING,
  FIND_CUSTOMER_BY_CONTACT_SUCCESS,
  GET_ADMIN_CUSTOMER_DETAILS_ERROR,
  GET_ADMIN_CUSTOMER_DETAILS_LOADING,
  GET_ADMIN_CUSTOMER_DETAILS_SUCCESS,
  GET_ADMIN_CUSTOMER_ERROR,
  GET_ADMIN_CUSTOMER_LOADING,
  GET_ADMIN_CUSTOMER_ORDER_ERROR,
  GET_ADMIN_CUSTOMER_ORDER_LOADING,
  GET_ADMIN_CUSTOMER_ORDER_SUCCESS,
  GET_ADMIN_CUSTOMER_SUCCESS,
} from "../../Constants/Constants";

export const findCustomer = (contactDetails) => {
  return (dispatch) => {
    dispatch({
      type: FIND_CUSTOMER_BY_CONTACT_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${findCustomer_Url}`,
      data: contactDetails,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: FIND_CUSTOMER_BY_CONTACT_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: FIND_CUSTOMER_BY_CONTACT_ERROR,
            netError: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: FIND_CUSTOMER_BY_CONTACT_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const getCoustomerList = (details) => {
  console.log("details", details);
  return (dispatch) => {
    dispatch({
      type: GET_ADMIN_CUSTOMER_LOADING,
    });
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: !!details
        ? `${getAdminCoustomer_Url}?${
            details.sorting && `spend=${details.sorting}`
          }${
            details.startDate &&
            details.endDate &&
            `${details.sorting ? "&" : ""}startDate=${
              details.startDate
            }&endDate=${details.endDate}`
          }`
        : `${getAdminCoustomer_Url}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_ADMIN_CUSTOMER_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_ADMIN_CUSTOMER_ERROR,
            netError: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: GET_ADMIN_CUSTOMER_ERROR,
            errors: validationError,
          });
        }
      });
  };
};
export const getCoustomerById = (details) => {
  return (dispatch) => {
    dispatch({
      type: GET_ADMIN_CUSTOMER_DETAILS_LOADING,
    });
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${getAdminCoustomerbyId_Url}/${details.id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_ADMIN_CUSTOMER_DETAILS_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_ADMIN_CUSTOMER_DETAILS_ERROR,
            netError: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: GET_ADMIN_CUSTOMER_DETAILS_ERROR,
            errors: validationError,
          });
        }
      });
  };
};
export const getCoustomerOrder = (details) => {
  return (dispatch) => {
    dispatch({
      type: GET_ADMIN_CUSTOMER_ORDER_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${getAdminCoustomerOrder_Url}/${details.id}`,
      ...(!!details.data && {
        data: details.data,
      }),
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_ADMIN_CUSTOMER_ORDER_SUCCESS,
          response: {
            data: resp.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_ADMIN_CUSTOMER_ORDER_ERROR,
            netError: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: GET_ADMIN_CUSTOMER_ORDER_ERROR,
            errors: validationError,
          });
        }
      });
  };
};
export const deleteCoustomerAddress = (id) => {
  return (dispatch) => {
    dispatch({
      type: DELETE_ADMIN_CUSTOMER_ADDRESS_LOADING,
    });
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${deleteCoustomerAddress_Url}/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: DELETE_ADMIN_CUSTOMER_ADDRESS_SUCCESS,
          response: {
            data: resp.data,
            msg: resp.data.message,
            flag: resp.data.address_deleted,
          },
        });
      })
      .catch((error) => {
        console.log("error", error);
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: DELETE_ADMIN_CUSTOMER_ADDRESS_ERROR,
            netError: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: DELETE_ADMIN_CUSTOMER_ADDRESS_ERROR,
            errors: validationError,
            flag: data?.data?.address_deleted,
          });
        }
      });
  };
};
export const editCoustomer = (details) => {
  return (dispatch) => {
    dispatch({
      type: EDIT_COUSTOMER_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${editCoustomer_Url}/${details.id}`,
      data: details.details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: EDIT_COUSTOMER_SUCCESS,
          response: {
            data: resp.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        console.log("error", error);
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: EDIT_COUSTOMER_ERROR,
            netError: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: EDIT_COUSTOMER_ERROR,
            errors: validationError,
          });
        }
      });
  };
};
export const deleteCoustomer = (id) => {
  return (dispatch) => {
    dispatch({
      type: DELETE_COUSTOMER_LOADING,
    });
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${deleteCoustomer_Url}/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: DELETE_COUSTOMER_SUCCESS,
          response: {
            data: resp.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        console.log("error", error);
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: DELETE_COUSTOMER_ERROR,
            netError: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: DELETE_COUSTOMER_ERROR,
            errors: validationError,
          });
        }
      });
  };
};
export const createAdminCoustomer = (details) => {
  return (dispatch) => {
    dispatch({
      type: CREATE_COUSTOMER_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${createCoustomer_Url}`,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: CREATE_COUSTOMER_SUCCESS,
          response: {
            data: resp.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        console.log("error", error);
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: CREATE_COUSTOMER_ERROR,
            netError: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: CREATE_COUSTOMER_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

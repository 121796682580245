import {
  ADD_PRM_DATA_ERROR,
  ADD_PRM_DATA_LOADING,
  ADD_PRM_DATA_SUCCESS,
  RESET_ADD_PRM_DATA,
} from "../../Store/type/Constant";

let initialState = {
  loading: false,
  user: [{}],
  error: null,
  errors: null,
  success: false,
  payload: {},
};

export default function createPrmReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_PRM_DATA_LOADING:
      return { ...state, error: false, loading: true, success: false };

    case ADD_PRM_DATA_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        success: true,
        message: action.msg,
      };

    case ADD_PRM_DATA_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        success: false,
        errors: action.errors,
      };

    case RESET_ADD_PRM_DATA:
      return {
        loading: false,
        error: null,
        errors: null,
        success: false,
      };

    default:
      return state;
  }
}

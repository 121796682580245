import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { addMenuItem } from "../../Services/Actions/menuAction";
import classNames from "classnames";
import $ from "jquery";
import { imageValidation } from "../../Constants/Constants";
import { toast } from "react-toastify";
import { Loader } from "rsuite";

function AddmenuModal(props) {
  const notifyError = (msg) => toast.error(msg);
  const [btnLoading, setbtnLoading] = useState(false);
  const [prevImg, setPrevImg] = useState();
  const [state, setState] = useState({
    // itemImage: "",
    itemName: "",
    itemPrice: "",
    note: "",
    status: "active",
    isPizza: false,
  });

  const [error, setError] = useState({
    // itemImage: false,
    itemName: false,
    itemPrice: false,
    note: false,
  });

  // const [Image, setImage] = useState(null);

  // useEffect(() => {
  //   $(".menuImage")?.dropify();
  // }, []);

  useEffect(() => {
    if (props.addMenuData.success) {
      setbtnLoading(false);
    }
  }, [props.addMenuData]);

  const handleInput = (e) => {
    const { name, value } = e.target;
    // if (name === "itemImage") {
    //   setState((prevState) => ({
    //     ...prevState,
    //     [name]: value,
    //   }));
    //   setImage(e.target.files[0]);
    //   setPrevImg(URL.createObjectURL(e.target.files[0]));
    // }

    if (e.target.name === "note") {
      if (e.target.value.length <= 256) {
        setState((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
        }));
      } else return;
    }

    if (name === "itemPrice") {
      if (value.length <= 10) {
        setState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else return;
    }
    if (name === "itemName") {
      if (value.length <= 65) {
        setState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else return;
    } else {
      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

    // var fileName = document.getElementById("itemImage").value;
    // var idxDot = fileName.lastIndexOf(".") + 1;
    // var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    // if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
    // } else {
    //   alert("Only jpg/jpeg and png files are allowed!");
    // }

    switch (name) {
      case "itemName":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            itemName: "Enter Item Name",
          }));
        }
        if (value.length >= 256) {
          setError((prevState) => ({
            ...prevState,
            itemName: "Enter Only 256 Characters",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            itemName: false,
          }));
        }
        break;
      case "itemPrice":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            itemPrice: "Enter Item Price",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            itemPrice: false,
          }));
        }
        break;

      // case "itemImage":
      //   if (value === "") {
      //     setError((prevState) => ({
      //       ...prevState,
      //       itemImage: "Enter Item Price",
      //     }));
      //   } else if (!RegExp(/^image/gim).test(e.target.files[0].type)) {
      //     setError((prevState) => ({
      //       ...prevState,
      //       itemImage: "Only accept image file",
      //     }));
      //   } else {
      //     setError((prevState) => ({
      //       ...prevState,
      //       itemImage: false,
      //     }));
      //   }
      //   break;

      case "note":
        if (value.length >= 256) {
          setError((prevState) => ({
            ...prevState,
            note: "Enter Only 256 Characters",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            note: false,
          }));
        }
        break;

      default:
        break;
    }
  };

  const handleCancel = () => {
    props.setIsAddMenu(false);
  };

  const handleAddItem = () => {
    if (state.itemName === "") {
      setError((prevState) => ({
        ...prevState,
        itemName: "Enter item name",
      }));
    }
    if (state.itemPrice === "") {
      setError((prevState) => ({
        ...prevState,
        itemPrice: "Enter item price",
      }));
    }
    // if (state.itemImage === "") {
    //   setError((prevState) => ({
    //     ...prevState,
    //     itemImage: "Enter item price",
    //   }));
    // }

    if (
      state.itemName !== "" &&
      // state.itemImage !== "" &&
      state.itemPrice !== "" &&
      !error.itemName &&
      !error.itemPrice &&
      // !error.itemImage &&
      !error.note
    ) {
      const details = {
        item_name: state.itemName,
        price: parseFloat(state.itemPrice).toFixed(2),
        note: state.note,
        // image: Image,
        is_pizza: state.isPizza ? "1" : "0",
        status: state.status === "active" ? "1" : "0",
        type: "2",
      };

      props.addMenuItem(details);
      props.setIsAddMenu(false);
      setbtnLoading(true);
    }
    // if (error.itemImage) {
    //   notifyError("Only accept image file");
    // }
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Create Menu Items</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="row form-area">
          <div className="">
            <div className="row">
              <Form.Group className="mt-4 col-6">
                <Form.Label style={{ fontWeight: "bold" }}>
                  Item Name:
                </Form.Label>
                <Form.Control
                  name="itemName"
                  type="text"
                  placeholder="Enter Item Name"
                  value={state.itemName}
                  onChange={handleInput}
                  className={classNames({
                    requireField: error.itemName,
                  })}
                />
              </Form.Group>
              <Form.Group className="mt-4 col-6">
                <Form.Label style={{ fontWeight: "bold" }}>Price:</Form.Label>
                <Form.Control
                  name="itemPrice"
                  type="number"
                  placeholder="Enter Item Price"
                  value={state.itemPrice}
                  onChange={handleInput}
                  className={classNames({
                    requireField: error.itemPrice,
                  })}
                />
              </Form.Group>
            </div>

            {/* <Form.Group className="mt-4">
              <Form.Label style={{ fontWeight: "bold" }}>Image:</Form.Label>
              <div className="custom-file-upload">
                <input
                  type="file"
                  name="itemImage"
                  id="itemImage"
                  accept="image/*"
                  label="image"
                  onChange={handleInput}
                  className={classNames({
                    requireField: error.image,
                  })}
                />
                <label htmlFor="itemImage">
                  {prevImg ? (
                    <div className="uploader-prev">
                      <img
                        className="img-fluid"
                        alt="droped image"
                        src={prevImg}
                      />
                      <span className="replace-btn">Replace</span>
                    </div>
                  ) : (
                    <div className="upload-placeholder text-center">
                      <i
                        className="fa fa-cloud-upload fa-3x"
                        aria-hidden="true"
                      ></i>
                      <h3>Upload File</h3>
                    </div>
                  )}
                </label>
              </div>
            </Form.Group> */}

            <Form.Group className="mt-4">
              <div className="row">
                <div className="col-12 col-md-3">
                  <Form.Label
                    style={{ fontWeight: "bold" }}
                    className="d-block"
                  >
                    Is Pizza?
                  </Form.Label>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    checked={state.isPizza}
                    className={"pizzaBtn ms-0"}
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        isPizza: e.target.checked,
                      }))
                    }
                  />
                </div>
                <div className="col-md-3 col-4">
                  <Form.Label style={{ fontWeight: "bold" }}>
                    Status:
                  </Form.Label>
                  <Form.Select
                    value={state.status}
                    onChange={(e) => {
                      setState((prevState) => ({
                        ...prevState,
                        status: e.target.value,
                      }));
                    }}
                  >
                    <option value="active">Active</option>
                    <option value="Inactive">Inactive</option>
                  </Form.Select>
                </div>
              </div>
              <div className="col-12 mt-2">
                <Form.Group className="mb-3 ">
                  <Form.Label>Note:</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    type="text"
                    placeholder="Enter Note"
                    name="note"
                    label="note"
                    id="note"
                    value={state?.note}
                    onChange={handleInput}
                    className={classNames("mb-3", {
                      requireField: error.note,
                    })}
                  />
                </Form.Group>
              </div>
            </Form.Group>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer className="mt-3">
        {/* <div className='py-2 px-4 border rounded pointer bg-secondary text-white mx-2' onClick={handleCancel}>Cancel</div> */}
        <div
          className="py-2 px-4 border rounded pointer text-white bg-red mx-2"
          onClick={handleAddItem}
        >
          {btnLoading ? <Loader /> : "Submit"}
        </div>
      </Modal.Footer>
    </>
  );
}

const mapStateToProp = (state) => ({
  addMenuData: state.MenuItemsReducer.addMenu,
});

const mapDispatchToProps = (dispatch) => ({
  addMenuItem: (details) => dispatch(addMenuItem(details)),
});

export default connect(mapStateToProp, mapDispatchToProps)(AddmenuModal);

import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Toast } from "react-bootstrap";
import classNames from "classnames";
import { connect } from "react-redux";
import {
  getAgentById,
  updateAgent,
} from "../../Services/Actions/deliveryAgentAction";
import {
  contactValidation,
  countryCodeValidation,
  validEmailRegex,
  validPassword,
} from "../../Constants/Constants";
import { toast } from "react-toastify";
import Toaster from "../../../Components/Toaster/Toaster";
import {
  Us_Pattern_Number,
  formatPhoneNumber,
} from "../../../Store/type/Constant";
import MaskedInput from "react-text-mask";
import { Loader } from "rsuite";

function UpdateAgent(props) {
  const notifyError = (msg) => toast.error(msg);
  const [btnLoading, setbtnLoading] = useState(false);
  const [state, setState] = useState({
    agentName: "",
    contactNo: "",
    emailId: "",
    password: "",
    countryCode: "91",
    confirmPassword: "",
  });
  const [error, setError] = useState({
    agentName: false,
    contactNo: false,
    emailId: false,
    password: false,
    countryCode: false,
    confirmPassword: false,
  });

  var res = state?.contactNo
    ?.replace(/\D+/g, "")
    .replace(/^(\d{3})(\d{3})(\d{4}).*/, "$1$2$3");

  useEffect(() => {
    if (props.updatesAgentId) {
      props.getAgentById(props.updatesAgentId);
    }
  }, [props.updatesAgentId]);

  useEffect(() => {
    if (props.updateAgent.success) {
      setbtnLoading(false);
    }
  }, [props.updateAgent]);

  useEffect(() => {
    if (props.getAgentByIdReducer.success) {
      const data = props.getAgentByIdReducer.data;
      setState((prevState) => ({
        ...prevState,
        agentName: data.name,
        contactNo: data?.number ? formatPhoneNumber(data?.number) : "N/A",
        emailId: data.email,
        password: data.password,
        confirmPassword: data.password,
        countryCode: data.countrycode,
        status: data?.status == 1 ? "active" : "Inactive",
      }));
    }
  }, [props.getAgentByIdReducer]);

  const handleInput = (e) => {
    const { name, value } = e.target;

    if (name === "countryCode") {
      if (value.length <= 4) {
        setState((prevState) => ({
          ...prevState,
          countryCode: value === "+" ? parseInt("0") : parseInt(value),
        }));
      } else return;
    }
    if (e.target.name === "contactNo") {
      if (e.target.value.length <= 14) {
        setState((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value.replace(
            /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/
          ),
        }));
      }
    }
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    switch (name) {
      case "agentName":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            agentName: "Enter Agent name",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            agentName: false,
          }));
        }
        break;

      case "contactNo":
        if (e.target.value.length == 0) {
          setError((prevState) => ({
            ...prevState,
            contactNo: "contactNo can not be empty",
          }));
        } else if (!Us_Pattern_Number.test(e.target.value)) {
          setError((prevState) => ({
            ...prevState,
            contactNo: "Enter valid contactNo",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            contactNo: false,
          }));
        }
        break;

      case "emailId":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            emailId: "Enter Agent Email Address",
          }));
        } else if (!validEmailRegex.test(value)) {
          setError((prevState) => ({
            ...prevState,
            emailId: "Enter Valid Email",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            emailId: false,
          }));
        }
        break;

      case "password":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            password: "Enter Agent password",
          }));
        } else if (!validPassword.test(value)) {
          setError((prevState) => ({
            ...prevState,
            password: "Enter Valid Email",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            password: false,
          }));
        }
        break;

      case "countryCode":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            countryCode: "Enter Country code",
          }));
        } else if (!countryCodeValidation.test(value)) {
          setError((prevState) => ({
            ...prevState,
            countryCode: "Enter country code",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            countryCode: false,
          }));
        }
        break;

      case "confirmPassword":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            confirmPassword: "Enter Confirm password",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            confirmPassword: false,
          }));
        }
        break;

      default:
        break;
    }
  };

  const handleCancel = () => {
    props.setIsUpdateAgent(false);
    // props.setIsAgent(false)
  };

  const handleUpdateAgent = () => {
    if (state.agentName === "") {
      setError((prevState) => ({
        ...prevState,
        agentName: "Enter Agent name",
      }));
    }
    if (state.contactNo == "") {
      setError((prevState) => ({
        ...prevState,
        contactNo: "contactNo can not be empty",
      }));
    } else if (!Us_Pattern_Number.test(state.contactNo)) {
      setError((prevState) => ({
        ...prevState,
        contactNo: "Enter valid contactNo",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        contactNo: false,
      }));
    }
    if (state.emailId === "") {
      setError((prevState) => ({
        ...prevState,
        emailId: "Enter email",
      }));
    }
    if (state.password === "") {
      setError((prevState) => ({
        ...prevState,
        password: "Enter password",
      }));
    }
    if (state.confirmPass === "") {
      setError((prevState) => ({
        ...prevState,
        confirmPass: "Enter Confirm password",
      }));
    }
    if (state.countryCode === "") {
      setError((prevState) => ({
        ...prevState,
        countryCode: "Enter Country code",
      }));
    }
    if (state.confirmPassword === "") {
      setError((prevState) => ({
        ...prevState,
        confirmPassword: "Enter Confirm password",
      }));
    }

    if (state.confirmPassword !== state.password && error.contactNo) {
      notifyError("Password and Confirm Password noy match");
    } else {
      if (
        state.agentName !== "" &&
        state.contactNo !== "" &&
        state.emailId !== "" &&
        state.password !== "" &&
        state.confirmPass !== "" &&
        state.countryCode !== "" &&
        !error.agentName &&
        !error.contactNo &&
        !error.emailId &&
        !error.password &&
        !error.confirmPass &&
        !error.countryCode
      ) {
        // call add function
        const details = {
          name: state.agentName,
          email: state.emailId,
          number: res,
          password: state.password,
          countrycode: state.countryCode,
          type: "2",
          status: state.status === "active" ? 1 : 0,
        };
        props.updateAgent({
          id: props.updatesAgentId,
          data: details,
        });
        props.setIsUpdateAgent(false);
        setbtnLoading(true);
      }
    }
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Update Agent</Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
        {" "}
        <Form className="row">
          <div className="col-6">
            <Form.Group className="mb-3">
              <Form.Label style={{ fontWeight: "bold" }}>
                Driver Name:
              </Form.Label>
              <Form.Control
                name="agentName"
                type="text"
                placeholder="Enter Agent Name"
                value={state.agentName}
                onChange={handleInput}
                className={classNames({
                  requireField: error.agentName,
                })}
              />
            </Form.Group>
          </div>
          <div className="col-6">
            <Form.Group className="mb-3">
              <Form.Label style={{ fontWeight: "bold" }}>
                Email Address:
              </Form.Label>
              <Form.Control
                name="emailId"
                type="text"
                placeholder="Enter Email Address"
                value={state.emailId}
                // disabled
                onChange={handleInput}
                className={classNames({
                  requireField: error.emailId,
                })}
              />
            </Form.Group>
          </div>

          <div className="col-6">
            <Form.Group className="mt-4">
              <Form.Label style={{ fontWeight: "bold" }}>
                Contact No:
              </Form.Label>
              <MaskedInput
                mask={[
                  "(",
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                  ")",
                  " ",
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                  "-",
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                ]}
                name="contactNo"
                placeholder="Enter Contact No"
                guide={false}
                id="my-input-id number"
                value={state.contactNo}
                onChange={handleInput}
                className={classNames("form-control", {
                  requireField: error.contactNo,
                })}
              />
            </Form.Group>
          </div>
          <div className="col-md-3 col-4 mt-4">
            <Form.Label style={{ fontWeight: "bold" }}>Status:</Form.Label>
            <Form.Select
              value={state?.status}
              onChange={(e) => {
                setState((prevState) => ({
                  ...prevState,
                  status: e.target.value,
                }));
              }}
            >
              <option value="active">Active</option>
              <option value="Inactive">Inactive</option>
            </Form.Select>
          </div>
        </Form>
        <div className="text-end mt-3">
          <Button
            type="button"
            className="border rounded pointer px-4 py-2 text-white bg-red mt-4"
            onClick={handleUpdateAgent}
          >
            {btnLoading ? <Loader /> : "Submit"}
          </Button>
        </div>
      </Modal.Body>
      <Toaster />
    </>
  );
}

const mapStateToProp = (state) => ({
  getAgentByIdReducer: state.DeliveryAgentReducer.getAgentById,
  updateAgent: state.DeliveryAgentReducer.updateAgent,
});

const mapDispatchToProps = (dispatch) => ({
  getAgentById: (id) => dispatch(getAgentById(id)),
  updateAgent: (details) => dispatch(updateAgent(details)),
});

export default connect(mapStateToProp, mapDispatchToProps)(UpdateAgent);

import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  UpdateOrderAction,
  getLocationByMenuDataAction,
  getOrderDataAction,
  getOrderFilterAction,
} from "../../../Store/Action/CreateAgents/UserAction";
import {
  RESET_UPDATE_SUPER_ADMIN_ORDER,
  formatPhoneNumber,
  getDateWithPrefix,
  monmthNames,
} from "../../../Store/type/Constant";
import Additem from "./Additem";
import classNames from "classnames";
import { toast } from "react-toastify";
import Toaster from "../../../Components/Toaster/Toaster";
import { Loader } from "rsuite";

const OrderDetails = (props) => {
  const [uploadeLoading, setUpdateLoading] = useState(false);
  const [show1, setShow1] = useState(false);
  const [imageLoading, setImageLoading] = useState(true);
  const [editItems, seteditItems] = useState(false);
  const [isAddItem, setIsAddItem] = useState(false);
  const [addedItem, setAddedItem] = useState([]);
  const [chepestPizzaItem, setChepestPizzaItem] = useState(null);
  const [locationSalestax, setlocationSalestax] = useState();
  const [totlaPizzaItem, setTotalPizzaItem] = useState(0);
  const [freePizza, setFreePizza] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedTax, setSelectedTax] = useState(null);

  const handleCloseDocument = () => {
    setShow1(false);
    setImageLoading(true);
  };
  const handleShowDocument = () => setShow1(true);

  const handleEditItemsClose = () => seteditItems(false);
  const handleEditItemsShow = () => seteditItems(true);

  const dispatch = useDispatch();

  const handleClose = () => {
    props.setEditShow(false);
  };

  const allorder = useSelector(
    (state) => state.SuperAdminOrderReducer.orderfilterSuperadmin.data
  );
  // console.log(props.SelectedOrderDetailsId, allorder);
  const allOrderID = allorder?.find(
    (v) => v?._id == props.SelectedOrderDetailsId
  );
  // console.log("allOrderID", allOrderID, allorder);
  const [error, setError] = useState({
    discount: false,
  });

  const [tax, setTax] = useState({
    total: 0,
    sales_tax: 0,
    service_fees: 0,
    delivery_fees: 0,
    manual_discount: 0,
    discount: 0,
    tip: 0,
    prm_amount: 0,
    total_tipamount: 0,
    subTotal: 0,
    grandTotal: 0,
  });

  const handleConfigInput = (e, index) => {
    const { name, value } = e.target;
    if (name === "manual_discount") {
      setTax((preve) => ({
        ...preve,
        manual_discount:
          parseFloat(value) === "" ? 0 : Number(value).toString(),
      }));

      if (value <= 0) {
        setError((preve) => ({
          ...preve,
          manual_discount: "Enter manual_discount",
        }));
      } else if (value > tax.total) {
        setError((preve) => ({
          ...preve,
          manual_discount: "Enter valid discount",
        }));
      } else if (value > 0) {
        setError((preve) => ({
          ...preve,
          manual_discount: false,
        }));
      }
    }
  };

  useEffect(() => {
    if (!!allOrderID?.location_id) {
      dispatch(getLocationByMenuDataAction(allOrderID?.location_id));
    }
  }, [allOrderID?.location_id]);

  // useEffect(() => {
  //   props.getOrderFilterAction();
  // }, []);

  useEffect(() => {
    props.getOrderDataAction();
  }, []);

  useEffect(() => {
    if (props.getLocationByMenuReducer?.success) {
      const list =
        props.getLocationByMenuReducer.data[0]?.locationdata[0]?.salestax;
      setlocationSalestax(list);
    }
  }, [props.getLocationByMenuReducer]);

  useEffect(() => {
    if (props.editOrderReducer?.success) {
      toast.success("Order is updated successfully.");
      props.getOrderDataAction();
      // props.getOrderFilterAction();
      props.resetUpdateOrder();
      handleEditItemsClose();
      setUpdateLoading(false);
    } else if (props.editOrderReducer?.error) {
      toast.error(props.editOrderReducer?.errors);
      props.resetUpdateOrder();
    }
  }, [props.editOrderReducer]);

  useEffect(() => {
    const filterData = addedItem?.filter(
      (e) => parseFloat(e.status) === 1 && e.is_pizza === 1
    );
    if (filterData?.length !== 0) {
      const cheapestItem = filterData?.reduce((a, b) => {
        return a.price < b.price ? a : b;
      });
      setChepestPizzaItem({ ...cheapestItem, quantity: 1 });
    } else {
      const cheapestItem = null;
      setChepestPizzaItem({ ...cheapestItem, quantity: 1 });
    }
  }, [addedItem]);

  useEffect(() => {
    if (!!locationSalestax) {
      const totalPizzaQuentity = addedItem
        ?.filter((e) => e.is_pizza == 1)
        ?.map((e) => e.quantity);
      setTotalPizzaItem(totalPizzaQuentity?.reduce((a, b) => a + b, 0));
      const totalAmount = addedItem
        ?.filter((e) => parseFloat(e.isfree) !== 1)
        ?.map((e) => {
          return e.price * e.quantity;
        })
        ?.reduce((a, b) => a + b, 0);
    }
  }, [addedItem, locationSalestax]);

  useEffect(() => {
    if (props.orderfilterSuperadmin?.success) {
      const data = props.orderfilterSuperadmin?.data?.find(
        (e, i) => e._id === props.SelectedOrderDetailsId
      );
      const list = data?.menu
        ?.filter((e) => {
          return e?.isfree !== 1;
        })
        .map((e) => {
          return {
            ...e,
            image: e?.itemmenu[0]?.image,
            is_pizza: e?.itemmenu[0]?.is_pizza,
            item_name: e?.itemmenu[0]?.item_name,
            location: e?.itemmenu[0]?.location,
            price: e?.itemmenu[0]?.price,
            menu_item_id: e?.menu_item_id,
          };
        });
      setSelectedOrder(list);
      setAddedItem(list);
      setSelectedTax({
        total: data?.amount,
        manual_discount: data?.manual_discount,
        subTotal: data?.subtotal,
        grandTotal: data?.grandtotal,
        // sales_tax:
      });
      setTax((preve) => ({
        ...preve,
        total: data?.amount,
        manual_discount: data?.manual_discount,
        subTotal: data?.subtotal,
        grandTotal: data?.grandtotal,
      }));
    }
  }, [props.orderfilterSuperadmin]);

  // useEffect(() => {
  //   setTax((preve) => ({
  //     ...preve,
  //     total: tax?.total,
  //     // manual_discount: tax.total - tax?.manual_discount,
  //     subTotal: tax?.subTotal - tax.manual_discount,
  //     grandTotal: tax?.grandTotal - tax.subTotal,
  //   }));
  // }, [tax.manual_discount]);

  const handleRemoveItem = (id) => {
    const list = addedItem.map((e) => {
      if (e._id === id) {
        return {
          ...e,
          quantity: e.quantity - 1 < 0 ? 0 : e.quantity - 1,
        };
      } else return e;
    });
    // const _list = list?.filter((e) => parseFloat(e.quantity) !== 0);
    setAddedItem([...list]);
  };

  const handlecount = (id, value) => {
    const list = addedItem?.map((e) => {
      if (e._id === id) {
        const quentity = selectedOrder.find((c) => c._id == id)?.quantity;
        return {
          ...e,
          quantity:
            value !== ""
              ? parseInt(value) < parseInt(quentity)
                ? parseInt(value)
                : parseInt(quentity)
              : 0,
        };
      } else return e;
    });
    setAddedItem([...list]);
  };

  useEffect(() => {
    const total_amount = addedItem?.map((e) => {
      return (parseFloat(e.price) * parseFloat(e.quantity)).toFixed(2);
    });
    const count_total_amount =
      total_amount?.length !== 0
        ? total_amount?.reduce((a, b) => parseFloat(a) + parseFloat(b))
        : 0;

    const subTotal = (
      parseFloat(count_total_amount) -
      parseFloat(tax.manual_discount) +
      parseFloat(allOrderID?.saletaxamount)
    ).toFixed(2);

    const grandTotal = (
      parseFloat(subTotal) +
      parseFloat(allOrderID?.servicevalue) +
      parseFloat(allOrderID?.deliveryvalue) +
      parseFloat(allOrderID?.totaltipamount)
    ).toFixed(2);
    setTax((prev) => ({
      ...prev,
      total: count_total_amount,
      subTotal: subTotal,
      grandTotal: grandTotal,
    }));
  }, [addedItem]);

  useEffect(() => {
    const subTotal = (
      parseFloat(tax.total) -
      parseFloat(tax.manual_discount) +
      parseFloat(allOrderID?.saletaxamount)
    ).toFixed(2);

    const grandTotal = (
      parseFloat(subTotal) +
      parseFloat(allOrderID?.servicevalue) +
      parseFloat(allOrderID?.deliveryvalue) +
      parseFloat(allOrderID?.totaltipamount)
    ).toFixed(2);

    setTax((prev) => ({
      ...prev,
      subTotal: subTotal,
      grandTotal: grandTotal,
    }));
  }, [tax.manual_discount]);

  useEffect(() => {
    const filterData = addedItem?.filter(
      (e) => parseFloat(e.status) === 1 && e.is_pizza === 1
    );
    if (filterData?.length !== 0) {
      const cheapestItem = filterData?.reduce((a, b) => {
        return a.price < b.price ? a : b;
      });
      setChepestPizzaItem({ ...cheapestItem, quantity: 1 });
    } else {
      const cheapestItem = null;
      setChepestPizzaItem({ ...cheapestItem, quantity: 1 });
    }
  }, [addedItem]);

  useEffect(() => {
    const filterData = addedItem?.filter(
      (e) => parseFloat(e.status) === 1 && e.is_pizza === 1
    );
    if (filterData?.length !== 0) {
      const cheapestItem = filterData?.reduce((a, b) => {
        return a.price < b.price ? a : b;
      });
      setChepestPizzaItem({ ...cheapestItem, quantity: 1 });
    } else {
      const cheapestItem = null;
      setChepestPizzaItem({ ...cheapestItem, quantity: 1 });
    }
  }, [addedItem]);

  const handleCreate = () => {
    if (tax?.total !== 0) {
      const selectedItem = !!freePizza
        ? [...addedItem, { ...freePizza, isfree: "1" }]
        : [...addedItem];
      const details = {
        name: allOrderID?.address[0]?.name,
        address1: allOrderID?.address[0]?.address1,
        address2: allOrderID?.address[0]?.address2,
        customer_name: allOrderID?.customers[0]?.name,
        address_id: allOrderID?.address_id,
        agent_id: allOrderID?.agent_id,
        email: allOrderID?.customers[0]?.email,
        number: allOrderID?.customers[0]?.number,
        countrycode: allOrderID?.address[0]?.pincode,
        leavetimestring: allOrderID?.leavetimestring,
        deliverytimestring: allOrderID?.deliverytimestring,
        leavetime: allOrderID?.leavetime,
        deliverytime: allOrderID?.deliverytime,
        date: allOrderID?.deliveryDateUnform,
        deliveryDateFrom: allOrderID?.deliveryDateFrom,
        deliveryDateUnform: allOrderID?.deliveryDateUnform,
        dob: allOrderID?.customers[0]?.dob,
        additem: selectedItem.map((e) => {
          return {
            ...e,
            menu_item_id: e.menu_item_id,
          };
        }),
        amount: parseFloat(tax.total).toFixed(2),
        subtotal: parseFloat(tax.subTotal)?.toFixed(2),
        saletaxamount: allOrderID?.saletaxamount,
        grandtotal: parseFloat(tax.grandTotal).toFixed(2),
        discount: parseFloat(allOrderID?.discount).toFixed(2),
        deliveryvalue: parseFloat(allOrderID?.deliveryvalue).toFixed(2),
        manual_discount: parseFloat(tax.manual_discount).toFixed(2),
        servicefees: parseFloat(allOrderID?.servicefees).toFixed(2),
        servicevalue: parseFloat(allOrderID?.servicevalue).toFixed(2),
        tipvalue: parseFloat(allOrderID?.tipamount).toFixed(2),
        agent_id: allOrderID.agent_id,
        leavetime: allOrderID?.leavetime,
        deliverytime: allOrderID?.deliverytime,
        pincode: allOrderID?.address[0]?.pincode,
        state: allOrderID?.address[0]?.state,
        city: allOrderID?.address[0]?.city,
        customer_id: allOrderID?.customer_id,
        prmamount: allOrderID?.prmamount,
        location: allOrderID?.locationdata[0]?._id,
        type: "1",
        note: allOrderID?.customers[0]?.note,
        totaltipArray: allOrderID?.Tipamounts,
        totaltipamount: allOrderID?.total_tipamount,
        ezCater: allOrderID?.ezCater,
        tipAmountFlag: allOrderID?.tipAmountFlag,
        createdate: allOrderID?.createdate,
        createdDateUnform: allOrderID?.createdDateUnform,
      };
      props.UpdateOrderAction({
        details: details,
        id: props.SelectedOrderDetailsId,
      });
      setUpdateLoading(true);
    } else {
      let errorList = [];
      if (tax?.total == 0) {
        errorList = [...errorList, "Please add atleast one item"];
      }

      toast.error(() => {
        return errorList.length > 1 ? (
          <ul>
            {errorList.map((e, i) => {
              return <li key={i}>{e}</li>;
            })}
          </ul>
        ) : (
          <>
            {errorList.map((e, i) => {
              return <div key={i}>{e}</div>;
            })}
          </>
        );
      });
    }
  };

  return (
    <>
      <Modal.Header
        closeButton
        style={{
          background: `linear-gradient(90deg, ${allOrderID?.locationdata[0]?.color}, #ffffff)`,
        }}
      >
        <h4 className="me-2 text-light">Order Details</h4>{" "}
        <div className="fs-5">
          <span className="text-light">|</span>
        </div>
        <h4 className="ms-2 text-light">
          {" "}
          {allOrderID?.locationdata[0]?.location_name}
        </h4>
      </Modal.Header>
      <Modal.Body>
        {!!allOrderID && (
          <>
            <div className="p-3">
              <div className="row">
                <h4 className="border-bottom mb-2 col-7">Order ID </h4>
                <h4 className="border-bottom mb-2 col">Order Status </h4>
              </div>
              <div className="row">
                <h5 className="text-red mb-4 col-7">
                  {(allOrderID?.order_name).toUpperCase()}
                </h5>
                <h5 className="text-red mb-4 col">
                  {allOrderID?.isDelivered === 0 ? (
                    <span className="ml-2">
                      <i
                        className="fa fa-person-biking me-2"
                        style={{ color: "#DB1F26" }}
                      ></i>
                      Ordered
                    </span>
                  ) : (
                    <span className="ml-2">
                      <i
                        className="fa-regular fa-circle-check me-2"
                        style={{ color: "green" }}
                      ></i>
                      <span className="text-success">Delivered</span>
                    </span>
                  )}
                </h5>
              </div>
              <h4 className="border-bottom">Delivery Date</h4>
              <div className="text-gray mt-2" style={{ fontWeight: "bold" }}>
                {allOrderID?.deliveryDateFrom}
                {/* {`${monthNames[new Date(allOrderID?.date).getMonth()]} ${getDateWithPrefix(new Date(allOrderID?.date).getDate())}, ${new Date(allOrderID?.date).getFullYear()}`} */}
                {/* {moment(allOrderID?.date).format("MMMM Do, YYYY")} */}
              </div>

              <div className=" row">
                <div className="mt-4  col-7" style={{ fontWeight: "bold" }}>
                  Deliver to
                </div>
                <div className="mt-4  col" style={{ fontWeight: "bold" }}>
                  Driver
                </div>
              </div>

              <div className="row">
                <div className="mt-2 col-7" style={{ fontWeight: "bold" }}>
                  <div className="text-gray">
                    <div>{allOrderID?.customers[0]?.name}</div>
                    <div>
                      {formatPhoneNumber(allOrderID?.customers[0]?.number)}
                    </div>
                  </div>
                  <div>
                    <div className="col-7" style={{ fontWeight: "bold" }}>
                      Address
                    </div>
                    <div className="">{allOrderID.address[0]?.name}</div>
                    <div className="text-gray">
                      <div>{allOrderID.address[0]?.address1},</div>
                      {allOrderID.address[0]?.address2 && (
                        <div>{allOrderID.address[0]?.address2},</div>
                      )}
                      <div>
                        {allOrderID.address[0]?.city},
                        {" " + allOrderID.address[0]?.state + " "}
                        {allOrderID.address[0]?.pincode}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col mt-2 text-gray"
                  style={{ fontWeight: "bold" }}
                >
                  <div>{allOrderID?.agents[0]?.name}</div>
                  <div>
                    {/* +{allOrderID?.agents[0]?.countrycode}{" "} */}
                    {formatPhoneNumber(allOrderID?.agents[0]?.number)}
                  </div>
                  <div className="text-break">
                    {allOrderID?.agents[0]?.email}
                  </div>
                </div>
              </div>
              <div className=" mt-4" style={{ fontWeight: "bold" }}>
                <div className="mb-2">
                  <div className="d-inline-block">Delivery Time:</div>
                  <div className="d-inline-block text-gray ms-3">
                    {allOrderID?.deliverytimestring}
                  </div>
                </div>
                <div>
                  <div className="d-inline-block">Leave Time:</div>
                  <div className="d-inline-block text-gray ms-3">
                    {" "}
                    {allOrderID?.leavetimestring}
                  </div>
                </div>
              </div>
              {/* <div className="mt-2" style={{ fontWeight: "bold" }}>
                <div className="d-inline-block">Location:</div>
                <div className="d-inline-block text-gray ms-3">
                  {allOrderID?.locationdata[0]?.location_name}
                </div>
              </div> */}

              <div className="mt-4">
                <div className="border-top border-bottom py-3 my-3">
                  <div className="row">
                    <h5 className="mb-4 col-8">Order Summary </h5>
                    {allOrderID?.isDelivered !== 0 && (
                      <Button
                        onClick={() => {
                          if (allOrderID?.location_id !== "") {
                            handleEditItemsShow();
                          }
                        }}
                        className="col-4 border rounded pointer px-1 py-1 text-white bg-red "
                      >
                        Update Items
                      </Button>
                    )}
                  </div>

                  {allOrderID?.menu.map((e, i) => {
                    if (e?.quantity > 0) {
                      return (
                        <>
                          <div
                            className="d-flex justify-content-between align-items-center mt-2"
                            key={i}
                          >
                            <div className="d-flex justify-content-between align-items-center flex-grow-1">
                              <div>
                                {/* <img
                                  crossOrigin="anonymous"
                                  src={
                                    e.itemmenu[0]?.image !== ""
                                      ? `${e.itemmenu[0]?.image}`
                                      : "/images/menu-placeholder.jpg"
                                  }
                                  style={{ width: "50px" }}
                                /> */}
                              </div>
                              <div className="ms-3 flex-grow-1">
                                <div style={{ fontWeight: "bold" }}>
                                  {e.itemmenu[0]?.item_name}
                                </div>
                                <div className="h6">{editItems?.item_name}</div>
                                {/* <div>
                                  Price: $
                                  {parseFloat(e?.itemmenu[0]?.price)?.toFixed(
                                    2
                                  )}
                                </div> */}

                                <div className="me-4">
                                  {" "}
                                  Quantity: {e.quantity} * $
                                  {parseFloat(e?.itemmenu[0]?.price)}
                                  {/* {`$${(
                                    parseFloat(e?.itemmenu[0]?.price) *
                                    parseInt(e?.quantity)
                                  ).toFixed(2)}`} */}
                                </div>
                              </div>
                            </div>

                            <div style={{ fontWeight: "bold" }}>
                              <span className="text-red">
                                {e.isfree === 0 ? "$" : ""}
                              </span>
                              {/* {e.isfree === 0
                                ? parseFloat(e.itemmenu[0]?.price).toFixed(2)
                                : "Free"} */}
                              {e.isfree === 0 ? (
                                <>
                                  {`${(
                                    parseFloat(e?.itemmenu[0]?.price) *
                                    parseInt(e?.quantity)
                                  ).toFixed(2)}`}
                                </>
                              ) : (
                                <>{"Free"}</>
                              )}
                            </div>
                          </div>
                          {e?.ordernote && (
                            <div
                              className="mt-3 p-3 border border-5"
                              style={{
                                backgroundColor: "#E9ECEF",
                                overflowY: "auto",
                                height: "110px",
                                resize: "none",
                                textAlign: "justify",
                              }}
                            >
                              <span style={{ fontWeight: "bold" }}>
                                Instructions:
                              </span>{" "}
                              <span className="">{e?.ordernote}</span>
                            </div>
                          )}
                        </>
                      );
                    }
                  })}
                </div>
                <div className="mt-3">
                  <h5 className="mb-3">Note</h5>
                  <Form.Control
                    disabled
                    className="border border-5"
                    style={{
                      height: "170px",
                      resize: "none",
                      textAlign: "justify",
                    }}
                    as={"textarea"}
                  >
                    {allOrderID.customers[0]?.note}
                  </Form.Control>
                </div>
                <hr />
                <div style={{ fontWeight: "bold" }}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>Item Total Price</div>
                    <div>
                      <span className="text-red">$</span>
                      {parseFloat(allOrderID?.amount).toFixed(2)}
                    </div>
                  </div>
                  {allOrderID?.discount !== "" &&
                    allOrderID?.discount !== 0 && (
                      <div className="d-flex justify-content-between align-items-center">
                        <div>Discount</div>
                        <div>
                          <span className="text-red">$</span>
                          {allOrderID?.discount !== "" &&
                          allOrderID?.discount !== 0
                            ? parseFloat(allOrderID?.discount).toFixed(2)
                            : parseFloat(allOrderID?.manual_discount).toFixed(
                                2
                              )}
                        </div>
                      </div>
                    )}
                  {!!allOrderID?.saletaxamount && (
                    <div className="d-flex justify-content-between align-items-center">
                      <div>Sales Tax</div>
                      <div>
                        <span className="text-red">$</span>
                        {parseFloat(allOrderID?.saletaxamount).toFixed(2)}
                      </div>
                    </div>
                  )}
                  {!!allOrderID?.subtotal && (
                    <div className="d-flex justify-content-between align-items-center">
                      <div>Sub Total</div>
                      <div>
                        <span className="text-red">$</span>
                        {parseFloat(allOrderID?.subtotal).toFixed(2)}
                      </div>
                    </div>
                  )}
                  {!!allOrderID?.servicevalue && (
                    <div className="d-flex justify-content-between align-items-center">
                      <div>Service Charges</div>
                      <div>
                        <span className="text-red">$</span>
                        {parseFloat(allOrderID?.servicevalue).toFixed(2)}
                      </div>
                    </div>
                  )}
                  {!!allOrderID?.deliveryvalue && (
                    <div className="d-flex justify-content-between align-items-center">
                      <div>Delivery Charges</div>
                      <div>
                        <span className="text-red">$</span>
                        {parseFloat(allOrderID?.deliveryvalue).toFixed(2)}
                      </div>
                    </div>
                  )}
                  {!!allOrderID?.totaltipamount && (
                    <div className="d-flex justify-content-between align-items-center">
                      <div>Total Tip Amount</div>
                      <div>
                        <span className="text-red">$</span>
                        {parseFloat(allOrderID?.totaltipamount).toFixed(2)}
                      </div>
                    </div>
                  )}
                </div>

                <hr />
                <div style={{ fontWeight: "bold" }}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>Grand Total</div>
                    <div>
                      <span className="text-red">$</span>
                      {parseFloat(allOrderID?.grandtotal).toFixed(2)}
                    </div>
                  </div>
                </div>
                <div className=" mb-4"></div>
                {allOrderID?.isDelivered === 1 && (
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <div
                      className="pointer bg-red text-light px-3 py-2 border rounded"
                      onClick={handleShowDocument}
                    >
                      Proof of Delivery
                    </div>
                    <div style={{ fontWeight: "bold" }}>
                      {`${allOrderID?.deliveryDateUnform}, ${allOrderID?.driverDeliveredtime}`}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </Modal.Body>

      <Modal
        show={show1}
        onHide={handleCloseDocument}
        backdrop="static"
        size="lg"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Photo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="me-3 d-flex justify-content-center align-items-center">
            {allOrderID?.deliveredorderdata.length != 0 ? (
              <div className="d-flex flex-col">
                {imageLoading ? <Spinner /> : ""}
                <img
                  // onError={imgDefault}
                  onLoad={() => {
                    setImageLoading(false);
                  }}
                  src={allOrderID?.deliveredorderdata[0]?.uploadphoto}
                  style={{ height: "200px", width: "250px" }}
                />
              </div>
            ) : (
              <>Syncing is in progress</>
            )}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={editItems}
        onHide={handleEditItemsClose}
        backdrop="static"
        size="md"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Items</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-xl-12 col-lg-5">
            {/* <div className="d-flex justify-content-center align-items-center mt-4">
              <button
                type="button"
                className="btn text-white bg-red"
                onClick={() =>
                  allOrderID?.location_id !== "" ? setIsAddItem(true) : ""
                }
              >
                Add&nbsp;Items
              </button>
            </div> */}
            <div className="text-end"></div>
            {addedItem?.length === 0 ? (
              <div className="text-center h5 mt-5 text-red">
                {" "}
                No items are added
              </div>
            ) : (
              <>
                <div className="items-container mt-4">
                  <div className="cart-container">
                    {addedItem?.map((item, index) => {
                      return (
                        <div key={index} className="border p-2 rounded mb-2">
                          <div className="d-flex align-items-center justify-content-start">
                            <div className="flex-grow-1">
                              <div className="h6">{item?.item_name}</div>
                              <div>
                                Price: ${parseFloat(item?.price)?.toFixed(2)}
                              </div>

                              <div className="me-4">
                                {" "}
                                Total Price: $
                                {parseFloat(item?.price)?.toFixed(2)} *{" "}
                                {item.quantity} ={" "}
                                {`$${(
                                  parseFloat(item?.price) *
                                  parseInt(item?.quantity)
                                ).toFixed(2)}`}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex qty-controls justify-content-end">
                            <button
                              type="button"
                              className="btn btn-sm bg-red"
                              onClick={() => handleRemoveItem(item._id)}
                            >
                              -
                            </button>
                            <input
                              className="btn bg-red text-white d-flex justify-content-center align-items-centerr text-center"
                              style={{ width: "40px", height: "30px" }}
                              value={item?.quantity}
                              onChange={(e) =>
                                handlecount(item?._id, e.target.value)
                              }
                            />
                          </div>
                          {item?.ordernote && (
                            <div
                              className="mt-2 p-1 border border-5"
                              style={{
                                backgroundColor: "#E9ECEF",
                                overflowY: "auto",
                                height: "110px",
                                resize: "none",
                                textAlign: "justify",
                              }}
                            >
                              <span style={{ fontWeight: "bold" }}>
                                Instructions:
                              </span>{" "}
                              <span className="">{item?.ordernote}</span>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
            <div className="row mt-2 justify-content-end align-items-center">
              <div className="col-6 bold">Manual Discount: </div>
              <div className="col-6">
                <input
                  type="number"
                  name="manual_discount"
                  value={tax.manual_discount}
                  onChange={handleConfigInput}
                  className={classNames("form-control", {
                    requireField: error.manual_discount,
                  })}
                />
              </div>
            </div>
            <div className="mt-3 ms-auto border-top">
              <div className="row mt-2 justify-content-end ">
                <div className="col-6 bold">Total Amount: </div>
                <div className="col-6">
                  ${parseFloat(tax?.total)?.toFixed(2)}
                </div>
              </div>
              <div className="row mt-2 justify-content-end">
                <div className="col-6 bold">Manual Discount: </div>
                <div className="col-6">
                  {" "}
                  ${parseFloat(tax?.manual_discount)?.toFixed(2)}
                </div>
              </div>
              <div className="row mt-2 justify-content-end">
                <div className="col-6 bold">Sales Tax: </div>
                <div className="col-6">
                  ${parseFloat(allOrderID?.saletaxamount)?.toFixed(2)}
                </div>
              </div>
              <div className="row mt-2 justify-content-end">
                <div className="col-6 bold">Sub Total: </div>
                <div className="col-6">
                  ${parseFloat(tax?.subTotal)?.toFixed(2)}
                </div>
              </div>
              <div className="row mt-2 justify-content-end">
                <div className="col-6 bold">Service Fees: </div>
                <div className="col-6">
                  ${parseFloat(allOrderID?.servicevalue)?.toFixed(2)}
                </div>
              </div>
              <div className="row mt-2 justify-content-end">
                <div className="col-6 bold">Delivery Fees: </div>
                <div className="col-6">
                  ${parseFloat(allOrderID?.deliveryvalue)?.toFixed(2)}
                </div>
              </div>
              <div className="row mt-2 justify-content-end">
                <div className="col-6 bold">Tip Amount: </div>
                <div className="col-6">
                  ${parseFloat(allOrderID?.totaltipamount)?.toFixed(2)}
                </div>
              </div>
              <div className="row mt-2 justify-content-end">
                <div className="col-6 bold">Grand Total: </div>
                <div className="col-6">
                  ${parseFloat(tax?.grandTotal)?.toFixed(2)}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end mt-2">
              <div
                className="border rounded pointer px-4 py-2 text-white bg-red"
                onClick={uploadeLoading ? () => {} : handleCreate}
              >
                {uploadeLoading ? <Loader /> : "Update Items"}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* <Toaster /> */}

      <Modal
        show={isAddItem}
        onHide={() => setIsAddItem(false)}
        size="xl"
        backdrop="static"
        dialogClassName="modal-dialog-scrollable"
      >
        <Additem
          setIsAddItem={setIsAddItem}
          setAddedItem={setAddedItem}
          addedItem={addedItem}
          flag={1}
          setChepestPizzaItem={setChepestPizzaItem}
          locationID={allOrderID && allOrderID?.location_id}
        />
      </Modal>

      {/* <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary">Submit</Button>
      </Modal.Footer> */}
    </>
  );
};

const mapStateToProp = (state) => ({
  SuperAdminOrderReducer: state.SuperAdminOrderReducer,
  orderfilterSuperadmin: state.SuperAdminOrderReducer.orderfilterSuperadmin,
  getLocationByMenuReducer: state.getLocationByMenu.getLocationByMenu,
  editOrderReducer: state.SuperAdminOrderReducer.updateOrder,
});

const mapDispatchToProps = (dispatch) => ({
  //     updatePrmDatahandler: (user) => dispatch(updatePrmDataAction(user)),
  getOrderDataAction: (Details) => dispatch(getOrderDataAction(Details)),
  getOrderFilterAction: (Details) => dispatch(getOrderFilterAction(Details)),
  getLocationByMenuDataAction: (id) =>
    dispatch(getLocationByMenuDataAction(id)),
  UpdateOrderAction: (details) => dispatch(UpdateOrderAction(details)),
  resetUpdateOrder: () => dispatch({ type: RESET_UPDATE_SUPER_ADMIN_ORDER }),
  // resetprm: () => dispatch({ type: RESET_ADD_PRM_DATA }),
  // resetEditDivisionReducer: () => dispatch({ type: RESET_EDIT_DIVISION }),
  // getAllDivisionHandler: user => dispatch((user))
});
export default connect(mapStateToProp, mapDispatchToProps)(OrderDetails);

import axios from "axios";
import { addMenus_Url, deleteMenus_Url, getMenuById_Url, getMenus_Url, updateMenus_Url } from "../../apiSheet";

import { ADD_MENU_ERROR, ADD_MENU_LOADING, ADD_MENU_SUCCESS, DELETE_MENU_ERROR, DELETE_MENU_LOADING, DELETE_MENU_SUCCESS, GET_MENU_ERROR, GET_MENU_ID_ERROR, GET_MENU_ID_LOADING, GET_MENU_ID_SUCCESS, GET_MENU_LOADING, GET_MENU_SUCCESS, RESET_ADD_MENU, UPDATE_MENU_ERROR, UPDATE_MENU_LOADING, UPDATE_MENU_SUCCESS } from "../../Constants/Constants";


export const getMenuItems = () => {

  return (dispatch) => {
    dispatch({
      type: GET_MENU_LOADING,
    });
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${getMenus_Url}`,
     
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_MENU_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message,
            pagination: {
              page: resp.data.page,
              limit: resp.data.limit,
              totalpage: resp.data.totalpage,
            }
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_MENU_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: GET_MENU_ERROR,
              errors: validationError
            });
          }
          
      });
  };
}

export const getMenuItemsById = (id) => {

  return (dispatch) => {
    dispatch({
      type: GET_MENU_ID_LOADING,
    });
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${getMenuById_Url}/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_MENU_ID_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_MENU_ID_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: GET_MENU_ERROR,
              errors: validationError
            });
          }
         
      });
  };
}

export const addMenuItem = (details) => {

  return (dispatch) => {
    dispatch({
      type: ADD_MENU_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
      url: `${addMenus_Url}`,
      data: details
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: ADD_MENU_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: ADD_MENU_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: RESET_ADD_MENU,
              errors: validationError
            });
          }
          else if (data.status === 401) {
            window.location.reload();
          }
      });
  };
}

export const updatemenuitem = (details) => {

  return (dispatch) => {
    dispatch({
      type: UPDATE_MENU_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${updateMenus_Url}/${details.id}`,
      data: details.data
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: UPDATE_MENU_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: UPDATE_MENU_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: UPDATE_MENU_ERROR,
              errors: validationError
            });
          }
          else if (data.status === 401) {
            window.location.reload();
          }
      });
  };
}

export const deletemenuitem = (id) => {

  return (dispatch) => {
    dispatch({
      type: DELETE_MENU_LOADING,
    });
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${deleteMenus_Url}/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: DELETE_MENU_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: DELETE_MENU_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: DELETE_MENU_ERROR,
              errors: validationError
            });
          }
          else if (data.status === 401) {
            window.location.reload();
          }
      });
  };
}
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  createMenuDataAction,
  getAgentsData,
  getMenuDataAction,
} from "../../../Store/Action/CreateAgents/UserAction";
import Footer from "../Userlayout/Footer";
import Header from "../Userlayout/Header";

const CreateMenu = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [apiData, setapiData] = useState([]);

  const [menuApiData, setmenuApiData] = useState({
    location: "",
    item_name: "",
    price: "",
    is_pizza: "",
    image: "",
  });

  const [errors, setErrors] = useState({
    location: false,
    item_name: false,
    price: false,
    is_pizza: false,
    image: false,
  });

  useEffect(() => {
    if (props.createMenusReducer.success) {
      navigate("/superadmin/menu");
    }
  }, [props.createMenusReducer]);

  const allLocationData = useSelector(
    (state) => state.getAgentsReducer.user.data
  );

  useEffect(() => {
    dispatch(
      getAgentsData({
        page: 1,
        limit: 10,
      })
    );
  }, []);

  useEffect(() => {
    setapiData(allLocationData);
  }, [allLocationData]);

  const onChangeInput = (e) => {
    if (e.target.name === "image") {
      setmenuApiData({ ...menuApiData, [e.target.name]: e.target.files[0] });
    } else {
      setmenuApiData({ ...menuApiData, [e.target.name]: e.target.value });
    }

    switch (e.target.name) {
      case "image":
        if (e.target.files.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            image: "image can not be empty",
          }));

          document.getElementById("image").classList.add("error");
        } else {
          setErrors((prevState) => ({
            ...prevState,
            image: false,
          }));

          document.getElementById("image").classList.remove("error");
        }
        break;

      case "location":
        if (e.target.value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            location: "location can not be empty",
          }));

          document.getElementById("location").classList.add("error");
        } else {
          setErrors((prevState) => ({
            ...prevState,
            location: false,
          }));

          document.getElementById("location").classList.remove("error");
        }
        break;

      case "item_name":
        if (e.target.value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            item_name: "name can not be empty",
          }));

          document.getElementById("item_name").classList.add("error");
        } else {
          setErrors((prevState) => ({
            ...prevState,
            item_name: false,
          }));

          document.getElementById("item_name").classList.remove("error");
        }
        break;

      case "is_pizza":
        if (e.target.value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            is_pizza: "is_pizza can not be empty",
          }));

          document.getElementById("is_pizza").classList.add("error");
        } else {
          setErrors((prevState) => ({
            ...prevState,
            is_pizza: false,
          }));

          document.getElementById("is_pizza").classList.remove("error");
        }
        break;

      case "price":
        if (e.target.value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            price: "price can not be empty",
          }));

          document.getElementById("price").classList.add("error");
        } else {
          setErrors((prevState) => ({
            ...prevState,
            price: false,
          }));

          document.getElementById("price").classList.remove("error");
        }
        break;

      default:
    }
  };

  const onSubmite = (e) => {
    e.preventDefault();

    if (menuApiData.location == "") {
      setErrors((prevState) => ({
        ...prevState,
        location: "location can not be empty",
      }));
      document.getElementById("location").classList.add("error");
    }

    if (menuApiData.item_name == "") {
      setErrors((prevState) => ({
        ...prevState,
        item_name: "item_name can not be empty",
      }));
      document.getElementById("item_name").classList.add("error");
    }

    // if (menuApiData.sale_tax_rate == "") {
    //   setErrors((prevState) => ({
    //     ...prevState,
    //     sale_tax_rate: "sale_tax_rate can not be empty",
    //   }));
    //   document.getElementById("sale_tax_rate").classList.add("error");
    // }

    if (menuApiData.price == "") {
      setErrors((prevState) => ({
        ...prevState,
        price: "price can not be empty",
      }));
      document.getElementById("price").classList.add("error");
    }

    // if (menuApiData.pizza == "") {
    //   setErrors((prevState) => ({
    //     ...prevState,
    //     pizza: "pizza can not be empty",
    //   }));
    //   document.getElementById("pizza").classList.add("error");
    // }

    if (menuApiData.image == "") {
      setErrors((prevState) => ({
        ...prevState,
        image: "image can not be empty",
      }));
      document.getElementById("image").classList.add("error");
    }

    if (
      // prmApiData.name !== "" &&
      menuApiData.location !== "" &&
      menuApiData.item_name !== "" &&
      menuApiData.price !== "" &&
      // menuApiData.pizza !== "" &&
      menuApiData.image !== ""
      // menuApiData.confirmpassword !== "" &&
      // menuApiData.password == menuApiData.confirmpassword
    ) {
      dispatch(
        createMenuDataAction({
          // name: menuApiData.name,
          location: menuApiData.location,
          item_name: menuApiData.item_name,
          price: menuApiData.price,
          // pizza: menuApiData.pizza,
          image: menuApiData.image,
          is_pizza: menuApiData.is_pizza ? "1" : "0",
          status: menuApiData.status === "active" ? "1" : "0",
        })
      );
    }
  };

  return (
    <>
      <Header />
      <main className="userMain">
        <div className="content-block"> </div>
      </main>
      <Footer />
    </>
  );
};

const mapStateToProp = (state) => ({
  createMenusReducer: state.createMenusReducer,

  // submit: state.createLiabilityReducer,
  // liability: state.getPrintOrgLiabilityReducer,
});

const mapDispatchToProps = (dispatch) => ({
  // createLiabilityHandler: (user) => dispatch(createLiability(user)),
  // resetCreateLiabilityReducer: () => dispatch({ type: RESET_CREATE_LIABILITY }),
  getAgentsData: (Details) => dispatch(getAgentsData(Details)),
});
export default connect(mapStateToProp, mapDispatchToProps)(CreateMenu);

// login
export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const RESET_LOGIN = "RESET_LOGIN";

// profile Details
export const PROFILE_LOADING = "PROFILE_LOADING";
export const PROFILE_SUCCESS = "PROFILE_SUCCESS";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const RESET_PROFILE = "RESET_PROFILE";

// menu constatnts

export const GET_MENU_LOADING = "GET_MENU_LOADING";
export const GET_MENU_SUCCESS = "GET_MENU_SUCCESS";
export const GET_MENU_ERROR = "GET_MENU_ERROR";
export const RESET_GET_MENU = "RESET_GET_MENU";

export const GET_MENU_ID_LOADING = "GET_MENU_ID_LOADING";
export const GET_MENU_ID_SUCCESS = "GET_MENU_ID_SUCCESS";
export const GET_MENU_ID_ERROR = "GET_MENU_ID_ERROR";
export const RESET_GET_MENU_ID = "RESET_GET_MENU_ID";

export const ADD_MENU_LOADING = "ADD_MENU_LOADING";
export const ADD_MENU_SUCCESS = "ADD_MENU_SUCCESS";
export const ADD_MENU_ERROR = "ADD_MENU_ERROR";
export const RESET_ADD_MENU = "RESET_ADD_MENU";

export const UPDATE_MENU_LOADING = "UPDATE_MENU_LOADING";
export const UPDATE_MENU_SUCCESS = "UPDATE_MENU_SUCCESS";
export const UPDATE_MENU_ERROR = "UPDATE_MENU_ERROR";
export const RESET_UPDATE_MENU = "RESET_UPDATE_MENU";

export const DELETE_MENU_LOADING = "DELETE_MENU_LOADING";
export const DELETE_MENU_SUCCESS = "DELETE_MENU_SUCCESS";
export const DELETE_MENU_ERROR = "DELETE_MENU_ERROR";
export const RESET_DELETE_MENU = "RESET_DELETE_MENU";

//  delivery agent

export const GET_AGENT_LOADING = "GET_AGENT_LOADING";
export const GET_AGENT_SUCCESS = "GET_AGENT_SUCCESS";
export const GET_AGENT_ERROR = "GET_AGENT_ERROR";
export const RESET_GET_AGENT = "RESET_GET_AGENT";

export const GET_AGENT_BY_ID_LOADING = "GET_AGENT_BY_ID_LOADING";
export const GET_AGENT_BY_ID_SUCCESS = "GET_AGENT_BY_ID_SUCCESS";
export const GET_AGENT_BY_ID_ERROR = "GET_AGENT_BY_ID_ERROR";
export const RESET_GET_AGENT_BY_ID = "RESET_GET_AGENT_BY_ID";

export const ADD_AGENT_LOADING = "ADD_AGENT_LOADING";
export const ADD_AGENT_SUCCESS = "ADD_AGENT_SUCCESS";
export const ADD_AGENT_ERROR = "ADD_AGENT_ERROR";
export const RESET_ADD_AGENT = "RESET_ADD_AGENT";

export const UPDATE_AGENT_LOADING = "UPDATE_AGENT_LOADING";
export const UPDATE_AGENT_SUCCESS = "UPDATE_AGENT_SUCCESS";
export const UPDATE_AGENT_ERROR = "UPDATE_AGENT_ERROR";
export const RESET_UPDATE_AGENT = "RESET_UPDATE_AGENT";

export const DELETE_AGENT_LOADING = "DELETE_AGENT_LOADING";
export const DELETE_AGENT_SUCCESS = "DELETE_AGENT_SUCCESS";
export const DELETE_AGENT_ERROR = "DELETE_AGENT_ERROR";
export const RESET_DELETE_AGENT = "RESET_DELETE_AGENT";

//  PRM

export const GET_PRM_LOADING = "GET_PRM_LOADING";
export const GET_PRM_SUCCESS = "GET_PRM_SUCCESS";
export const GET_PRM_ERROR = "GET_PRM_ERROR";
export const RESET_GET_PRM = "RESET_GET_PRM";

export const GET_PRM_BY_ID_LOADING = "GET_PRM_BY_ID_LOADING";
export const GET_PRM_BY_ID_SUCCESS = "GET_PRM_BY_ID_SUCCESS";
export const GET_PRM_BY_ID_ERROR = "GET_PRM_BY_ID_ERROR";
export const RESET_GET_PRM_BY_ID = "RESET_GET_PRM_BY_ID";

export const ADD_PRM_LOADING = "ADD_PRM_LOADING";
export const ADD_PRM_SUCCESS = "ADD_PRM_SUCCESS";
export const ADD_PRM_ERROR = "ADD_PRM_ERROR";
export const RESET_ADD_PRM = "RESET_ADD_PRM";

export const UPDATE_PRM_LOADING = "UPDATE_PRM_LOADING";
export const UPDATE_PRM_SUCCESS = "UPDATE_PRM_SUCCESS";
export const UPDATE_PRM_ERROR = "UPDATE_PRM_ERROR";
export const RESET_UPDATE_PRM = "RESET_UPDATE_PRM";

export const DELETE_PRM_LOADING = "DELETE_PRM_LOADING";
export const DELETE_PRM_SUCCESS = "DELETE_PRM_SUCCESS";
export const DELETE_PRM_ERROR = "DELETE_PRM_ERROR";
export const RESET_DELETE_PRM = "RESET_DELETE_PRM";

//  order

export const GET_ORDER_LOADING = "GET_ORDER_LOADING";
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";
export const GET_ORDER_ERROR = "GET_ORDER_ERROR";
export const RESET_GET_ORDER = "RESET_GET_ORDER";

export const GET_ORDER_BY_ID_LOADING = "GET_ORDER_BY_ID_LOADING";
export const GET_ORDER_BY_ID_SUCCESS = "GET_ORDER_BY_ID_SUCCESS";
export const GET_ORDER_BY_ID_ERROR = "GET_ORDER_BY_ID_ERROR";
export const RESET_GET_ORDER_BY_ID = "RESET_GET_ORDER_BY_ID";

export const ADD_ORDER_LOADING = "ADD_ORDER_LOADING";
export const ADD_ORDER_SUCCESS = "ADD_ORDER_SUCCESS";
export const ADD_ORDER_ERROR = "ADD_ORDER_ERROR";
export const RESET_ADD_ORDER = "RESET_ADD_ORDER";

export const UPDATE_ORDER_LOADING = "UPDATE_ORDER_LOADING";
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS";
export const UPDATE_ORDER_ERROR = "UPDATE_ORDER_ERROR";
export const RESET_UPDATE_ORDER = "RESET_UPDATE_ORDER";

export const DELETE_ORDER_LOADING = "DELETE_ORDER_LOADING";
export const DELETE_ORDER_SUCCESS = "DELETE_ORDER_SUCCESS";
export const DELETE_ORDER_ERROR = "DELETE_ORDER_ERROR";
export const RESET_DELETE_ORDER = "RESET_DELETE_ORDER";


export const ADMIN_ORDER_FILTER_LOADING = "ADMIN_ORDER_FILTER_LOADING";
export const ADMIN_ORDER_FILTER_SUCCESS = "ADMIN_ORDER_FILTER_SUCCESS";
export const ADMIN_ORDER_FILTER_ERROR = "ADMIN_ORDER_FILTER_ERROR";
export const RESET_ADMIN_ORDER_FILTER = "RESET_ADMIN_ORDER_FILTER";

// find customer by contact

export const FIND_CUSTOMER_BY_CONTACT_LOADING =
  "FIND_CUSTOMER_BY_CONTACT_LOADING";
export const FIND_CUSTOMER_BY_CONTACT_SUCCESS =
  "FIND_CUSTOMER_BY_CONTACT_SUCCESS";
export const FIND_CUSTOMER_BY_CONTACT_ERROR = "FIND_CUSTOMER_BY_CONTACT_ERROR";
export const RESET_FIND_CUSTOMER_BY_CONTACT = "RESET_FIND_CUSTOMER_BY_CONTACT";

// logout

export const ADMIN_LOGOUT_LOADING = "ADMIN_LOGOUT_LOADING";
export const ADMIN_LOGOUT_SUCCESS = "ADMIN_LOGOUT_SUCCESS";
export const ADMIN_LOGOUT_ERROR = "ADMIN_LOGOUT_ERROR";
export const RESET_ADMIN_LOGOUT = "RESET_ADMIN_LOGOUT";

// forgot password
export const FORGOT_PASSWORD_LOADING = "FORGOT_PASSWORD_LOADING";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const RESET_FORGOT_PASSWORD = "RESET_FORGOT_PASSWORD";

// verify otp
export const OTP_VERIFY_LOADING = "OTP_VERIFY_LOADING";
export const OTP_VERIFY_SUCCESS = "OTP_VERIFY_SUCCESS";
export const OTP_VERIFY_ERROR = "OTP_VERIFY_ERROR";
export const RESET_OTP_VERIFY = "RESET_OTP_VERIFY";

// reset password
export const RESET_PASSWORD_LOADING = "RESET_PASSWORD_LOADING";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const RESET_RESET_PASSWORD = "RESET_RESET_PASSWORD";

// change password
export const CHANGE_PASSWORD_LOADING = "CHANGE_PASSWORD_LOADING";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";
export const RESET_CHANGE_PASSWORD = "RESET_CHANGE_PASSWORD";

// admin contact search
export const CUSTOMER_CONTACT_SEARCH_ADMIN_LOADING = "CUSTOMER_CONTACT_SEARCH_ADMIN_LOADING";
export const CUSTOMER_CONTACT_SEARCH_ADMIN_SUCCESS = "CUSTOMER_CONTACT_SEARCH_ADMIN_SUCCESS";
export const CUSTOMER_CONTACT_SEARCH_ADMIN_ERROR = "CUSTOMER_CONTACT_SEARCH_ADMIN_ERROR";
export const RESET_CUSTOMER_CONTACT_SEARCH_ADMIN = "RESET_CUSTOMER_CONTACT_SEARCH_ADMIN";

// admin customer search
export const ADMIN_CUSTOMER_SEARCH_LOADING = "ADMIN_CUSTOMER_SEARCH_LOADING";
export const ADMIN_CUSTOMER_SEARCH_SUCCESS = "ADMIN_CUSTOMER_SEARCH_SUCCESS";
export const ADMIN_CUSTOMER_SEARCH_ERROR = "ADMIN_CUSTOMER_SEARCH_ERROR";
export const RESET_ADMIN_CUSTOMER_SEARCH = "RESET_ADMIN_CUSTOMER_SEARCH";

// admin agent wise order
export const ADMIN_AGENT_WISE_ORDER_LOADING = "ADMIN_AGENT_WISE_ORDER_LOADING";
export const ADMIN_AGENT_WISE_ORDER_SUCCESS = "ADMIN_AGENT_WISE_ORDER_SUCCESS";
export const ADMIN_AGENT_WISE_ORDER_ERROR = "ADMIN_AGENT_WISE_ORDER_ERROR";
export const RESET_ADMIN_AGENT_WISE_ORDER = "RESET_ADMIN_AGENT_WISE_ORDER";

export const GET_ADMIN_DELIVERY_AGENTS_DATA_LOADING =
  "GET_ADMIN_DELIVERY_AGENTS_DATA_LOADING";
export const GET_ADMIN_DELIVERY_AGENTS_DATA_SUCCESS =
  "GET_ADMIN_DELIVERY_AGENTS_DATA_SUCCESS";
export const GET_ADMIN_DELIVERY_AGENTS_DATA_ERROR = "GET_ADMIN_DELIVERY_AGENTS_DATA_ERROR";
export const DELETE_ADMIN_DELIVERY_AGENTS_ITEM_DATA =
  "DELETE_ADMIN_DELIVERY_AGENTS_ITEM_DATA";
export const RESET_ADMIN_GET_DELIVERY_DATA = "RESET_ADMIN_GET_DELIVERY_DATA";

// admin export
export const ADMIN_EXPORT_PDF_LOADING = "ADMIN_EXPORT_PDF_LOADING";
export const ADMIN_EXPORT_PDF_SUCCESS = "ADMIN_EXPORT_PDF_SUCCESS";
export const ADMIN_EXPORT_PDF_ERROR = "ADMIN_EXPORT_PDF_ERROR";
export const RESET_ADMIN_EXPORT_PDF = "RESET_ADMIN_EXPORT_PDF";

// admin export
export const UPLOAD_MENU_EXCEL_LOADING = "UPLOAD_MENU_EXCEL_LOADING";
export const UPLOAD_MENU_EXCEL_SUCCESS = "UPLOAD_MENU_EXCEL_SUCCESS";
export const UPLOAD_MENU_EXCEL_ERROR = "UPLOAD_MENU_EXCEL_ERROR";
export const RESET_UPLOAD_MENU_EXCEL = "RESET_UPLOAD_MENU_EXCEL";

// admin export
export const REPORT_LOADING = "REPORT_LOADING";
export const REPORT_SUCCESS = "REPORT_SUCCESS";
export const REPORT_ERROR = "REPORT_ERROR";
export const RESET_REPORT = "RESET_REPORT";

// admin export
export const GET_ADMIN_CUSTOMER_LOADING = "GET_ADMIN_CUSTOMER_LOADING";
export const GET_ADMIN_CUSTOMER_SUCCESS = "GET_ADMIN_CUSTOMER_SUCCESS";
export const GET_ADMIN_CUSTOMER_ERROR = "GET_ADMIN_CUSTOMER_ERROR";
export const RESET_GET_ADMIN_CUSTOMER = "RESET_GET_ADMIN_CUSTOMER";

// customer details
export const GET_ADMIN_CUSTOMER_DETAILS_LOADING = "GET_ADMIN_CUSTOMER_DETAILS_LOADING";
export const GET_ADMIN_CUSTOMER_DETAILS_SUCCESS = "GET_ADMIN_CUSTOMER_DETAILS_SUCCESS";
export const GET_ADMIN_CUSTOMER_DETAILS_ERROR = "GET_ADMIN_CUSTOMER_DETAILS_ERROR";
export const RESET_GET_ADMIN_CUSTOMER_DETAILS = "RESET_GET_ADMIN_CUSTOMER_DETAILS";


// customer Order
export const GET_ADMIN_CUSTOMER_ORDER_LOADING = "GET_ADMIN_CUSTOMER_ORDER_LOADING";
export const GET_ADMIN_CUSTOMER_ORDER_SUCCESS = "GET_ADMIN_CUSTOMER_ORDER_SUCCESS";
export const GET_ADMIN_CUSTOMER_ORDER_ERROR = "GET_ADMIN_CUSTOMER_ORDER_ERROR";
export const RESET_GET_ADMIN_CUSTOMER_ORDER = "RESET_GET_ADMIN_CUSTOMER_ORDER";



// customer Order
export const DELETE_ADMIN_CUSTOMER_ADDRESS_LOADING = "DELETE_ADMIN_CUSTOMER_ADDRESS_LOADING";
export const DELETE_ADMIN_CUSTOMER_ADDRESS_SUCCESS = "DELETE_ADMIN_CUSTOMER_ADDRESS_SUCCESS";
export const DELETE_ADMIN_CUSTOMER_ADDRESS_ERROR = "DELETE_ADMIN_CUSTOMER_ADDRESS_ERROR";
export const RESET_DELETE_ADMIN_CUSTOMER_ADDRESS = "RESET_DELETE_ADMIN_CUSTOMER_ADDRESS";



// customer Edit
export const EDIT_COUSTOMER_LOADING = "EDIT_COUSTOMER_LOADING";
export const EDIT_COUSTOMER_SUCCESS = "EDIT_COUSTOMER_SUCCESS";
export const EDIT_COUSTOMER_ERROR = "EDIT_COUSTOMER_ERROR";
export const RESET_EDIT_COUSTOMER = "RESET_EDIT_COUSTOMER";


// customer Edit
export const DELETE_COUSTOMER_LOADING = "DELETE_COUSTOMER_LOADING";
export const DELETE_COUSTOMER_SUCCESS = "DELETE_COUSTOMER_SUCCESS";
export const DELETE_COUSTOMER_ERROR = "DELETE_COUSTOMER_ERROR";
export const RESET_DELETE_COUSTOMER = "RESET_DELETE_COUSTOMER";

// customer Create
export const CREATE_COUSTOMER_LOADING = "CREATE_COUSTOMER_LOADING";
export const CREATE_COUSTOMER_SUCCESS = "CREATE_COUSTOMER_SUCCESS";
export const CREATE_COUSTOMER_ERROR = "CREATE_COUSTOMER_ERROR";
export const RESET_CREATE_COUSTOMER = "RESET_CREATE_COUSTOMER";



// regexp
export const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
export const validPassword = RegExp(
  /^(?:(?:(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]))|(?:(?=.*[a-z])(?=.*[A-Z])(?=.*[*.!@$%^&(){}[]:;<>,.?~_+-=|\]))|(?:(?=.*[0-9])(?=.*[A-Z])(?=.*[*.!@$%^&(){}[]:;<>,.?~_+-=|\]))|(?:(?=.*[0-9])(?=.*[a-z])(?=.*[*.!@$%^&(){}[]:;<>,.?~_+-=|\]))).{8,35}$/
);
export const contactValidation = RegExp(/^(?!0+\s+,?$)\d{10}\s*,?$/);
export const countryCodeValidation = RegExp(/^(\d{1,3}|\d{1,4})$/);
export const onlyNumberValidation = RegExp(/^[0-9]*$/);
export const pinCodeValidation = RegExp(/^[0-9]*$/);

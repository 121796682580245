import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getAgentsData,
  getLocationByOrderDataAction,
  getOrderDataAction,
  getOrderFilterAction,
} from "../../../Store/Action/CreateAgents/UserAction";
import Footer from "../../Components/Userlayout/Footer";
import Header from "../../Components/Userlayout/Header";
import moment from "moment";
import { Form, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import OrderDetails from "../Order/OrderDetails";
import ReactDatePicker from "react-datepicker";
import classNames from "classnames";
import { toast } from "react-toastify";
import Toaster from "../../../Components/Toaster/Toaster";
import { RESET_GET_SUPER_ADMIN_LOCATION_BY_OREDR } from "../../../Store/type/Constant";
import Spinner from "../../../Components/Spinner/Spinner";
const Dashboard = (props) => {
  const dispatch = useDispatch();

  // const [selectedDate, setSelectedDate] = useState(new Date());
  const [endDate, setEndDate] = useState("");
  // const [dateFilter, setDateFilter] = useState("today");
  // const [celnderView, setCelnderView] = useState("Month");
  const [orderList, setOrderList] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [location, setlocation] = useState("all");
  const [Loading2, setLoading2] = useState(false);

  const [SelectedOrderDetailsId, setSelectedOrderDetailsId] = useState();
  const [EditShow, setEditShow] = useState(false);

  const [dateRange, setDateRange] = useState(["", ""]);
  const [startDatee, endDatee] = dateRange;
  const navigate = useNavigate();

  // const allorder = useSelector(
  //   (state) => state.SuperAdminOrderReducer.getOrder.data
  // );

  const [allorder, setallorder] = useState();

  const allLocationData = useSelector(
    (state) => state.getAgentsReducer.user.data
  );

  const LocationByOrder = useSelector(
    (state) => state.getLocationByOrder.getLocationByOrder.data
  );

  const [error, seterror] = useState(false);

  useEffect(() => {
    dispatch(getAgentsData());
    props.getAllOrder();
  }, []);

  useEffect(() => {
    if (props.getorderreducer?.success) {
      const data = props.getorderreducer?.data;
      console.log(props.getorderreducer, "props.getorderreducer");
      setallorder(data);
    }
  }, [props.getorderreducer]);

  useEffect(() => {
    if (!!location && location !== "" && location !== "all") {
      dispatch(getLocationByOrderDataAction(location));
    } else if (location === "all") {
      // const todayOrder = allorder?.filter((v, i) => {
      //   const _date = `${new Date(v?.date).getMonth()}-${new Date(
      //     v?.date
      //   ).getDate()}-${new Date(v?.date).getFullYear()}`;
      //   const currentDate = `${new Date().getMonth()}-${new Date().getDate()}-${new Date().getFullYear()}`;
      //   return (
      //     v?.deliveryDateUnform === moment().format("MM-DD-yyyy")
      //     // _date === currentDate
      //   );
      // });
      const todayOrder = allorder?.filter((v, i) => {
        // console.log(moment().format("MM-DD-yyyy"));
        // const _date = `${new Date(v?.date).getMonth()}-${new Date(
        //   v?.date
        // ).getDate()}-${new Date(v?.date).getFullYear()}`;
        // const currentDate = `${new Date().getMonth()}-${new Date().getDate()}-${new Date().getFullYear()}`;
        return (
          // moment().format("MM-DD-yyyy") ===
          // moment(v?.deliveryDateUnform?.split("T")?.[0]).format("MM-DD-yyyy")
          v?.deliveryDateUnform === moment().format("MM-DD-yyyy")
          // _date === currentDate
        );
      });
      // console.log(todayOrder);
      setOrderList(todayOrder);
    }
  }, [location, allorder]);

  useEffect(() => {
    if (props.getLocationByOrder.success) {
      const data = props.getLocationByOrder?.data;
      const list = data?.filter((v, i) => {
        const _date = `${new Date(v?.date).getMonth()}-${new Date(
          v?.date
        ).getDate()}-${new Date(v?.date).getFullYear()}`;
        const currentDate = `${new Date().getMonth()}-${new Date().getDate()}-${new Date().getFullYear()}`;
        return (
          v?.deliveryDateUnform === moment().format("MM-DD-yyyy")
          // _date === currentDate
        );
      });
      setOrderList(list);
    }
  }, [props.getLocationByOrder]);

  // useEffect(() => {
  //   if (!!LocationByOrder) {
  //     setOrderList([...LocationByOrder]);
  //   }
  // }, [LocationByOrder]);

  // useEffect(() => {
  //   if (!!allorder) {
  //     setOrderList([...allorder]);
  //   }
  // }, [allorder]);

  useEffect(() => {
    dispatch(getOrderDataAction());
  }, [dispatch]);

  // useEffect(() => {
  //   if (startDate === "" && endDate === "") {
  //     setOrderList(allorder);
  //   }
  // }, [startDate, endDate, allorder]);

  useEffect(() => {
    props.resetOrder();
  }, [startDate, startDate]);

  // useEffect(() => {
  //   if (!!allorder) {
  //     if (startDate !== "" && (endDate === "" || endDate === null)) {
  //       const list = allorder.filter((e) => {
  //         return (
  //           moment(startDate, "MM-DD-yyyy") <=
  //           moment(e.date, "MM-DD-yyyy")
  //         );
  //       });
  //       setOrderList([...list]);
  //     } else if ((startDate === "" || startDate === null) && endDate !== "") {
  //       const list = allorder.filter((e) => {
  //         return (
  //           moment(endDate, "MM-DD-yyyy") >=
  //           moment(e.date, "MM-DD-yyyy")
  //         );
  //       });
  //       setOrderList([...list]);
  //     } else if (startDate !== "" && endDate !== "") {
  //       const list = allorder.filter((e) => {
  //         return (
  //           moment(startDate, "MM-DD-yyyy") <= moment(e.date, "MM-DD-yyyy") &&
  //           moment(endDate, "MM-DD-yyyy") >= moment(e.date, "MM-DD-yyyy")
  //         );
  //       });
  //       setOrderList([...list]);
  //     }
  //   }
  // }, [startDate, endDate, allorder]);

  // useEffect(() => {
  //   if ((!!allorder && startDate === null) || endDate === null) {
  //     setOrderList([...allorder]);
  //     setlocation("");
  //   }
  // }, [startDate, endDate, allorder]);

  useEffect(() => {
    if (endDate !== null && endDate !== "") {
      setLoading2(true);
      setTimeout(() => {
        setLoading2(false);
      }, 200);
    }
  }, [endDate]);

  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    if (
      startDate !== "" &&
      startDate !== null &&
      (endDate === "" || endDate === null) &&
      !isOpen
    ) {
      toast.error("Please select the next date in the picker.");
    }
  }, [isOpen]);

  useEffect(() => {
    if (location !== "") {
      setDateRange(["", ""]);
    }
  }, [location]);

  useEffect(() => {
    if (props.getorderreducer?.success) {
      // const list = allorder?.filter((v, i) => {
      //   const _date = `${new Date(v?.date).getMonth()}-${new Date(
      //     v?.date
      //   ).getDate()}-${new Date(v?.date).getFullYear()}`;
      //   const currentDate = `${new Date().getMonth()}-${new Date().getDate()}-${new Date().getFullYear()}`;
      //   return (
      //     moment(v?.deliveryDateUnform).format("MM-DD-yyyy") ===
      //     moment().format("MM-DD-yyyy")
      //     // _date === currentDate
      //   );
      // });
      // setOrderList(list);
      setOrderList(allorder);
    }
  }, [props.getorderreducer]);

  return (
    <>
      <Header />
      <main className="userMain">
        <div className="content-block">
          <div className=" d-flex justify-content-beetwen mb-3">
            {/* <div className="col col-lg-3 col-md-4">
              <Form.Label className="mt-1">From / To Date:</Form.Label>
              <div className="">
                <ReactDatePicker
                  showYearDropdown
                  onInputClick={() => setIsOpen(true)}
                  onClickOutside={() => setIsOpen(false)}
                  selectsRange={true}
                  startDate={startDatee}
                  endDate={endDatee}
                  placeholderText="MM-DD-YYYY"
                  dateFormat={"MM-dd-yyyy"}
                  onChange={(date) => {
                    if (!!date && allorder.length !== 0) {
                      setDateRange(date);
                      setStartDate(date[0]);
                      setEndDate(date[1]);
                    } else {
                      setOrderList([...allorder]);
                    }
                    if (date[1]) {
                      setIsOpen(false);
                    }
                  }}
                  className={classNames("form-control", {
                    requireField: error,
                  })}
                  // isClearable={true}
                />
              </div>
            </div> */}
            <div className="form-group event-drp col col-lg-3 col-md-4">
              <Form.Label className="mt-1">Location:</Form.Label>
              <select
                id="location"
                name="location"
                value={location}
                onChange={(e) => setlocation(e.target.value)}
                className="form-select"
              >
                <option value="" className="d-none">
                  Select Location
                </option>
                <option value="all" className="">
                  {`${"All Location (Orders)"}`}
                </option>
                {allLocationData?.map((v, i) => {
                  return (
                    <option value={v?._id} key={i}>
                      {v.location_name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-3">
              <div style={{ fontSize: "18px", fontWeight: "700" }} className="">
                Today's deliveries
              </div>
              <div className="row mb-3 justify-content-between align-items-center">
                <div className="col-12 col-md-5"></div>
                {/* <div className="col-12 col-md-5">
                  <Form.Group>
                    <Form.Select
                      name="date_filter"
                      value={dateFilter}
                      onChange={(e) => setDateFilter(e.target.value)}
                    >
                      <option value={"today"}>Today</option>
                      <option value={"tomorrow"}>Tomorrow</option>
                      <option value={"custom"}>Custom Date</option>
                    </Form.Select>
                  </Form.Group>
                </div> */}
              </div>
              <div
                className="border p-3"
                style={{
                  maxHeight: "780px",
                  overflowY: "auto",
                }}
              >
                {Loading2 ? (
                  <div className="mt-5">
                    <Spinner />
                  </div>
                ) : (
                  <>
                    <div>
                      {orderList?.length === 0 ? (
                        LocationByOrder ? (
                          <div className="text-center h5 mt-5 text-red">
                            There are no orders at selected location/restaurant
                          </div>
                        ) : (
                          <div className="text-center h5 mt-5 text-red">
                            There are no orders
                          </div>
                        )
                      ) : (
                        <>
                          {orderList
                            ?.filter((item, index) => index < 3)
                            .map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  className="border my-2 p-2 rounded"
                                  onClick={() => {
                                    setEditShow(true);
                                    setSelectedOrderDetailsId(item?._id);
                                  }}
                                >
                                  <div
                                    className="d-flex justify-content-between align-items-center text-red"
                                    style={{ fontWeight: "700" }}
                                  >
                                    <div className="">
                                      {item.order_name.toUpperCase()}
                                    </div>
                                    <div className="">
                                      <div className="d-flex justify-content-end">
                                        $
                                        {parseFloat(item.grandtotal).toFixed(2)}
                                      </div>
                                      <div className="mt-2 d-flex justify-content-end">
                                        {item.isDelivered === 0
                                          ? "Ordered"
                                          : "Delivered"}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="">
                                    Date: {item.deliveryDateFrom}
                                  </div>
                                  <div className="">
                                    Time: {item.deliverytimestring}
                                  </div>
                                  <div className="">
                                    Address:
                                    <div style={{ fontWeight: "bold" }}>
                                      {item.address[0]?.name}
                                    </div>
                                    <div>{item.address[0]?.address1},</div>
                                    {item?.address[0]?.address2 && (
                                      <div>{item.address[0]?.address2},</div>
                                    )}
                                    <div>
                                      {item.address[0]?.city},
                                      {" " + item.address[0]?.state + " "}
                                      {item.address[0]?.pincode}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          <div
                            className="see-all text-decoration-none pointer text-center"
                            onClick={() => navigate("/superadmin/order")}
                          >
                            See all
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <Modal
        show={EditShow}
        onHide={() => setEditShow(false)}
        size="md"
        backdrop="static"
        dialogClassName="modal-dialog-scrollable"
      >
        <OrderDetails
          SelectedOrderDetailsId={SelectedOrderDetailsId}
          setEditShow={setEditShow}
        />
      </Modal>
      <Toaster />
    </>
  );
};

const mapStateToProp = (state) => ({
  SuperAdminOrderReducer: state.SuperAdminOrderReducer,
  getLocationByOrder: state.getLocationByOrder.getLocationByOrder,
  getorderreducer: state.SuperAdminOrderReducer.getOrder,
  orderfilterReducer: state.SuperAdminOrderReducer.orderfilterSuperadmin,
});

const mapDispatchToProps = (dispatch) => ({
  getLocationByOrderDataAction: (id) =>
    dispatch(getLocationByOrderDataAction(id)),
  // getAllOrder: (Details) => dispatch(getOrderDataAction(Details)),
  getAllOrder: (Details) => dispatch(getOrderFilterAction(Details)),
  resetOrder: () => dispatch({ type: RESET_GET_SUPER_ADMIN_LOCATION_BY_OREDR }),
});

export default connect(mapStateToProp, mapDispatchToProps)(Dashboard);

import {
  GET_CUSTOMER_ERROR,
  GET_CUSTOMER_LOADING,
  GET_CUSTOMER_SUCCESS,
  RESET_GET_CUSTOMER,
} from "../type/Constant";

let initialState = {
  loading: false,
  user: [],
  error: false,
  errors: [],
  success: false,
  pagination: {},
};

export default function getCustomerReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CUSTOMER_LOADING:
      return { ...state, success: false, loading: true };

    case GET_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        user: action.payload,
        pagination: action.pagination,
      };

    case GET_CUSTOMER_ERROR:
      return { ...state, error: true, errors: action.errors };

    case RESET_GET_CUSTOMER:
      return {
        ...state,
        loading: false,
        user: [],
        error: false,
        errors: [],
        success: false,
      };

    default:
      return state;
  }
}

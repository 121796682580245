import { RESET_STORE } from "../type/Constant";
import getAgentsReducer from "./getAgentsReducer.js/getAgentsReducer";
import { combineReducers } from "redux";
// import signinReducer from "./Signin/SigninReducer";
// import createMenuReducer from "../../Componant/Store/Reducer/Menureducer/CreateMenuReducer";
// import createAgentsReducer from "./CreateAgentsReducer";
// import signinReducer from "./Signin/SigninReducer"
// import createMenuReducer from "./Menureducer/CreateMenuReducer";
// import createAgentsReducer from "./CreateAgentsReducer"
// import createPrmReducer from "./CreatePrmReducer/CreatePrmReducer";

import signinReducer from "./Signin/SigninReducer";
import createAgentsReducer from "./CreateAgentsReducer";
import editAgentsReducer from "./EditAgentsReducer";
import getPrmReducer from "./getPrmReducer/GetPrmReducer";
import createPrmReducer from "./CreatePrmREducer";
import editPrmReducer from "./EditPrmReducer";
import createMenusReducer from "./CreateMenuReducer";
import getMenuReducer from "./getMenuReducer/GetMenuReducer";
import getDeliveryAgentsReducer from "./GetDeliveryAgentsReducer";
import createDeliveryAgentsReducer from "./CreateDeliveryAgentsReducer";
import getCustomerReducer from "./GetCutomerReducer";
import createCustomerReducer from "./CreateCustomerReducer";
import getCustomerAddressReducer from "./GetCustomerAddressReducer";
import createcustomerAddressReducer from "./CreateCustomerAddressReducer";
import SuperAdminOrderReducer from "./OrderReducer";
import editMenuReducer from "./EditMenuReducer";
import findCustomerreducer from "./findCustomerReducer";
import getLocationListReducer from "./getAgentsReducer.js/getLocationListReducer";
import editDeliveryAgentsReducer from "./EditDeliveryAgentsReducer";
import getLocationByAgents from "./LocationbyAgentsReducer";
import getLocationByOrder from "./LocationByOrderReducer";
import getDeliveryAgentsByIdReducer from "./GetDeliveryAgentsByIDReducer";
import getPrmByIdReducer from "./getPrmNyIdReducer";
import getMenuByIdReducer from "./GetMenuByIdReducer";
import getViewLocationByIdReducer from "./GetViewLocationReducer";
import getCustomerByIdReducer from "./GetCustomerByIdReducer";
import getLocationByPRM from "./LocationWisePRMreducer";
import getLocationWiseAgents from "./LocationWiseAgentsReducer";
import searchLocationReducer from "./searchLocationReducer";
import getagentsByOrderReducer from "./agentWiseOrderReducer";
import reportsOrderReducer from "./ReportsOrderReducer";
import exportpdfReducer from "./ExportPdfReducer";
import customerordercountReducer from "./CustomerordercountReducer";
import getlocationwisecustomerReducer from "./LocationhwisecustomerReducer";

// admin reducer
import AuthReducer from "../../Admin/Services/Reducers/AuthReducer/AuthReducer";
import MenuItemsReducer from "../../Admin/Services/Reducers/MenuItemsReducer/MenuItemsReducer";
import DeliveryAgentReducer from "../../Admin/Services/Reducers/DeliveryAgentReducer/DeliveryAgentReducer";
import PrmReducer from "../../Admin/Services/Reducers/PrmReducer/PrmReducer";
import OrderReducer from "../../Admin/Services/Reducers/OrderReducer/OrderReducer";
import CustomerReducer from "../../Admin/Services/Reducers/CustomerReducer/CustomerReducer";
import PasswordReducer from "../../Admin/Services/Reducers/PasswordReducer/PasswordReducer";
import ReportReducer from "../../Admin/Services/Reducers/ReportReducer/ReportReducer";
import editCustomerReducer from "./EditCustomerReducer";
import getSuperAdminProfileReducer from "./SuperAdminProfileReducer";
import UpdatePrmForLocationReducer from "./UpdatePrmForLocationReducer";
import DeleteCustomerAddressReducer from "./DeleteCustomerAddressReducer";
import DeletePrmReducer from "./DeletePrmReducer";
import DeleteMenuItemsReducer from "./DeleteMenuitemsReducer";
import getLocationByMenu from "./LocationByMenuReducr";
import DeleteDeliveryAgentsReducer from "./DeleteDeliveryAgentsReducer";
import SuperadminPasswordReducer from "./SuperadminPasswordReducer";
import exportadminpdfReducer from "./exportadminpadReducer";
import DeleteCustomerReducer from "./DeleteCustomerReducer";
import getswapagentReducer from "./GetswapagentReducer";
import getSwapAgentAdminReducer from "./getSwapAgentAdminReducer";
import importMenuItemExcel from "./Menureducer/importMenuItemExcel";
import AdminimportMenuItemExcel from "../../Admin/Services/Reducers/MenuItemsReducer/AdminimportMenuiItemsExcel";

const appReducer = combineReducers({
  createPrmReducer,
  getAgentsReducer,
  signinReducer,
  // createMenuReducer,
  createAgentsReducer,
  AuthReducer,
  MenuItemsReducer,
  DeliveryAgentReducer,
  PrmReducer,
  OrderReducer,
  getPrmReducer,
  getAgentsReducer,
  signinReducer,
  createMenusReducer,
  createAgentsReducer,
  editAgentsReducer,
  createPrmReducer,
  editPrmReducer,
  createMenusReducer,
  getMenuReducer,
  getDeliveryAgentsReducer,
  createDeliveryAgentsReducer,
  getCustomerReducer,
  createCustomerReducer,
  getCustomerAddressReducer,
  createcustomerAddressReducer,
  SuperAdminOrderReducer,
  CustomerReducer,
  editMenuReducer,
  findCustomerreducer,
  PasswordReducer,
  ReportReducer,
  getLocationListReducer,
  editCustomerReducer,
  editDeliveryAgentsReducer,
  getSuperAdminProfileReducer,
  UpdatePrmForLocationReducer,
  DeleteCustomerAddressReducer,
  DeletePrmReducer,
  DeleteMenuItemsReducer,
  getLocationByMenu,
  DeleteDeliveryAgentsReducer,
  getLocationByAgents,
  getLocationByOrder,
  getDeliveryAgentsByIdReducer,
  getPrmByIdReducer,
  getMenuByIdReducer,
  getViewLocationByIdReducer,
  getCustomerByIdReducer,
  SuperadminPasswordReducer,
  getLocationByPRM,
  getLocationWiseAgents,
  searchLocationReducer,
  getagentsByOrderReducer,
  reportsOrderReducer,
  exportpdfReducer,
  customerordercountReducer,
  exportadminpdfReducer,
  DeleteCustomerReducer,
  getswapagentReducer,
  getSwapAgentAdminReducer,
  importMenuItemExcel,
  AdminimportMenuItemExcel,
  getlocationwisecustomerReducer,
});

const rootReducer = (state, action) => {
  if (action.type === RESET_STORE) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};
export default rootReducer;
